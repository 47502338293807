// Customizable Area Start
import React from 'react';
import {
    styled,
    Typography,
    Box
} from "@mui/material";
import { BlockComponent } from "../../framework/src/BlockComponent";

interface Props {
    data?: Object
}

interface S {
    message?: string
}

interface SS {
    id: string;
}

class PageNotFound extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="page-not-found">
                    <Typography className='not-found-text'> 404 page not found.</Typography>
                </Box>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& .page-not-found": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px",
        flexDirection: "column",
        height: "auto",
        maxWidth: "80%",
        width: "100%",
        margin: "25px auto",
        flex: 1,
        "& .not-found-text": {
            fontFamily: "Poppins",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "60px",
            color: "#D97706"
        }
    }
});

export default PageNotFound;
// Customizable Area End