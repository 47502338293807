import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
interface FormDataStructure {
  state: boolean;
  dob: boolean;
  suffix: boolean;
  suffix_label: any;
  id: number | null;
  form_header: string;
  form_description: string;
  email: boolean;
  phone_number: boolean;
  contact_method: boolean;
  name: boolean;
  name_req:boolean,
  last_name_req:boolean,
  suffix_req:boolean,
  dob_req:boolean,
  state_req:boolean,
  dob_label:string,
  last_name_label:string,
  state_label:string,
  name_label:string
}
export type ForState = S
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedValue: string | [];
  skipped: Set<number>;
  agreeChecked: boolean;
  formStats: FormDataStructure;
  stateDropdown:string[];
  open:boolean;
  progress_bars: {progress_bar_text: string, progress_bar_stage: string}[];
  stage_type: string;
  firstName:string;
  suffixValue:any;
  lastName:string;
  stateValue:string;
  dateValue:any;
  newState:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WellnessJourneyUpdateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQuestionStatsCallIds: string = "";
  postTherapyApiCallId:string = "";
  getStatsCallId:string = "";
  postApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedValue: [],
      newState:"",
      agreeChecked: false,
      dateValue:"",
      skipped: new Set(),
      firstName:"",
      suffixValue:"",
      lastName:"",
      stateValue:"",
      formStats: {
        id: null,
        form_header: "",
        form_description: "",
        email: false,
        phone_number: false,
        contact_method: false,
        name: false,
        suffix_label:"",
        suffix:false,
        dob:false,
        state:false,
        name_req:false,
        last_name_req:false,
        suffix_req:false,
        dob_req:false,
        state_req:false,
        dob_label:"",
        last_name_label:"",
        state_label:"",
        name_label:""
      },
      progress_bars: [
        { progress_bar_text: 'Stage A', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Stage B', progress_bar_stage: '2' },
        { progress_bar_text: 'Stage C',  progress_bar_stage: '3'}
      ],
      stage_type: 'Stage A',
      stateDropdown: [],
      open:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResForQuestionStates(from, message);
    this.handleResForStateApi(from,message);
    // Customizable Area End
  }

  // Customizable Area Start
  
  async componentDidMount() {
    this.getQuestionStats();
    this.getStataApi();
    this.postTherapySignupApi("")
  }
  handleInputChange = ( fieldName: keyof S,value: any): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S)
  }
  getStataApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getQuestionStats = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionStatsCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landingpage2/landing_page_questions/get_variant_form_data?form_type=patient"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAgreeChanges = (value: boolean) => {
    this.setState({ agreeChecked: value });
  };

 
 

  handleResForQuestionStates = async (from: string, message: Message) => {
    if (
      this.getQuestionStatsCallIds ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ formStats: responseJson });
      }
    }
  };
  handleResForStateApi = async (from: string, message: Message) => {
    if (
      this.getStatsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ stateDropdown: responseJson.states });
      }
    }
  };
  postTherapySignupApi = async (id:any) => {
    const {firstName,lastName,suffixValue,stateValue,dateValue} = this.state
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    
    let data = {
      data: {
        type: "patients",
        attributes: {
          first_name: firstName,
          last_name: lastName,
          suffix: suffixValue,
          date_of_birth: dateValue,
          state: stateValue,
          terms_of_use: true
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postTherapyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/patients/${id}`
    
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}