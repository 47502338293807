import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props{
  navigation: any;
  id: string; 
}
interface Option {
  id: number;
  label: string;
}

export interface S{
  options : Option[];
  selectedOptions : number[];
  listData: {progress_bar_text: string, progress_bar_stage: string}[]
  activeStep: string
}

interface SS {
    id: any;
  }

export default class HealthReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {
      options: [
        { id: 1, label: 'Autism/Asperger.s' },
        { id: 2, label: 'Addictions/Substance Abuse' },
        { id: 3, label: 'ADHD' },
        { id: 4, label: 'Adoption' },
        { id: 5, label: 'Alzheimer.s' },
        { id: 6, label: 'Anger Management' },
        { id: 7, label: 'Antisocial Personality' },
        { id: 8, label: 'Anxiety Disorders' },
        { id: 9, label: 'Behavioral Issues' },
        { id: 10, label: 'Bipolar Disorder' },
        { id: 11, label: 'Borderline Personality Disorder' },
        { id: 12, label: 'Christian Counseling' },
        { id: 13, label: 'Chronic Illness' },
        { id: 14, label: 'Chronic Pain' },
        { id: 15, label: 'Codependency' },
        { id: 16, label: 'Coping Skills' },
        { id: 17, label: 'Depression' },
        { id: 18, label: 'Dissociative Disorders (DID)' },
        { id: 19, label: 'Domestic Abuse/Domestic Violence' },
        { id: 20, label: 'Dual Diagnosis' },
        { id: 21, label: 'Eating Disorders' },
        { id: 22, label: 'Family Conflict' },
        { id: 23, label: 'Gender Identity Issues' },
        { id: 24, label: 'Grief and Loss' },
        { id: 25, label: 'Hoarding' },
        { id: 26, label: 'Impulsive Control Disorders' },
        { id: 27, label: 'Infidelity' },
        { id: 28, label: 'LGBTQ+' },
        { id: 29, label: 'Life Transitions' },
        { id: 30, label: 'Life Coaching' },
        { id: 31, label: 'Marital Issues' },
        { id: 32, label: 'Medical Issues' },
        { id: 33, label: 'Men.s Issues' },
        { id: 34, label: 'Mood Disorders' },
        { id: 35, label: 'Narcissistic Personality Disorder' },
        { id: 36, label: 'Obesity' },
        { id: 37, label: 'Obsessive Compulsive Disorder' },
        { id: 38, label: 'Parenting Issues' },
        { id: 39, label: 'Peer Relationships' },
        { id: 40, label: 'Personality Disorders' },
        { id: 41, label: 'Psychosis' },
        { id: 42, label: 'Racial Identity' },
        { id: 43, label: 'Relationship Issues' },
        { id: 44, label: 'Self Esteem' },
        { id: 45, label: 'Self Harm' },
        { id: 46, label: 'Sex Therapy' },
        { id: 47, label: 'Sexual Abuse' },
        { id: 48, label: 'Sexual Addiction' },
        { id: 49, label: 'Sexual Dysfunction' },
        { id: 50, label: 'Sleep or Insomnia' },
        { id: 51, label: 'Spirituality ' },
        { id: 52, label: 'Stress' },
        { id: 53, label: 'Suicidal Ideations' },
        { id: 54, label: 'Trauma/PTSD' },
        { id: 55, label: 'Traumatic Brain Injury' },
        { id: 56, label: 'Weight Loss' },
        { id: 57, label: 'Woman.s Issues' }
      ],
      selectedOptions : [],
      listData: [
        { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2' },
        { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
      ],
      activeStep: 'About You'
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 
      
    handleOption=(id:number)=>{
      this.setState((prevState)=>({
        selectedOptions : prevState.selectedOptions.includes(id) ? prevState.selectedOptions.filter((item) => item !== id) : [...prevState.selectedOptions,id]
      })
    )}
    
}