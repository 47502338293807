// Customizable Area Start
import React from 'react';
import {
    Box, styled
} from "@mui/material";
import FlowDecisionController, { Props } from "./FlowDecisionController";
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import { provider, shield } from './assets';

class FlowDecision extends FlowDecisionController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapperFlowDecision>
                <section className='leftBorder'>
                    <Box className="mainContainer">

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="title">[User Name], what would you like to do first?</Box>
                        <Box className="boxes">
                            <Box className="box1">
                                <Box className="content">
                                    <Box className="boxTitle">Select Provider</Box>
                                    <Box className="description">I would like to select my provider before verifying my insurance coverage</Box>
                                    <Box className="imgParent"><img src={provider} className="image" alt="image" /></Box>
                                </Box>
                                <button className="button">Design my Care Team</button>
                            </Box>
                            <Box className="box1">
                                <Box className="content">
                                    <Box className="boxTitle">Check my <br />
                                        Insurance Coverage</Box>
                                    <Box className="description">I would like to verify my insurance coverage before selecting my provider</Box>
                                    <Box className="imgParent"><img src={shield} className="image" alt="image" /></Box>
                                </Box>
                                <button className="button">Verify my insurance</button>
                            </Box>
                        </Box>
                        <Box className="backBtn">Back</Box>
                    </Box>
                    <FooterData />
                </section>
            </StyledWrapperFlowDecision>
        )
    }
}


const StyledWrapperFlowDecision = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },
    "& .backBtn": {
        height: "auto",
        color: "#78716C",
        width: "100%",
        marginLeft: "15%",
        maxWidth: "15%",
        marginBottom: "112px"
    },
    "& .button": {
        margin: "auto",
        "marginTop": "10%",
        "display": "flex",
        "justifyContent": "center",
        "marginBottom": "10%",
        height: "56px",
        width: "90%",
        color: "#FFFFFF",
        borderRadius: "24px",
        background: "#E46760",
        border: "none",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",

    },
    "& .imgParent": {
        display: "flex",
        minHeight: "120px",
        justifyContent: "center",
        height: "auto",
        width: "100%",
    },
    "& .image": {
        height: "20%",
        width: "20%",
    },
    "& .description": {
        maxWidth: "80%",
        textAlign: "center",
        margin: "auto",
        marginTop: "8%",
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        marginBottom: "8%",
    },
    "& .boxTitle": {
        "color": "#2C2524",
        "fontSize": "29px",
        "fontFamily": "Poppins",
        "textAlign": "center",
        fontWeight: 500,
        width: "100%",
        minHeight: "84px",
        margin: "auto",
        "marginTop": "5%",
    },
    "& .box1": {
        border: "1px solid #A8A29E",
        borderRadius: "24px",
    },
    "& .boxes": {
        height: "auto",
        width: "100%",
        maxWidth: "50%",
        margin: "auto",
        display: "flex",
        flexDirection: "row",
        marginBottom: "2%",
        gap: "20px"
    },
    "& .title": {
        width: "100%",
        height: "auto",
        margin: "auto",
        maxWidth: "60%",
        color: "#2C2524",
        marginTop: "4%",
        fontWeight: 600,
        fontSize: "41px",
        fontFamily: "Raleway",
        textAlign: "center",
        marginBottom: "2%",
    },
    "& .leftBorder": {
        height: "auto",
        borderLeft: "32px solid #1A4A42",
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "@media screen and (max-width: 767px)": {
        "& .boxes": {
            display: "block"
        },
        "& .box1": {
            margin: "15px 0px"
        }
    }
})

export default FlowDecision;
// Customizable Area End