export const HeaderLogoImg = require("../assets/header.png").default;
export const Therapy = require("../assets/therapy.png").default;
export const GroupWellness = require("../assets/wellness.png").default;
export const right_img = require("../assets/rightSide.png").default;
export const wellnessLanding = require("../assets/wellness_landing.png").default;
export const total = require("../assets/total.png").default;
export const TotalLogo = require("../assets/TotalLogo.png").default;
export const call = require("../assets/call.png").default;
export const wellnessDummy = require("../assets/wellness_dummy.jpg").default;
export const callimg = require("../assets/callimg.png").default;
export const optionImage = require("../assets/optionhint.svg").default;
export const activeOptionImage = require("../assets/activeOptionhint.svg").default;

