// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Typography,
    Button
} from "@mui/material";
import { HeaderLogoImg, scheduledCall  } from './assets';
import { BlockComponent } from "../../../framework/src/BlockComponent";

interface Props {
    data?: Object
}

interface S {
    time_text: string,
    button_text: string
}

interface SS {
    id: string;
}

class ScheduledCallback extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            button_text: 'Continue',
            time_text: 'Afternoon: 12 - 4 PM'
        }
    }

    handleDisabledBtn = (data: string | boolean) => {
        return data ? false: true
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="container-elm">
                    <Box className="header-section">
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                    </Box>
                    <Box className="content-container">
                        <img className="call-logo" src={scheduledCall} height={180} width={180} alt="Call Scheduled" />
                        <Typography
                            variant="h1" 
                            component="h2"
                            className='headingText'
                            dangerouslySetInnerHTML={{__html: "Callback Scheduled!"}}
                        />
                        <Typography
                            className='bodyText'
                            dangerouslySetInnerHTML={{__html: "Our team will call you today/on the next business day' depending on their selection on the previous page"}}
                        />
                        <CustomButton data-test-id="button-elm" onClick={() => {}} className="time-button">{this.state.time_text}</CustomButton>
                    </Box>
                    <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.button_text)} className="continue-button">{this.state.button_text}</CustomButton>
                </Box>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    '& .container-elm': {
        backgroundColor: "#fff",
        height: "auto",
        minHeight: "78px",
        display: "flex",
        flexDirection: "column",
        borderLeft: "32px solid #1A4A42"
    },
    '& .content-container': {
        display: "flex",
        flexDirection: "column",
        width: "44%",
        margin: "75px auto 0",
        "& .call-logo": {
            margin: "0 auto"
        },
        "& .headingText, & .bodyText": {
            textAlign: "center",
            margin: 0
        },
        "& .headingText": {
            fontFamily: "Raleway",
            fontSize: "50px",
            fontWeight: 700,
            lineHeight: "120%",
            letterSpacing: "0.5px",
            marginBottom: "40px",
            marginTop: "32px",
            color: "#1A4A42"
        },
        '& .bodyText': {
            fontWeight: 400,
            fontFamily: "Poppins,sans-serif",
            backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
                linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textFillColor: "transparent",
            fontSize: "20px",
            lineHeight: "30px",
            marginBottom: "24px"
        }
    },
    "& .header-section": {
        height: "78px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E7E5E4",
        width: "100%",
        '& img': { paddingLeft: "3%" },
    }
});

const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    borderRadius: '24px',
    height: "56px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    '&.continue-button': {
        backgroundColor: '#E46760',
        color: '#fff',
        width: "40%",
        maxWidth: "500px",
        margin: "226px auto 95px"
    },
    '&.time-button': {
        backgroundColor: '#E8EDEC',
        color: '#1A4A42',
        width: "60%",
        maxWidth: "396px",
        padding: "0 16px",
        fontFamily: "Poppins,sans-serif",
        fontSize: "29px",
        lineHeight: "70%",
        letterSpacing: "0.5px",
        margin: "0 auto"
    }
}));

export default ScheduledCallback;
// Customizable Area End