import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { ReactNode } from "react";


export const ResolveCondition = (condition: unknown, trueResult: unknown, falseResult: unknown) => {
    return condition ? trueResult : falseResult
}

export const ConditionalRenderer = (condition: boolean, truePart: ReactNode, falsePart:ReactNode ) => {
  return (condition ? truePart : falsePart );
};

export const handleNavigation = (pathname: string, props: unknown, param?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    runEngine.sendMessage(message.id, message)
};

export const getSearchParams = (param: string) => {
    const pathObj = new URLSearchParams(window.location.search);
    return pathObj.get(param) || '';
}