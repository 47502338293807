// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    TextField,
    Select,
    Divider,MenuItem,FormControl
} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { HeaderLogoImg, emergency, call} from '../assets';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
let validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required("Email is required"),
  relationship_to_patient: Yup.string()
  .required("Relationship to the patient is required"),

  reason_for_signing_up: Yup.string()
    .required("Reason for signing up is required"),
  contact_method:Yup.string().required("Please select the preferred contact method")
  });
import TermsConditionsEdit from "../../../../blocks/termsconditions/src/TermsConditionsEdit.web"
import AuthorizedRepresentativeController, {
    Props,
} from "./AuthorizedRepresentativeController";
import StepperData from "../../../../components/src/StepperData.web";
import { setStorageData } from '../../../../framework/src/Utilities';
import { ConditionalRenderer, ResolveCondition ,handleNavigation} from '../../../../blocks/utilities/src/CustomHelpers';

const commonStyleData= require('./CommonStyles.js');
const { CommonStyles, CustomBox, CustomButton, PhoneInputWrapper } = commonStyleData.CommonStyles;

class AuthorizedRepresentative extends AuthorizedRepresentativeController {
    constructor(props: Props) {
        super(props);
    }

    renderError = (touched: any, fieldStats: any) => {
        return ConditionalRenderer(Boolean(touched && fieldStats), <div className="error">{fieldStats}</div>, '')
    }

    render() {
        const {allResponseJsondata} = this.state;
        const paramId = this.props?.navigation?.getParam("navigationBarTitleText"); 
        return (
            <StyledWrapperForWellness>
               <Box className="headerLogo">
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Divider />
          <Box className= "headerStepper">           
             <StepperData 
              key={`stepper${allResponseJsondata.stage_type}`}
              listData={allResponseJsondata.progress_bars_attributes}
              activeStep={""}
            />
          </Box>

          <Formik
            initialValues={{
              phone_number: "",
              landline_number : "",
              email: "",
              phone_checkbox: false,
              email_checkbox: false,
              sms_checkbox: false,
              contact_method : "" ,
              relationship_to_patient:"",
              reason_for_signing_up:""
            }}
            data-test-id="formikWrapper"
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setStorageData("values", JSON.stringify(values));
              setStorageData("Param Value", paramId);
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => {
             return(
              <Form onSubmit={handleSubmit}>
                <Box className="main-section">
                  <h2 className="question">
                    {allResponseJsondata?.form_header}
                  </h2>
                  <p className="information">
                    {allResponseJsondata.form_description}
                  </p>

                  <Box className="box">
                    {
                      allResponseJsondata.email &&
                      <Box className="div-container">
                        <div className="heading">Email
                        {ConditionalRenderer(allResponseJsondata.email, <span className='require-text'>*</span>,'')}
                        </div>
                        <Box className="inputField">
                          <Field
                            onChange={(e:any) => {
                              handleChange(e);
                              this.setState({authEmail:e.target.value});
                            }}
                            name="email"
                            data-test-id="txtInput2"
                            as={TextField}
                            value={values.email}
                            className="emailField inputFields"
                            placeholder="Enter Email address"
                          />
                          {ConditionalRenderer(Boolean(errors.email),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.email),
                                <div className="error">
                                  {errors.email}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                        </Box>
                      </Box>
                    } 

                    {allResponseJsondata.contact_method && (
                      <>
                        <div className="Preferredheading">
                          Preferred Contact Method{" "}
                          {ConditionalRenderer(allResponseJsondata.mobile_number,<span className='require-text'>*</span>,'')}                          
                          <span className="Select">
                            (select all that apply)
                          </span>
                        </div>
                        <Box className="checkBoxGroup">
                        <label style={webstyle1.checkboxLabel1} >
                          
                          <input
                            data-test-id="phoneCheckbox"
                            type="checkbox"
                            checked={values.phone_checkbox}
                            style={webstyle1.checkboxInput1} 
                            onChange={(e:any) => {
                              setFieldValue("phone_checkbox", e.target.checked);
                              this.handleCheckboxChange("phone", e.target.checked);
                            }} 
                            name="phone_checkbox"
                          />
                         <span
                           style={webstyle1.checkboxCustom1(values.phone_checkbox)}>
                          {<span style={webstyle1.checkmark1}></span>}
                         </span>
                         <span className="phoneClass">Phone</span>
                        </label>
                        <label style={webstyle1.checkboxLabelEmail1}>
                          <input
                            type="checkbox"
                            style={webstyle1.checkboxInput1}  
                            onChange={(e:any) => {
                              setFieldValue("email_checkbox", e.target.checked);
                              this.handleCheckboxChange("email", e.target.checked);
                            }}
                            data-test-id="emailcheck"
                            name="email_checkbox"
                            checked={values.email_checkbox}
                          />
                         <span
                           style={webstyle1.checkboxCustom1(values.email_checkbox)}>
                          {<span style={webstyle1.checkmark1}></span>}
                         </span>
                         <span className="phoneClass">Email</span>
                         </label>
                         <label style={webstyle1.checkboxLabel1}>
                          <input
                            type="checkbox"
                            style={webstyle1.checkboxInput1}   
                            onChange={(e:any) => {
                              setFieldValue("sms_checkbox", e.target.checked);
                              this.handleCheckboxChange("sms", e.target.checked);
                            }} 
                            data-test-id="smscheck"
                            name="sms_checkbox"
                            checked={values.sms_checkbox}
                          />
                         <span
                           style={webstyle1.checkboxCustom1(values.sms_checkbox)}>
                          {<span style={webstyle1.checkmark1}></span>}
                         </span>
                         <span className="phoneClass">SMS</span>
                         </label>
                        </Box>                    
                      </>
                     )}   

                {allResponseJsondata.relationship_to_patient && 
                <Box className="full-name">
                <div className="heading">Relationship to the Patient
                     <span className='require-text'>*</span>
                     </div>
                  <FormControl className="inputFieldLast1" fullWidth>
                  <Field
  as={Select}
  MenuProps={{
    PaperProps: {
      sx: {
        border: "1px solid #E6C79C",
        borderRadius: "0px 0px 24px 24px",
      },
    },
  }}
  sx={{
    border: "1px solid #E6C79C",
    borderRadius: "0px 0px 24px 24px",
    borderTop: "none",
    padding: "12px",
    "& fieldset": {
      border: "none",
    },
    "& .MuiSelect-select": {
      borderRadius: "16px",
    },
  }}
  onChange={(e: any) => {
    handleChange(e); 
    this.setState({ patientRelation: e.target.value });
  }}
  name="relationship_to_patient"
  value={values.relationship_to_patient}
  data-test-id="relation"
  displayEmpty
  placeholder="Select One"
  IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
>
  {allResponseJsondata.relationship_to_patient.map((item: string, index: number) => (
    <MenuItem
      key={index}
      value={item}
      sx={{
        borderBottom:"1px solid #D6D3D1",
        fontWeight: 400,
        padding: "8px 12px 8px 16px",
        height: "40px",
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
    >
      {item}
    </MenuItem>
  ))}
</Field>
                    {ConditionalRenderer(Boolean(errors.relationship_to_patient),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.relationship_to_patient),
                                <div className="error">
                                  {errors.relationship_to_patient}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                  </FormControl>
                  
               </Box>
                 } 
                    
                    
             {allResponseJsondata.reason_for_signing_up && 
               <Box className="full-name">
                 <div className="heading">Why are you signing up on behalf of someone else?
                 <span className='require-text'>*</span>
                  </div>
                  <FormControl className="inputFieldLast1" fullWidth>
                    <Field
                      onChange={(e:any) => {
                        handleChange(e);
                        this.setState({patientReasons:e.target.value});
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "1px solid #E6C79C", 
                            borderRadius: "0px 0px 24px 24px", 
                          },
                        },
                      }}
                      sx={{
                        border: "1px solid #E6C79C",
                        borderRadius: "0px 0px 24px 24px", 
                        padding: "12px",
                        borderTop:"none",
                        "& fieldset": {
                          border: "none", 
                        },
                        "& .MuiSelect-select": {
                          borderRadius: "16px",
                        },
                      }}
                      name="reason_for_signing_up"
                      data-test-id="reasons"
                      as={Select}
                      value={values.reason_for_signing_up}
                      placeholder="Select One"
                      displayEmpty
                      IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
                    >
                     {allResponseJsondata.reason_for_signing_up.map((item:string,index:number) => (
                         
                          <MenuItem 
                          sx={{
                            fontWeight: 400,
                            borderBottom:"1px solid #D6D3D1",
                            padding:"8px 12px 8px 16px",
                            height:"40px",
                            fontFamily: "Poppins", 
                            fontSize: "16px",
                            lineHeight: "24px", 
                            "&:hover": {
                              backgroundColor: "#e0e0e0", 
                            },
                          }}
                          key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Field>
                    {ConditionalRenderer(Boolean(errors.reason_for_signing_up),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.reason_for_signing_up),
                                <div className="error">
                                  {errors.reason_for_signing_up}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                  </FormControl>
               </Box>
             } 
               <Box padding="0px 41.5px">
                  <Box className="checkboxContainerLast">
                      <label style={webstyle1.checkboxLabel1}>
                         <input
                           type="checkbox"
                           style={webstyle1.checkboxInput1}  
                           name="agree"
                           data-test-id="agreeCheck"
                           checked={this.state.agreeChecked}
                           onChange={(e) =>
                           this.handleAgreeChanges(e.target.checked)
                           }
                         />
                        <span
                          style={webstyle1.checkboxCustom1(this.state.agreeChecked)}
                          >
                         {<span style={webstyle1.checkmark1}></span>}
                        </span>
                        <span className="phoneClass">
                       I agree with the{" "}
                       <u data-test-id = "conditions" 
                       onClick={()=>this.handleNavigateTermConditions("terms_and_condition","patient")}
                       >
                         <i className="italic" style={{cursor:"pointer"}}>Terms of Use</i>
                       </u>{" "}
                       <span className="phoneClass">and{" "}</span>
                       <u data-test-id = "condition" 
                       onClick={()=>this.handleNavigateTermConditions("privacy_policy","patient")}
                       >
                         <i className="italic"  style={{cursor:"pointer"}}>Privacy Policy</i>
                       </u>
                     </span>
                        </label>
                       {
                       this.state.modalOpen && <TermsConditionsEdit navigation={undefined} id={""} modalOpen={this.handleNavigateTermConditions} pageType={this.state.apiPageType} role={this.state.role} states={this.state.modalOpen}/>
                        } 
                   </Box>
                   <Box className="checkboxContainerLast">
                      
                      <label style={webstyle1.checkboxLabel1}>
                         <input
                           type="checkbox"
                           style={webstyle1.checkboxInput1}  
                           name="agree"
                           data-test-id="consentAgreeCheck"
                           checked={this.state.consentChecked}
                           onChange={(e) =>
                           this.handleConsentChanges(e.target.checked)
                           }
                         />
                        <span
                          style={webstyle1.checkboxCustom1(this.state.consentChecked)}
                          >
                         {<span style={webstyle1.checkmark1}></span>}
                        </span>
                        <span className="phoneClass">
                        I have the consent of the patient
                     </span>
                        </label>
                   </Box>
                  </Box>
                    
                <Box>
                    <button
                      onClick={this.postTherapySignupApi}
                      className={`button-continue ${ResolveCondition(
                        this.state.consentChecked,
                        "active",
                        "inactive"
                      )}`}
                      disabled={!this.state.selectedValue}
                      data-test-id="submitBtn"
                      type="submit"
                    >
                      {allResponseJsondata.button_text}
                    </button>
                  </Box>

                  <Box className="loginClass">
                    <u className="content">Already have an account? Log in</u>
                  </Box>
                  
                  </Box>
                                    
                </Box>
              </Form>
              )
            }}
            
          </Formik>
           
          {
              ConditionalRenderer(allResponseJsondata.help_footer, <Box className="footer" id="footer">
              <p className="emergencyIcon">
                <span>
                  <img src={emergency} alt="emergency" className="yellowFilter" />
                </span>
                &nbsp; If this is an emergency &nbsp; <u>please call 911</u>
              </p>
              <div className="footerRight" id="rightFooter">
                <span id="label-need-help" className="label-700-20">
                  Need Help?
                </span>
                <button className="callButton" data-test-id="callBTN">
                  Schedule a call?
                  <span className="callImg">
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>,<Box className="footer"></Box>)
               
            }
       
            </StyledWrapperForWellness>
        );
    }
}

const StyledWrapperForWellness = styled("div")({
    borderLeft: "32px solid #1A4A42;",

    margin: "0 auto",
    // height: "100%",
    width: "100%",
    "& *": {
      boxSizing: "border-box",
    },
    "& .require-text": {
      WebkitTextFillColor: "#DC2626",
      margin:"3px",
      textFillColor: "#DC2626",
  },
  "& .inputFieldLast1 .MuiOutlinedInput-root": {
    
    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .MenuDropdown":{

   color:"red",

   "&.MuiPaper-root-MuiPopover-paper-MuiMenu-paper":{
    color:"red",

   }

  },
  "& .inputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxWidth: "100%",
    "border": "1px solid #E6C79C",
    width: "100%",
    maxHeight: "44px",
   },
  "& .inputFieldLast1": {
    maxHeight: "72px",
    borderRadius: "24px",
    maxWidth: "100%",
    width: "100%",
    marginBottom:"24px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display:"flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%", 
      display:"flex"
    }
  },
    "& .MuiFormControl-root.MuiTextField-root.emailField.inputFields": {
      width: "100% !important"
    },
    "& .emailField .MuiOutlinedInput-root": {
      width: "100%",
      height: "42px",
      maxWidth: "600px",
      borderRadius: "24px",
      borderColor: "#F87171",
      "& fieldset": {
        borderColor: "#F87171",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F87171",
      },
      "&:hover fieldset": {
        borderColor: "#F87171",
      },
      "& .MuiInputBase-input": {
        height: "100%",
      }
    },
    "& .MuiCheckbox-root.Mui-checked svg": {
      color: "#1A4A42",
      borderRadius: "4px",
    },
    "& .headerStepper": {
        "display": "flex",
        "paddingTop": "32px",
        "justifyContent": "center",
      },
    "& .MuiCheckbox-root svg": {
      borderRadius: "10px",
    },
    "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "16px",
    },
    "& .header .custom-step-connector .MuiStepConnector-root": {
      display: "none",
    },
    "& .header .custom-step-connector .MuiStepper-horizontal": {
      justifyContent: "space-between",
      position: "relative",
    },
    "& .header .custom-step-connector .MuiSvgIcon-root": {
      borderRadius: "10px",
    },
    "& .inputField .inputFields .MuiInputBase-root": {
      width: "100%",
      maxHeight: "42px",
      maxWidth: "600px",
      borderRadius: "24px"
    },
    "& .inputField .inputFields .MuiOutlinedInput-root": {
      width: "100%",
      maxHeight: "42px",
      borderRadius: "24px",
      borderColor: "#E6C79C",
      "& fieldset": {
        borderColor: "#E6C79C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E6C79C",
      },
      "&:hover fieldset": {
        borderColor: "#E6C79C",
      },
    },
    "& .header .custom-step-connector .MuiStepLabel-iconContainer::after":
    {
      height: "3px",
      backgroundColor: "#A8A29E",
      content: `""`,
      position: "absolute",
      width: "239px",
      left: "44px",
      top: "10px",
    },
    "& .Select": {
      fontWeight: 400,
      fontFamily: "Poppins",
      color: "#78716C",
      fontSize:"16px",
      lineHeight: "24px",
    },
    "& .error-container": {
      // minHeight: "20px",
    },
    "& .error": {
      fontWeight:400,
      fontFamily:"Poppins",
      fontSize:"13px",
      lineHeight:"19.5px",
      color: "#DC2626",
      marginTop: "2px",
      textAlign: "initial",
    },
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      width: "100%",
    },
    "& .inputField .inputFields .MuiInputBase-root.MuiOutlinedInput-root":
    {
      width: "100%",
      height: "100%",
      maxHeight: "42px",
      maxWidth: "600px",
      borderRadius: "24px",
    },
    "& .inputField1 .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #E6C79C",
          borderRadius:"24px",
          height:"44px"
        },
        "&:hover fieldset": {
          border: "1px solid #E6C79C",
          borderRadius:"24px",
          height:"44px"

        },
        "&.Mui-focused fieldset": {
          border: "1px solid #E6C79C",
          borderRadius:"24px",
          height:"44px"

        },
      },
    "& .italic": {
      fontFamily: "Poppins",
      fontWeight:400,
      fontSize:"20px",
      lineHeight:"30px",
      textDecoration: "underline",
      color: "#1A4A42",
      textDecorationColor: "green",
    },
    "& .loginClass":{
        marginTop:"28px"
    },
    "& .content": {
      fontWeight: 700,
      color: "#E46760",
      fontSize: "20px",
      fontFamily: "Raleway",
      lineHeight:"23.48px"
    },
    "& .div-container": {
      marginBottom: "24px",
    },
    "& .div-container1":{
        marginBottom: "32px",

    },
  
    "& .checkBoxGroup": {
      maxWidth: "291px",
      display: "flex",
      alignItems: "center",
      maxHeight: "22px",
      padding: "10px",
      borderRadius: "10px",
      marginLeft:"-10px",
      marginBottom:"24px"
    },
    "& .phoneClass"  : {
       fontFamily:"Poppins",
       fontWeight:400,
       fontSize:"20px",
       lineHeight:"30px"
    },
  
    "& .checkBoxGroup.active": {
      backgroundColor: "#000000",
    },
    "& .checkboxContainerLast": {
      display: "flex",
      marginBottom: "16px",
      alignItems: "center",
    },
    "& .checkboxContainer": {
      display: "flex",
      alignItems: "center",
    },
    "& .upperCheckbox":{
       borderRadius:"20px"
    },
    '& .checkboxText' : {
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .heading": {
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "justify",
      marginBottom:"4px"
    }, 
    "& .Preferredheading" : {
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "justify",
      marginBottom:"6px"
    },
    "& .box": {
      height: "100%",
      width: "100%",
      maxWidth: "600px",
      maxHeight: "340px",
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      marginTop: "32px",
    },
    "& .headerLogo": {
      height: "auto",
      width: "100%",
      minHeight: "78px",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
    },
    "& .logoImg": {
      marginLeft: "144px",
    },
    "& .footerRight": {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    "& .label-700-20": {
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Poppins",
    },
    "& .main-section": {
      // height: "100vh",
      textAlign: "center",
      marginTop : "51.22px"
    },
    "& .custom-step-connector": {
      maxWidth: "600px",
      width: "100%",
    },
    "& .header .custom-step-connector .MuiStepLabel-labelContainer": {
      alignItems: "center",
      flexDirection: "column",
    },
    "& .about-section": {
      borderLeft: "32px solid #1A4A42;",
      // "@media(max-width:1500px)": {
      //   color:"red"
      // },
    },
    "& .question": {
      fontWeight: 600,
      fontFamily: "Raleway",
      fontSize: "41px",
      lineHeight: "53.3px",
      letterSpacing: "0.5px",
      maxWidth: "700px",
      margin: "auto",
    },
    "& .information": {
      maxWidth: "600px",
      margin: "auto",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      marginTop: "32px",
    },
    "& .callButton": {
      borderRadius: "16px",
      background: "#ffffff",
      border: "1px solid #E46760",
      padding: "5px 10px",
      color: "#E46760",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "23.48px",
    },
    "& .footer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #E7E5E4",
      marginTop: "300px",
      padding: "0px 141px",
      minHeight: "96px",
      "@media(max-width:750px)": {
        padding: "0px 0px",
      },
    },
    "& .emergencyIcon": {
      display: "flex",
      alignItems: "center",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "20px",
    },
    "& .button-continue": {
      border: "none",
      maxWidth: "600px",
      maxHeight: "56px",
      height: "100%",
      width: "100%",
      borderRadius: "24px",
      cursor: "pointer",
      background: "#E46760",
      marginTop: "32px",
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight:"23.48px"
    },
    "& .button-continue.active": {
      border: "none",
      background: "#E46760",
      cursor: "pointer",
      color: "#FFFFFF",
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      marginTop: "32px",
      marginBottom:"28px"
    },
  
    "& .button-continue.inactive": {
      border: "none",
      background: "#E7E5E4",
      cursor: "not-allowed",
      color: "#A8A29E",
      disableCursor: true,
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      marginTop: "32px",
      marginBottom:"28px"

    },
    "& u": {
      color: "#D97706",
    },
    "& .header": {
      height: "auto",
      minHeight: "140px",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    "@media screen and (max-width:1200px)": {
      "& .logoImg": {
        marginLeft: "50px",
      },
    },
    "@media screen and (max-width:767px)": {
      "& .logoImg": {
        marginLeft: "30px",
      },
    },
  });

const CustomTextField = styled(TextField)(({
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      width: "600px",
      height: "44px",
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&.first-name-input .MuiInputBase-root, &.last-name-input .MuiInputBase-root': {
        width: "288px !important",
    },
    '& .MuiInputBase-input': {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        padding: '10px 16px',
        color: "#2C2524",
        '&::placeholder': {
            color: "#A8A29E",
            opacity: 1
        }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        display: "flex",
        alignItems: "center",
        border: "1px solid #E6C79C"
      },
      '&:hover fieldset': {
        border: "1px solid #E6C79C"
      },
      '&.Mui-focused fieldset': {
        border: "2px solid #E6C79C"
      },
    },
}));
const webstyle1 = {
 
    checkboxLabel1: {
      display: "flex",
      cursor: "pointer",
      fontSize: "14px",
      alignItems: "center",
    },

    checkboxInput1: {
      display: "none",
    },
    checkboxLabelEmail1:{
      display: "flex",
      cursor: "pointer",
      fontSize: "14px",
      alignItems: "center",
      margin:"0px 24px"
    },
    checkmark1: {
      width: "8px",
      height: "12px",
      border: "solid white",
      left: "5px",
      position: "absolute" as "absolute", 
      top: "1px",
      transform: "rotate(45deg)",
      borderWidth: "0 2px 2px 0",
      opacity: 1,
    } as React.CSSProperties,
    checkboxCustom1: (isChecked: any): React.CSSProperties => ({
      width: "20px",
      height: "20px",
      border: "1px solid #78716C",
      display: "inline-block",
      position: "relative",
      backgroundColor: isChecked ? "#1A4A42" : "transparent",
      borderRadius: "6px",
      marginRight: "10px",
    }),
  };

export default AuthorizedRepresentative;
// Customizable Area End