import React from "react";

// Customizable Area Start
import {
  Box, styled,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  TextField
} from "@mui/material";
import StepperData from '../../../components/src/StepperData.web';

// Customizable Area End

import AilmentsPageController, {
  Props,
} from "./AilmentsPageController";
import { call, emergency, skip } from "./assets";
import { ConditionalRenderer, ResolveCondition, handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";


class AilmentsPage extends AilmentsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <StyledWrapperAilments>
        <>
          <section className="leftBorder">
            <Box>
              <Box className="header">
                <StepperData 
                  key={`stepper${this.state.activeStep}`}
                  listData={this.state.progress_bars}
                  activeStep={this.state.activeStep}
                />
              </Box>
            </Box>

            <Box className="headingTitle">
              What ailments do you have?
            </Box>
            <Box className="basicContent"> if you are comfortable, could you please tell us what prompted you to seek our services?</Box>
            <h5 className="headQuestion">You can choose from the following options:</h5>
            <Box className="buttonContainer">
              {this.state.btnList.map((label, index) => (
                <button
                  onClick={() => this.handleClick(index)}
                  key={index}
                  data-test-id={label}
                  className={`btns ${ResolveCondition(this.state.selectedIndex === index, 'selected', '')} ${ResolveCondition(this.state.editableIndex === index, 'editing', '')}`}
                >
                  {ConditionalRenderer(this.state.editableIndex === index, <StyledField
                    autoFocus
                    data-test-id="fieldChanges"
                    id="ailmentTextField"
                    onChange={(e) => this.handleInput(e)}
                    value={this.state.value}
                    className="inputField"
                    onBlur={() => this.handleBlur()}
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    style={{ width: '100%', height: '100%' }}
                  />, label)}
                </button>
              ))}
            </Box>


            <Box className="footer-content">
              <div className="box-wrap" id="ailments">
                <div
                  className="greyColor"
                  data-test-id="btnback"
                  onClick={() => {
                    handleNavigation("ThanksForSharing", this.props);
                  }}
                >
                  <p>Back</p>
                </div>
              </div>

              <div className="box-wrap">
                <button
                  className={`button-continue ${ResolveCondition(this.state.selectedIndex !== null, 'active', 'inactive')}`}
                  data-test-id="continue"
                >
                  Continue
                </button>
              </div>

              <div className="box-wrapperRight">
                <div className="greyColor">
                  <p
                    data-test-id="btnSkip"
                    className="skipIcon"
                    onClick={() => {
                      handleNavigation("SupportDesign", this.props);
                    }}
                  >
                    Skip this Step &nbsp;
                    <span>
                      <img src={skip} alt="skip" />
                    </span>
                  </p>
                </div>
              </div>
            </Box>

            <Box className="footer">
              <p className="emergencyIcon"><span><img src={emergency} alt="emergency" /></span>&nbsp; If this is an emergency &nbsp; <u>please call 911</u></p>
              <div className="footerRight" id="AilmentFooter">
                <span className="label-700-20">Need Help?</span>
                <button className="callButton">
                  Schedule a call?
                  <span>
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>
          </section>
        </>
      </StyledWrapperAilments>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapperAilments = styled("div")({
  width: "100%",
  margin: "0 auto",
  "& *": {
    boxSizing: "border-box",
  },
  "& .btns.selected": {
    border: "none",
    background: "#E46760"
  },
  "& .btns": {
    height: "48px",
    borderRadius: "26px",
    width: "343px",
    margin: "auto",
    background: "#E7E5E4",
    color: "#44403C",
    border: "none",
    fontFamily: "Poppins",
    fontSize: "20px",
    textAlign: "left",
    paddingLeft: "16px",
    marginBottom: "12px",
    fontWeight: 400
  },
  "& .btns.editing": {
    border: "2px solid #E6C79C",
    background: "white",
  },
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    width: "327px",
    height: "48px",
    borderRadius: "26px",
    margin: "auto",
    border: "none",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    textAlign: "left",
    marginBottom: "12px",
    background: "none"
  },
  "& .MuiFormControl-root.MuiTextField-root.inputField.css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "343px",
    height: "48px",
    border: "none",
    borderRadius: "26px",
    color: "#44403C",
    margin: "auto",
    background: "#E7E5E4",
    fontFamily: "Poppins",
    textAlign: "left",
    fontWeight: 400,
    marginBottom: "12px",
    fontSize: "20px",
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
  },
  "& .callButton": {
    borderRadius: "16px",
    border: "1px solid #E46760",
    background: "#ffffff",
    color: "#E46760",
    padding: "5px 10px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    fontWeight: 700,
    fontFamily: "Raleway",
    lineHeight: "36px",
    fontSize: "20px",
  },
  "& .label-700-20": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  "& .footerRight": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  "& .emergencyIcon": {
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    display: "flex",
    fontSize: "20px",
  },
  "& .footer": {
    justifyContent: "space-between",
    display: "flex",
    borderTop: "1px solid #E7E5E4",
    marginTop: "126px",
    alignItems: "center",
    padding: "0px 141px",
    minHeight: "96px",
  },
  "& .button-continue": {
    width: "100%",
    marginTop:"32px",
    maxWidth: "392px",
    height: "56px",
    background: "#E46760",
    cursor: "pointer",
    borderRadius: "40px",
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Raleway",
    color: "#FFFAF6",
    border: "none",
  },
  "& .button-continue.active": {
    color: "#FFFFFF",
    background: "#E46760",
    fontFamily: "Raleway",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "700",
    border: "none",
  },
  "& .button-continue.inactive": {
    color: "#FFFFFF",
    background: "#D6D3D1",
    disableCursor: true,
    cursor: "not-allowed",
    fontWeight: "700",
    fontFamily: "Raleway",
    border: "none",
    fontSize: "20px",
  },
  "& .greyColor": {
    fontFamily: "Raleway",
    color: "#807C7C",
    fontSize: "20px",
    fontWeight: "700",
    cursor: "pointer",
  },
  "& .footer-content": {
    maxHeight: "56px",
    alignItems: "center",
    maxWidth: "1231px",
    marginTop: "32px",
    justifyContent: "space-evenly",
    display: "flex",
    margin: "auto",
  },
  "& .box-wrap": {
    maxWidth: "33.33%",
    width: "100%",
  },
  "& .box-wrapperRight": {
    maxWidth: "33.33%",
    width: "100%",
    justifyContent: "end",
    display: "flex",
  },
  "& .buttonContainer": {
    maxHeight: "636px",
    maxWidth: "700px",
    display: "flex",
    margin: "auto",
    marginTop: "32px",
    flexDirection: "column",
  },
  "& .headQuestion": {
    "maxHeight": "36px",
    "maxWidth": "733px",
    "alignItems": "center",
    "textAlign": "center",
    "justifyContent": "center",
    "display": "flex",
    "fontWeight": 700,
    "fontFamily": "Poppins",
    margin: "auto",
    marginTop: "32px",
    "fontSize": "24px",
  },
  "& .basicContent": {
    "maxHeight": "72px",
    "maxWidth": "733px",
    "alignItems": "center",
    "textAlign": "center",
    "justifyContent": "center",
    "display": "flex",
    "fontWeight": 400,
    "fontFamily": "Poppins",
    margin: "auto",
    "fontSize": "24px",
    marginTop: "32px"
  },  
  "& .headingTitle": {
    "height": "100%",
    "maxWidth": "1017px",
    "maxHeight": "53px",
    "width": "100%",
    "textAlign": "center",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "fontFamily": "Raleway",
    "fontSize": "41px",
    margin: "auto",
    "fontWeight": 600,
    marginTop: "15px"
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  "& .leftBorder": {
    borderLeft: "32px solid #1A4A42",
    height: "100%"
  },
  "& .custom-step-connector": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .MuiStepLabel-labelContainer": {
    alignItems: "center",
    flexDirection: "column",
  },
  "& .css-j5w0w9-MuiStepConnector-root": {
    display: "none",
  },
  "& .MuiStepper-root.MuiStepper-horizontal.css-rfs2u2-MuiStepper-root": {
    justifyContent: "space-between",
    position: "relative",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "10px",
  },
  "& .custom-step-connector .MuiStepLabel-iconContainer.Mui-active.css-vnkopk-MuiStepLabel-iconContainer::after":
  {
    backgroundColor: "#A8A29E",
    height: "3px",
    position: "absolute",
    content: `""`,
    top: "10px",
    left: "44px",
    width: "239px",
  },
  "& .custom-step-connector .MuiStepLabel-iconContainer.Mui-active.css-vnkopk-MuiStepLabel-iconContainer::before":
  {
    position: "absolute",
    width: "239px",
    backgroundColor: "#A8A29E",
    content: `""`,
    right: "53px",
    height: "3px",
    top: "10px",
  },
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: "#2C2524",
  },

});

const CustomStepper = styled(Stepper)({
  width: "100%",
  maxWidth: "600px",
  margin: "20px auto",
  "& .MuiStepLabel-labelContainer": {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .MuiStepIcon-root": {
    marginBottom: "8px",
  },
});

const CustomStepConnector = styled(StepConnector)({
  "&.MuiStepConnector-active": {
    backgroundColor: "#000000",
  },
});
const CustomStepLabel = styled(StepLabel)({
  display: "flex",
  flexDirection: "column",
});

const StyledField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "26px",
    height: "100%",
    width: "100%",
    maxHeight: "44px",
    maxWidth: "340px",
    border: "none",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "20px",
    padding: "10px 16px",
    boxSizing: "border-box",
    border: "none",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "20px",
    color: "#999",
    fontFamily: "Poppins",
  },
});




export default AilmentsPage;
// Customizable Area End
