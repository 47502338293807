import React from "react";

// Customizable Area Start
import { Box, styled, Modal, TextField } from "@mui/material";
// Customizable Area End

import SaveProgressController, { Props } from "./SaveProgressController";
import { call1, cross } from "./assets";

export default class SaveProgress extends SaveProgressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapperForSaveProgress>
        <section className="about-section">
          <Box className="container">
            <Modal open={this.state.open}>
              <ModalWrapper>
                <Box className="modal-wrapper">
                  <Box className="heading">
                    <p className="head">
                      Looks like you previously started an application
                    </p>
                    <img src={cross} alt="cross" className="crossBtn" />
                  </Box>
                  <Box>                    
                    {this.state.modalContent === "email" ? (
                      <p className="primaryText">
                        Enter the temporary password we sent to your email when
                        you first started the signup process
                      </p>
                    ) : (
                      <p className="primaryText2">
                        We've sent the temporary password to the mobile number
                        you gave us when you started your application, ending in
                        <b>**1234**</b>
                      </p>
                    )}
                  </Box>
                  <Box className="fieldContainer">
                    <div className="label">Enter Password</div>
                    <Box className="input">
                      <TextField placeholder="Temporary Password" />
                    </Box>
                  </Box>
                  <Box className="content">
                    <p className="para">
                      I didn't receive the password,{" "}
                      <span className="spanContent">
                        <u>please resend it</u>
                      </span>
                    </p>
                    {this.state.modalContent === "email" ? (
                      <p className="para">
                        Send me the password by,{" "}
                        <span
                          className="spanContent"
                          data-test-id="smsInstead"
                          onClick={() => {
                            this.setState({ modalContent: "sms" });
                          }}
                        >
                          <u>SMS instead</u>
                        </span>
                      </p>
                    ) : (
                      <p className="para">
                        Send me the password by,{" "}
                        <span
                          className="spanContent"
                          onClick={() => {
                            this.setState({ modalContent: "email" });
                          }}
                          data-test-id="emailInstead"
                        >
                          <u>email instead</u>
                        </span>
                      </p>
                    )}
                  </Box>

                  <Box className="footer">
                    <button className="submitBtn">Submit</button>
                    <p className="footerContent">
                      Or call us on <b>1-800-567-LIFE</b>
                      <img height="30px" width="30px" src={call1} alt="call1" />
                    </p>
                  </Box>
                </Box>
              </ModalWrapper>
            </Modal>
          </Box>
        </section>
      </StyledWrapperForSaveProgress>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapperForSaveProgress = styled("div")({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .container": {
    height: "100%",
    width: "100%",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
});

const ModalWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "20%",
  left: "50%",
  width: "100%",
  maxWidth: "710px",
  backgroundColor: "#fff",
  margin: "auto",
  borderRadius: "8px",
  transform: "translateX(-50%)",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
  "& .input .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
  }, 
  "& .footerContent": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    margin: "auto",
    paddingTop: "24px",
  },
  "& .submitBtn": {
    width: "232px",
    height: "56px",
    backgroundColor: "#E46760",
    borderRadius: "24px",
    border: "none",
    color: "#FFFAF6",
    fontFamily: "Raleway",
    margin: "auto",
    fontWeight: 700,
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .footer": {
    maxWidth: "710px",
    maxHeight: "158px",
    borderTop: "1px solid #E7E5E4",
    paddingTop: "24px",
  },
  "& .spanContent": {
    color: "#E46760",
    cursor: "pointer",
  },
  "& .para": {
    maxWidth: "392px",
    maxHeight: "24px",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#44403C",
  },
  "& .content": {
    maxWidth: "392px",
    maxHeight: "68px",
    gap: "20px",
    margin: "auto",
    marginTop: "32px",
  },
  "& .input .MuiFormControl-root":
    {
      width: "100%",
    },
  "& .input .MuiInputBase-root":
    {
      padding: "10px 12px 10px 16px",
      maxWidth: "392px",
      maxHeight: "44px",
      borderRadius: "24px",
      border: "1px solid #E6C79C",
      margin: "auto",
      width: "100%",
    },
  "& .label": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    textAlign: "left",
    margin: "auto",
    lineHeight: "24px",
    marginTop: "32px",
    maxWidth: "392px",
    marginBottom: "5px",
  },
  "& .fieldContainer": {
    maxHeight: "72px",
    height: "100%",
  },
  "& .primaryText": {
    maxHeight: "60px",
    maxWidth: "584px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    textAlign: "center",
    margin: "auto",
    marginTop: "32px",
    color: "#44403C",
  },
  "& .primaryText2": {
    maxHeight: "90px",
    maxWidth: "584px",
    fontFamily: "Poppins",
    fontWeight: 401,
    fontSize: "20px",
    textAlign: "center",
    margin: "auto",
    marginTop: "32px",
    color: "#44403C",
    lineHeight:"30px"
  },
  "& .modal-wrapper": {
    margin: "32px 40px",
  },
  "& .head": {
    maxWidth: "456px",
    maxHeight: "82px",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "29px",
    textAlign: "center",
    margin: "auto",
  },
  "& .heading": {
    width: "630px",
    height: "82px",
    display: "flex",
  },
  "& .crossBtn": {
    height: "32px",
    width: "32px",
  },
});

// Customizable Area End
