// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TextField,
    InputLabel
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { HeaderLogoImg, activeCall, activeChat, chatIcon, dropdownIcon, uploadMediaIcon, sendIcon } from './assets';

import NeedHelpController, {
    Props,
} from "./NeedHelpController";
import { ResolveCondition, handleNavigation } from '../../../blocks/utilities/src/CustomHelpers';

class NeedHelp extends NeedHelpController {
    constructor(props: Props) {
        super(props);
    }

    handleDisabledBtn = (data: string | boolean) => {
        return data ? false: true
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="container-elm">
                    <Box className="header-section">
                        <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
                    </Box>
                    <Box className="content-container">
                        <Typography
                            variant="h1" 
                            component="h2"
                            className='headingText'
                            dangerouslySetInnerHTML={{__html: "Need Help? <span style='color: #1A4A42'>Let us Help You</span>"}}
                        />
                        <Typography
                            className='bodyText'
                            dangerouslySetInnerHTML={{__html: "Whether you have a quick question or need more personalized assistance, we're here to help you every step of the way."}}
                        />
                        <Typography
                            className='subText'
                            dangerouslySetInnerHTML={{__html: "Choose from our convenient options:"}}
                        />
                        <CustomButton data-test-id="button-elm" onClick={() => this.handleChat()} disabled={this.handleDisabledBtn(this.state.button_text)} className="submit-button"><span>{this.state.button_text}</span><img src={this.state.button_text ? activeChat: chatIcon} alt="chat button" /></CustomButton>
                    </Box>
                    <Box className="card-container">
                        <Box className="card">
                            <Typography
                                variant="h1" 
                                component="h2"
                                className='cardHeaderText'
                                dangerouslySetInnerHTML={{__html: "Call us Now"}}
                            />
                            <Typography
                                className='cardBodyText'
                                dangerouslySetInnerHTML={{__html: "Get instant support from a real person"}}
                            />
                            <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.button_text)} className="call-button"><span>1-800-567-LIFE</span><img src={activeCall} alt="call button" /></CustomButton>
                        </Box>
                        <Box className="card">
                            <Box style={{display: "flex", gap: "4px", flexDirection: "column"}}>
                                <Typography
                                    variant="h1" 
                                    component="h2"
                                    className='cardHeaderText'
                                    dangerouslySetInnerHTML={{__html: "Request a call back"}}
                                />
                                <Typography
                                    className='cardBodyText'
                                    dangerouslySetInnerHTML={{__html: "Chose a time that best suits you"}}
                                />
                                <Typography
                                    className='cardSubHeaderText'
                                    dangerouslySetInnerHTML={{__html: "*Calls will be returned today or on the next business day"}}
                                />
                            </Box>
                            <Box className={`menu-container ${this.state.toggleMenu ? 'active': ''}`} ref={this.dropDownRef}>
                                <CustomBox className="select-btn" data-test-id="menu-section" onClick={() => this.toggleMenuData()}>
                                    <span className={`${ResolveCondition(this.state.selectedMenu,'active-text','')}`}>{this.state.selectedMenu || "Choose a time frame"}</span>
                                    <img className={`${ResolveCondition(this.state.toggleMenu,'active','')}`} src={dropdownIcon} alt="dropdown" />
                                </CustomBox>
                                {this.state.toggleMenu && <>
                                {this.state.menuList.map(item =>
                                    <Box key={`menuData${item}`} data-test-id="menu-data" className={`menu-text default-text ${ResolveCondition(this.state.selectedMenu === item,"selected","")}`} onClick={() => this.handleSelectedData(item)}><span className='active-text'>{item}</span></Box>
                                )}</>}
                            </Box>
                            <CustomButton data-test-id="button-elm" onClick={() => handleNavigation("scheduledCallback", this.props)} disabled={this.handleDisabledBtn(this.state.selectedMenu)} className="confirm-button">Confirm Request</CustomButton>
                        </Box>
                    </Box>
                    <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.button_text)} className="back-button">Back</CustomButton>
                </Box>
                {this.state.showModal && <CustomDialog
                    maxWidth="md"
                    open={this.state.showModal}
                    onClose={() => this.handleCloseModal()}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    className='chat-modal'
                    data-test-id="close-modal"
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Hello! Need Help?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        data-test-id="close-modal"
                        onClick={() => this.handleCloseModal()}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography
                            className='bodyText'
                            dangerouslySetInnerHTML={{__html: "Lorem ipsum dolor sit amet consectetur. Ac diam a cum sed nisl fringilla quis molestie urna. Id sit aliquet."}}
                        />
                        <Box className="chat-container">
                            <Typography
                                className='headingTextData'
                                dangerouslySetInnerHTML={{__html: "Session Chat"}}
                            />
                            <Box style={{ padding: "0 12px", gap: "10px", display: "flex", flexDirection: "column" }}>
                                <Box className="reply-section">
                                    <Typography><span className='nameField'>Total Life:</span><span className='time-field'>14:37</span></Typography>
                                    <Typography className='bodyElm'>Hi there! I'm here to help.What can I assist you with today?</Typography>
                                </Box>
                                <Box className="reply-section client">
                                    <Typography><span className='nameField client'>You:</span><span className='time-field'>14:38</span></Typography>
                                    <Typography className='bodyElm client'>I need some help with...</Typography>
                                </Box>
                            </Box>
                            <Box className="footer-elm">
                                <CustomTextField 
                                    multiline
                                    data-test-id="message-field"
                                    value={this.state.messageText}
                                    onChange={(event) => this.handleMessage(event.target.value)}
                                    placeholder='Type here'
                                />
                                <Box className="right-section">
                                    <InputLabel >
                                        <img src={uploadMediaIcon} alt="upload media" />
                                        <CustomTextField
                                            ref={this.uploadRef}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFile(event)}
                                            style={{display: "none"}}
                                            data-test-id="file-field"
                                            type='file'
                                        />
                                    </InputLabel>
                                    <CustomButton data-test-id="button-elm" onClick={() => {}} disabled={this.handleDisabledBtn(this.state.messageText)} className="send-button">
                                        <img src={sendIcon} alt="send btn" />
                                    </CustomButton>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                </CustomDialog>}
            </StyledWrapper>
        );
    }
}

const CustomTextField = styled(TextField)(({
    width: "88%",
    overflow: "auto",
    MsoverflowStyle: "none",
    scrollbarWidth: "none",
    '&::-webkit-scrollbar': {
        display: "none"
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      height: "44px",
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiInputBase-input': {
        fontFamily: "Poppins,sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        padding: '12px',
        color: "#2C2524",
        '&::placeholder': {
            color: "#A8A29E",
            opacity: 1
        }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        display: "flex",
        alignItems: "center",
        border: "1px solid transparent"
      },
      '&:hover fieldset': {
        border: "1px solid transparent"
      },
      '&.Mui-focused fieldset': {
        border: "1px solid transparent"
      },
    },
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      boxShadow: "none",
      borderRadius: "24px"
    },
    "& .bodyText, & .MuiDialogTitle-root": {
        fontFamily: "Poppins,sans-serif",
        textAlign: "center",
        backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
            linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textFillColor: "transparent"
    },
    '& .MuiDialogTitle-root': {
        fontSize: "29px",
        fontWeight: 500,
        lineHeight: "40.6px",
        letterSpacing: "0.5px"
    },
    "& .bodyText": {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px"
    },
    "& .chat-container": {
        marginTop: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        borderRadius: "16px",
        border: "1px solid #D6D3D1",
        "& .headingTextData": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#000"
        }
    },
    "& .footer-elm": {
        height: "68px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderTop: "1px solid #D6D3D1"
    },
    "& .reply-section": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "6px",
        borderRadius: "8px",
        backgroundColor: "#E8EDEC",
        width: "40%",
        maxWidth: "265px",
        "&.client": {
            border: "1px solid #E46760",
            backgroundColor: "transparent",
            alignSelf: "end",
            maxWidth: "192px"
        },
        "& .nameField, & .time-field": {
            fontFamily: "Poppins,sans-serif",
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "19.5px",
        },
        "& .time-field": {
            color: "#807C7C"
        },
        "& .bodyElm": {
            fontFamily: "Inter,sans-serif",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#44403C",
            "&.client": {
                color: "#57534E"
            }
        },
        "& .nameField": {
            color: "#1A4A42",
            fontStyle: "italic",
            marginRight: "8px",
            "&.client": {
                backgroundImage: `linear-gradient(0deg, #2C2524, #2C2524),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
                color: "transparent",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textFillColor: "transparent",
                fontStyle: "normal",
                fontWeight: 700
            }
        }
    },
    "& .right-section": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        "& .send-button": {
            width: "40px",
            height: "40px",
            borderRadius: "6.15px",
            backgroundColor: "#1A4A42",
            minWidth: "20px"
        },
        '& .Mui-disabled.send-button': {
            opacity: 0.4
        }
    },
    "& .MuiDialogContent-root": {
        overflow: "auto",
        MsoverflowStyle: "none",
        scrollbarWidth: "none",
        '&::-webkit-scrollbar': {
            display: "none"
        }
    }
}));

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    '& .container-elm': {
        backgroundColor: "#fff",
        height: "auto",
        minHeight: "78px",
        display: "flex",
        flexDirection: "column",
        borderLeft: "32px solid #1A4A42"
    },
    '& .content-container': {
        display: "flex",
        flexDirection: "column",
        width: "48%",
        margin: "0 auto",
        "& .headingText, & .subText, & .bodyText": {
            textAlign: "center",
            margin: 0
        },
        "& .headingText": {
            fontFamily: "Raleway",
            fontSize: "50px",
            fontWeight: 700,
            lineHeight: "120%",
            letterSpacing: "0.5px",
            marginTop: "75px"
        },
        "& .subText": {
            color: "#2C2524",
            fontWeight: 700,
            marginBottom: "12px"
        },
        '& .bodyText': {
            color: "#44403C",
            fontWeight: 400,
            margin: "12px 0"
        },
        "& .subText, & .bodyText": {
            fontFamily: "Poppins,sans-serif",
            fontSize: "20px",
            lineHeight: "30px"
        }
    },
    "& .header-section": {
        height: "78px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #E7E5E4",
        width: "100%",
        '& img': { paddingLeft: "3%" },
    },
    '& .card-container': {
        display: "flex",
        gap: "24px",
        margin: "56px auto 68px",
        width: "70%",
        '& .card': {
            padding: "20px 30px",
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "16px",
            border: "1px solid #D6D3D1",
            width: "45%",
            '& .cardHeaderText, & .cardSubHeaderText, & .cardBodyText': {
                fontFamily: "Poppins,sans-serif",
                textAlign: "center"
            },
            '& .cardHeaderText': {
                fontSize: "35px",
                fontWeight: 500,
                lineHeight: "49px",
                letterSpacing: "0.5px",
                color: "#2C2524"
            },
            '& .cardSubHeaderText': {
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "19.5px",
                color: "#44403C"
            },
            '& .cardBodyText': {
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#44403C"
            }
        }
    },
    '& .menu-text': {
        height: "46px",
        padding: "0 12px 0 16px",
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
        color: "#44403C",
        fontFamily: "Poppins,sans-serif",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        borderBottom: "1px solid #D6D3D1",
        ':last-child': {
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
            borderBottom: "none"
        },
        "&.selected": {
            backgroundColor: "#F5F5F4"
        }
    },
    '& .menu-container': {
        borderRadius: "24px",
        border: "none",
        '&.active': {
            outline: "2px solid #E6C79C",
            borderTop: "none !important",
            "& .select-btn": {
                outlineWidth: "2px"
            }
        }
    },
});

const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    borderRadius: '24px',
    height: "56px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    '&.submit-button, &.confirm-button': {
        backgroundColor: '#E46760',
        color: '#FFFAF6',
        width: "48%",
        margin: "28px auto 0"
    },
    '&.back-button': {
        backgroundColor: 'transparent',
        color: '#78716C',
        width: "max-content",
        height: "max-content",
        marginLeft: "14.5%",
        marginBottom: "124px"
    },
    '&.confirm-button': {
        width: "100%",
        marginTop: "0"
    },
    '&.call-button': {
        backgroundColor: '#F5F5F4',
        color: '#2C2524',
        width: "100%",
    },
    '&.Mui-disabled.submit-button, &.Mui-disabled.confirm-button': {
        backgroundColor: '#E7E5E4',
        color: "#A8A29E",
        cursor: 'not-allowed',
        boxShadow: 'none',
        pointerEvents: 'none',
    },
    '&.submit-button:hover': {
        backgroundColor: '#E46760',
    },
}))

const CustomBox = styled(Box)(({ theme }) => ({
    '&.select-btn': {
        borderRadius: "24px",
        outline: "1px solid #E6C79C",
        height: "56px",
        backgroundColor: '#fff',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 12px 0 16px",
        fontFamily: "Poppins,sans-serif",
        color: "#A8A29E",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        '& img.active': { rotate: "180deg" },
        '& .active-text': {
            color: "#2C2524"
        }
    },
}))

export default NeedHelp;
// Customizable Area End