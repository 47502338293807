import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';

interface ImageUrls {
  image: string;
}
interface PrimaryLinksAttributes {  
  primary_button_text: string;
  primary_link_url: string;
  external_link: boolean
}
interface SecondaryLinkAttributes {
  secondary_button_text: string;
  secondary_link_url: string;
  external_link: boolean
}
interface SingleWellnessPage{
  id: number | null;
  main_title : string,
  sub_title : string,
  main_body : string,
  button_text : string,
  page_type : string,
  image_urls : ImageUrls,
  primary_links_attributes : PrimaryLinksAttributes[];
  secondary_links_attributes : SecondaryLinkAttributes[];
  help_footer : boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes :Object;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseWellness: SingleWellnessPage,
  message: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WellnessLandingPage extends BlockComponent<
  Props,
  S,
  SS
> {
  getWellnessMembershipCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      responseWellness:{
        id : null,
        main_title : "",
        sub_title : "",
        main_body : "",
        button_text : "",
        page_type : "",      
        image_urls: {
          image: ""
        },
        primary_links_attributes : [],
        secondary_links_attributes : [],
        help_footer : false
      },
      message: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleWellnessMembership(from,message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getWellnessPage()
  }
  getWellnessPage = () => { 
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || ''; 
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    let body = {
      "unique_slug": unique_slug,
      "page_type": "Single Service Landing Pages"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWellnessMembershipCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWellnessPage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleWellnessMembership =  async (from: string, message: Message) => {
    if (
      this.getWellnessMembershipCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({responseWellness:responseJson.data.attributes , message: ""})
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({message: "Page not found"})
        }
        if (responseJson.errors) {
          this.setState({message: "Page not found"})
        }
      }
    }
  }


handleNavigation = (pathname: string) => {
  const paramsData = pathname.split('/');
  const message: Message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), paramsData[0]);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  paramsData[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsData[1]);
  runEngine.sendMessage(message.id, message)
};
  // Customizable Area End
}
