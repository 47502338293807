// Customizable Area Start
import React from "react";
import {
  Box, styled,
} from "@mui/material";
import SupportDesignController, {
  Props,
} from "./SupportDesignController";
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import { skip } from './assets';
import { ResolveCondition, handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";


class SupportDesign extends SupportDesignController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <StyledWrapperSupportDesign>
        <>
          <section className="leftBorder">
            <Box className="container">
              <Box className="headerStepper">
                <StepperData 
                  key={`stepper${this.state.activeStep}`}
                  listData={this.state.listData}
                  activeStep={this.state.activeStep}
                />
              </Box>
              <Box className="heading">Support Designed for You</Box>
              <Box className="instruction">Choose all that apply for personalized care tailored for you</Box>
              <Box className="box-Container">
                {
                  this.state.box.map((item) => (
                    <Box className={`boxes ${this.state.selectedData.includes(item.id) ? "active" : ""}`}>
                      <img className="images" src={item.img} alt="hearing" />
                      <Box className="boxTitle"><b>{item.title}</b></Box>
                      <Box className="boxDescription">{item.description}</Box>
                      <button 
                      data-test-id = {item.id}
                      className={`boxBtn ${ResolveCondition(this.state.selectedData.includes(item.id),"active","")}`} onClick={()=>
                      {
                        this.handleClick(item.id)                        
                      }}>{this.state.selectedData.includes(item.id) ? "Selected" : item.btn_text}</button>
                    </Box>
                  ))
                }
              </Box>
              <Box className="footer-container" id="footerSupport">
                <div className="box-wrapper" style={{ display: "flex", "justifyContent": "left" }}>
                  <div
                    className="greyColor"
                    data-test-id = "btnback"
                    onClick={() => {
                      handleNavigation("HealthReview", this.props);
                    }}
                  >
                    <p>Back</p>
                  </div>
                </div>

                <div className="box-wrapper" id="footerSupport" style={{ display: "flex", "justifyContent": "center" }}>
                  {" "}
                  <button
                  data-test-id = "btnContinue"
                    className={`button-continue ${ResolveCondition(
                      this.state.selectedData.length > 0,
                      "active",
                      "inactive"
                    )}`}
                    disabled={
                      !(
                        this.state.selectedData !== undefined &&
                        this.state.selectedData.length > 0
                      )
                    }
                    onClick={() => {
                      handleNavigation("LastQuestion", this.props);
                    }}
                  >
                    Continue
                  </button>
                </div>

                <div className="box-wrapper" id="footerSupport" style={{ display: "flex", "justifyContent": "right" }}>
                  <div className="greyColor">
                    <p
                    data-test-id = "btnSkip"
                     onClick={() => {
                      handleNavigation("LastQuestion", this.props);
                    }}
                      className="skipIcon"
                    >
                      Skip this Step &nbsp;
                      <span>
                        <img src={skip} alt="skip" />
                      </span>
                    </p>
                  </div>
                </div>
              </Box>
              <FooterData />
            </Box>
          </section>
        </>
      </StyledWrapperSupportDesign>
    );
  }
}

const StyledWrapperSupportDesign = styled("div")({
  width: "100%",
  margin: "0 auto",
  "& *": {
    boxSizing: "border-box",
  },
  "& .boxBtn.active":{
    width : "184px", 
    height :"32px",
    color : "#FFFFFF",
    border : "1px solid #E46760",
    borderRadius : "16px",    
    marginTop : "10px",
    marginBottom : "12px",
    flexShrink : 0,
    background : "#E46760",
    fontWeight : 700,
    fontSize : "16px",
    fontFamily : "Poppins"
  },
  "& .boxBtn":{
    width : "184px", 
    height :"32px",
    color : "#E46760",
    border : "1px solid #E46760",
    borderRadius : "16px",
    background : "transparent",
    marginTop : "10px",
    marginBottom : "12px",
    flexShrink : 0
  },
  "& .boxDescription":{
    height :"100%",
    width : "256px",
    paddingTop : "10px",
    textAlign : "center",
    fontFamily : "Poppins",
    fontWeight : 400,
    fontSize : "16px",
    color : "#807C7C"
  },
  "& .boxTitle": {
    width: "auto",
    textAlign: "center",
    height: "30px",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    paddingTop : "12px",
  },
  "& .images": {
    height: "72px",
    width: "72px"
  },
  "& .boxes": {
    flexGrow: 1,
    flexBasis: "calc(33.33% - 20px)",
    minWidth: "250px",
    margin: "10px",
    border: "1px solid #D6D3D1",
    borderRadius: "16px",
    justifyContent: "space-between",
    padding: "10px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .boxes.active": {
    border: "2px solid #E46760", 
  },
  
  "& .box-Container": {
    width: "100%",
    maxWidth : "960px",
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  "& .instruction": {
    height: "100%",
    width: "100%",
    maxHeight: "36px",
    maxWidth: "731px",
    textAlign: "center",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    marginTop: "32px",
    marginBottom: "32px"
  },
  "& .heading": {
    height: "100%",
    width: "100%",
    maxHeight: "53px",
    maxWidth: "1017px",
    textAlign: "center",
    margin: "auto",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    marginTop: "60px"
  },
  "& .headerStepper": {
    "display": "flex",
    "paddingTop": "32px",
    "justifyContent": "center",
  },
  "& .box-wrapper": {
    width: "100%",
    maxWidth: "33.33%",
  },
  "& .leftBorder": {
    height: "auto",
    borderLeft: "32px solid #1A4A42",
  },
  "& .button-continue": {
    width: "100%",
    maxWidth: "392px",
    height: "56px",
    background: "#E46760",
    cursor: "pointer",
    borderRadius: "40px",
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Raleway",
    border: "none",
    color: "#FFFFFF"
  },
  "& .footer-container": {
    justifyContent: "center",
    margin: "auto",
    marginBottom: "112px",
    marginTop: "100px",
    maxWidth: "60%",
    display: "flex",
  },
  "& .button-continue.active": {
    color: "#FFFFFF",
    cursor: "pointer",
    background: "#E46760",
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Raleway",
    border: "none",
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
  },
  "& .button-continue.inactive": {
    cursor: "not-allowed",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    background: "#D6D3D1",
    disableCursor: true,
    fontWeight: "700",
    border: "none",
    fontSize: "20px",
  },
  "& .greyColor": {
    fontFamily: "Raleway",
    color: "#807C7C",
    fontSize: "20px",
    fontWeight: "700",
    cursor: "pointer",
  },

});

export default SupportDesign;
// Customizable Area End
