// Customizable Area Start
import React from 'react';
import {
    Box,
    Button,
    Typography,
    styled
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import WellnessMemebershipDetailsController, {
    Props,
} from "./WellnessMemebershipDetailsController";
import {
  ConditionalRenderer,
    handleNavigation,
  } from "../../../blocks/utilities/src/CustomHelpers";
import { call } from './assets';
import Loader from '../../../../../src/packages/components/src/Loader.web';

class WellnessMemebershipDetails extends WellnessMemebershipDetailsController {
    constructor(props: Props) {
        super(props);
    }

    handleButtonElm = (
      button_text: string,
      urlData: string,
      external_link: boolean,
    ) => {
      if (external_link) {
          return (
            <a
              className={`buttonSecond`}
              data-test-id={"button_text"}
              href={urlData}
              rel="noopener noreferrer"
              key={button_text}
            >
              {button_text}
            </a>
          );
      } else {
        return (
          <Button className='actions-btn'
          data-test-id="choose-plan"
          key={button_text}
          onClick={() => this.handleNavigation(urlData)}
          >
              {button_text}
          </Button>
        );
      }
    };
    render() {
      const benefits = this.state.planDetailsData?.attributes?.benefits || '';
    const paragraphs = benefits
        .split(/\r\n/)
        .filter((p: string) => p.trim() !== '');
        const {loading} = this.state
        return (

          <>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <StyledWrapper>
                <Box
                    sx={{
                    bgcolor: 'white',
                    textAlign: 'center',
                    borderLeft: "32px solid #1A4A42"
                }}
    >
      <Typography variant="h4" component="h1" gutterBottom className='heading-membership'>
                  {this.state.planDetailsData?.attributes?.title ?? null}
      </Typography>

      <Typography variant="body1" color="textSecondary" paragraph className='subtitle-wellness'
       dangerouslySetInnerHTML={{__html: this.state?.planDetailsData?.attributes?.description ?? null}}
        />
          
      <Box className="benefits">
      <Typography variant="h6" component="h2" className='benefits-title'>
                    {this.state.planDetailsData?.attributes?.benefits_heading ?? null}
      </Typography>
      <Box sx={{ textAlign: 'left',marginTop: '24px' }}>
          <Box sx={{ display: 'flex'}}>
            <div className="benefit-item">
          {paragraphs?.map((paragraph:string, index:number) => (
                          <div key={index} style={{ display: "flex", alignItems : "center" }}>
          <CheckCircleOutlineIcon className="circle-icon" />
          <Typography
            className="benefits-description"
            dangerouslySetInnerHTML={{ __html: `${paragraph}</p>` }} 
            />
            </div>
        ))}
        </div>
          </Box>
      </Box>
      <Typography variant="h6" component="h2" style={{
        marginTop:"24px"
      }} className='benefits-title'>
                    {this.state.planDetailsData?.attributes?.other_benefits_heading ?? null}
                  </Typography>
          
                  <Box sx={{ textAlign: 'left', marginTop: '24px' }}>
                    {paragraphs?.map((paragraph: string, index: number) => (
                      <div key={index} style={{ display: "flex", alignItems : "center" }}>
                        <CheckCircleOutlineIcon className="circle-icon" />
                        <Typography
                          className="benefits-description"
                          dangerouslySetInnerHTML={{
                            __html: `${paragraph}</p>`,
                          }}
                        />
                      </div>
                    ))}
                  </Box>
          
                  <Typography variant="h5" className="pricing">
                  {this.state.planDetailsData?.attributes?.price && this.state.planDetailsData?.attributes?.time_period &&
  `$${this.state.planDetailsData.attributes.price} / ${this.state.planDetailsData.attributes.time_period}`}
                  </Typography>
                </Box>
                <Box>
                {this.state.planDetailsData?.attributes?.sub_primary_links.map((link: { primary_button_text_1: string; primary_link_url_1: string; external_link_1: boolean; }, index: number) => (
                                        ConditionalRenderer(
                                            Boolean(link?.primary_button_text_1),
                                            this.handleButtonElm(link?.primary_button_text_1, link?.primary_link_url_1, link?.external_link_1),
                                            ""
                                        )
                                    ))}
                   <Button variant="text" className='view-more-plan'
                    data-test-id="view-more-plan"
                    onClick={() => {
                        handleNavigation("WellnessMembershipPlan", this.props);
                      }}>
                      {this.state.planDetailsData?.attributes?.secondary_button_text_1}
                    </Button>
                  </Box>
              <Box className="footerContain">
              <div className="rightFooter" id="footer">
                <span className="needHelp">Need Help?</span>
                <button className="scheduleCall">
                  Schedule a call?
                  <span>
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>
    </Box>
    <Box className="box-wrapper">
                            <div
                                className="greyColor"
                                data-test-id="back-btn"
                                onClick={() => {
                                    this.props.navigation.goBack()
                                }}
                            >
                                <p>Back</p>
                            </div>
                        </Box>
            </StyledWrapper>
          )}
          </>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .form-container": {
        minHeight: "78px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        borderLeft: "32px solid #1A4A42"
    },
    "& .heading-membership": {
        fontSize:"50px",
        fontWeight: 700,
        fontFamily: 'Raleway',
        letterSpacing: '0.5px',
        lineHeight: '60px',
        width: '808px',
        margin: '76px auto 20px auto',
    },
    "& .buttonSecond": {
      display: "flex",
      justifyContent: "center",
      margin: "20px auto 0",
      cursor: "pointer",
      textDecoration: "none",
      width: "600px",
      padding: "16px 20px",
      paddingLeft: "20px",
      paddingRight: "20px",
      borderRadius: "24px",
      border: "2px solid #E46760 !important",
      fontWeight: 700,
      backgroundColor: "#E46760 !important",
      fontFamily: "Raleway !important",
      fontSize: "20px",
      lineHeight: "23.48px",
      letterSpacing: "1px",
      color: "#FFFAF6"
    },
    "& .subtitle-wellness": {
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '30px',
        width: '584px',
        margin: 'auto',
        color: 'rgba(28, 25, 23, 1)'
    },
    "& .benefits" : {
        width: '539px',
        margin: '58px auto'
    },
    ".benefits-description p": {
      margin: "0px"
    },
    "& .benefits-title": {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '29px',
        display: 'flex',
        lineHeight: '40.6px',
        letterSpacing: '0.5px'
    },
    "& .benefits-description" : {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '40.6px',
    },
    "& .pricing": {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '29px',
        lineHeight: '40.6px',
        letterSpacing: "0.5px",
        display:'flex',
        marginTop: "24px"
    },
    "& .actions-btn": {
        background: 'rgba(228, 103, 96, 1)',
        fontFamily: 'Raleway',
        fontWeight: 700,
        fontSize: '20px',
        display: 'flex',
        lineHeight: '23.8px',
        letterSpacing: '1px',
        width: '600px',
        margin: 'auto',
        height: '56px',
        borderRadius:"24px",
        padding: "16px 20px",
        textTransform: "capitalize",
        color:"white",
        '&:hover': {
            border: '1px solid rgb(228, 103, 96)',
            backgroundColor: 'white',
            color: 'rgb(228, 103, 96)'
          },
        "@media screen and (max-width: 1200px)": {
            width: '380px',
      }
    },
    "& .view-more-plan" : {
        color: 'rgba(120, 113, 108, 1)',
        fontSize: '20px',
        fontFamily: 'Raleway',
        fontWeight: 700,
        lineHeight: '23.8px',
        letterSpacing: '1px',
        textTransform: "inherit",
        marginTop: '32px'
    },
    "& .footerContain": {
        justifyContent: "end",
        display: "flex",
        borderTop: "1px solid #E7E5E4",
        minHeight: "96px",
        padding: "0px 141px",
        marginTop:"193px"
      },
      "& .rightFooter": {
        alignItems: "center",
        gap: "12px",
        display: "flex",
      },
      "& .needHelp": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "20px",
      },
      "& .scheduleCall": {
        borderRadius: "16px",
        background: "#ffffff",
        padding: "5px 10px",
        border: "1px solid #E46760",
        display: "flex",
        color: "#E46760",
        gap: "8px",
        alignItems: "center",
        fontWeight: 700,
        fontFamily: "Raleway",
        lineHeight: "36px",
        fontSize: "20px",
      },
    "& .box-wrapper": {
        width: "200px",
        height: "23px",
        marginLeft: "282px",
        marginTop: "-420px",
        zIndex:999,
        "@media screen and (max-width: 1200px)": {
            marginLeft: "50px",
      }
    },
      "& .greyColor": {
        fontFamily: "Raleway",
        fontWeight: "700",
        fontSize: "20px",
        cursor: "pointer",
        color: 'rgba(120, 113, 108, 1)',
      },
      "& .circle-icon": {
        width: '20px',
        height: '20px',
        marginRight: "9px",
        marginTop: "2px",
        color:"#1A4A42",
        marginBottom: "8px"
      }
});


export default WellnessMemebershipDetails;
// Customizable Area End