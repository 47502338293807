Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getDualLandingData = "bx_block_landingpage2/landing_page_questions/get_therapy_landing_page";
exports.getDualLandingWellnessData = "bx_block_landingpage2/landing_page_questions/get_wellness_landing_page";
exports.getWellnessPage = "bx_block_landingpage2/landing_page_questions/get_single_service_wellness_page";
exports.getTherapyPage = "bx_block_landingpage2/landing_page_questions/get_single_service_therapy_page";

exports.getWellnessPage = "bx_block_landingpage2/landing_page_questions/get_multiple_page_instance";

exports.getWellnessMembershipPage = "bx_block_plan/plans"
exports.getAPiMethod = "GET"
exports.landingPages = "bx_block_landingpage2/landing_page_questions/get_multiple_page_instance";
exports.landingpageNew = "POST"

exports.getMembershipPlansDetailsApiEndpoint = "bx_block_plan/sub_plans";
// Customizable Area End