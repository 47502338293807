import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
let btnListInitial = ["Adhd","Anxiety conditions","Substance abuse","Autism","Lorem","Lipsum","Prefer not to say","Other(specify)"]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string; 
}

interface S {
  // Customizable Area Start
  value: string;
  editableIndex: number | null;
  selectedIndex: number | null;
  btnList: string[];
  progress_bars: {progress_bar_text: string, progress_bar_stage: string}[];
  activeStep: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AilmentsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getWellnessMembershipCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start   

    this.state = {
      btnList: btnListInitial,
      editableIndex: null,
      value: "",
      selectedIndex: null,
      activeStep: "Stage A",
      progress_bars: [
        { progress_bar_text: "Stage A", progress_bar_stage: '1' },
        { progress_bar_text: "Stage B", progress_bar_stage: '2' }, 
        { progress_bar_text: "Stage C", progress_bar_stage: '3' }
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  } 

  // Customizable Area Start

  handleClick = (index: number) => {
    this.setState((prevState) => {
      const newSelectedIndex = prevState.selectedIndex === index ? null : index;      
      return {
        selectedIndex: newSelectedIndex,
        editableIndex: prevState.btnList[index] === "Other(specify)" ? index : null,
        value: prevState.btnList[index] === "Other(specify)" ? "" : prevState.value
      };
    });
  };
  

  handleBlur() {
    this.setState({ editableIndex: null });
  }

  handleInput(event: any) {
    let { value } = event.target
    this.setState({ value: value });
  }

  handleKeyPress(event: any) {
    if (event.key === "Enter" && this.state.editableIndex !== null) {
      const newbtnList = [...this.state.btnList];
      newbtnList[this.state.editableIndex] = this.state.value;
      this.setState({
        btnList: newbtnList,
        editableIndex: null,
        value: "",
        selectedIndex: null,
      });
    }
  }
  // Customizable Area End
}
