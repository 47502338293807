// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  main_title: string;
  planDetailsData: any;
  message: string;
  loading: boolean;
}

interface SS {
  id: string;
}

export default class WellnessMemebershipDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMembershipDetailsPlanCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      main_title: "",
      planDetailsData: [],
      message: "",
      loading: false 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async receive(from: string, message: Message) {
    this.handleWellnessMembershipPlanDetails(from,message);
  }

  async componentDidMount(){
    this.getMembershipPlanPage()
  }

  getMembershipPlanPage = () => { 
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMembershipDetailsPlanCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getMembershipPlansDetailsApiEndpoint}/${unique_slug}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleWellnessMembershipPlanDetails = async (from: string, message: Message) => {
    if (
      this.getMembershipDetailsPlanCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({ planDetailsData: responseJson.data, message: "", loading: false }); 
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found"});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found"});
        }
      } else {
        this.setState({ loading: false });      }
    }
  };

      handleNavigation = (pathname: string) => {
        const paramsDatas = pathname.split('/');
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), paramsDatas[0]);
        message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
        paramsDatas[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsDatas[1]);
        this.send(message);
      }
      
}
// Customizable Area End