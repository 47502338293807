import React from "react";
import { CircularProgress } from "@mui/material";

interface myProps {
  loading: boolean;
}

export default function ImageLoader(props: myProps) {
  return props.loading ? (
    <div style={webStyles.root} >
      <CircularProgress size={40} style={{color: "#E46760"}} />
    </div>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    alignContent: "center",
    width: "100%"
  },
};