export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const checkedIcon = require("../assets/checkbox_checked.svg").default;
export const checkMark = require("../assets/checkMark.svg").default;
export const uncheckIcon = require("../assets/checkbox_uncheck.svg").default;
export const emergency = require("../assets/emergencyIcon.svg").default;
export const call = require("../../cfcustomonboardingflowcreator1/assets/call.svg").default;
export const HeaderLogoImg = require("../../cfcustomonboardingflowcreator1/assets/header.png").default;
export const dropdownIcon = require("../assets/dropdown.svg").default;
export const skip = require("../assets/skip.svg").default;
export const hearing = require("../assets/hearing.jpeg").default;
export const psychology = require("../assets/psychology_alt.jpeg").default;
export const tactic = require("../assets/icon_tactic.jpeg").default;
export const sync_problem = require("../assets/icon_sync_problem.jpeg").default;
export const person_search = require("../assets/icon_person_search.jpeg").default;
export const location_home = require("../assets/icon_location_home.jpeg").default;
export const icon_indeterminate_question_box = require("../assets/icon_indeterminate_question_box.jpeg").default;
export const extension_off = require("../assets/icon_extension_off.jpeg").default;
export const explore = require("../assets/icon_explore.jpeg").default;
export const provider = require("../assets/iconProvider.jpeg").default;
export const shield = require("../assets/iconShield.jpeg").default;


