import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props{
  navigation: any;
  id: string; 
}

export interface S{
  listData: {progress_bar_text: string, progress_bar_stage: string}[]
  activeStep: string  
}

interface SS {
    id: any;
  }

export default class FlowDecisionController extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {    
      listData: [
        { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2' },
        { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
      ],
      activeStep: 'About You'      
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 
}