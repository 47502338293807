// Customizable Area Start
import React from 'react';
import {
    Box, styled
} from "@mui/material";
import LastQuestionController, { Props } from "./LastQuestionController.web";
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import { checkedIcon, skip, uncheckIcon } from './assets';
import { ResolveCondition, handleNavigation } from '../../utilities/src/CustomHelpers';

class LastQuestion extends LastQuestionController {
    constructor(props: Props) {
        super(props);

    }
    render() {
        return (
            <StyledWrapperLastQuestion>
                <>
                    <section className="leftBorder">
                        <Box className="container">
                            <Box className="headerStepper" id="LastQuestion">
                                <StepperData 
                                    key={`stepper${this.state.activeStep}`}
                                    listData={this.state.listData}
                                    activeStep={this.state.activeStep}
                                />
                            </Box>
                        </Box>
                        <Box className="title">Last Question!</Box>
                        <Box className="subTitle">Have you tried any of the following in the past to feel better?</Box>
                        <Box className="contentContainer">
                            <Box className='choiceHeading'>Choose from the following options</Box>
                            <Box className="subHead">(select all that apply)</Box>
                        </Box>
                        <Box className="boxOptions">
                            {this.state.optionList.map((item)=>(
                                <Box className = "option">
                                    <img src={this.state.selectedData.includes(item.id) ? checkedIcon : uncheckIcon} alt="icon" className ="img" 
                                    data-test-id = {item.id}
                                    onClick={()=>{
                                        this.handleTicks(item.id,item.label)
                                    }}/> {item.label}
                                </Box>
                                
                            ))}
                             {this.state.showOtherInput && (
                            <input 
                                type="text" 
                                value={this.state.otherValue}
                                onChange={this.handleOtherInputChange}
                                onKeyPress={this.handleOtherInputKeyPress}
                                placeholder="Enter your option"
                                data-test-id="changeField"
                                className='inputField'
                            />
                        )}
                        </Box>
                        <Box className="footer-container" id="LastQuestion">
                            <div className="box-wrapper" style={{ display: "flex", "justifyContent": "left" }}>
                                <div
                                    className="greyColor"
                                    data-test-id="btnback"
                                    onClick={() => {
                                        handleNavigation("ThanksForSharing", this.props);
                                    }}
                                >
                                    <p>Back</p>
                                </div>
                            </div>

                            <div className="box-wrapper" id="LastQuestion" style={{ display: "flex", "justifyContent": "center" }}>
                                {" "}
                                <button
                                    className={`button-continue ${ResolveCondition(this.state.selectedData.length > 0, 'active', 'inactive')}`}
                                    data-test-id="continue"
                                    disabled={
                                        (                                          
                                          this.state.selectedData.length <= 0
                                        )
                                      }                                    
                                >
                                    Continue
                                </button>
                            </div>

                            <div className="box-wrapper" id="LastQuestion" style={{ display: "flex", "justifyContent": "right" }}>
                                <div className="greyColor">
                                    <p
                                        className="skipIcon"
                                        data-test-id="btnSkip"
                                        onClick={() => {
                                            handleNavigation("SupportDesign", this.props);
                                        }}
                                    >
                                        Skip this Step &nbsp;
                                        <span>
                                            <img src={skip} alt="skip" />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </Box>
                        <FooterData />
                    </section>
                </>
            </StyledWrapperLastQuestion>
        )
    }
}

const StyledWrapperLastQuestion = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },  
    "& .inputField":{
        width : "100%",
        height : "4%",
        border : "1px solid #E6C79C",
        borderRadius : "24px",
        outline : "none",
        "&:focus": {
            border: "1px solid #E6C79C",             
        }
    },
    "& .img":{
        marginRight : "10px"
    },
    "& .option":{
        marginBottom : "6%",
        display : "flex"
    },
    "& .boxOptions":{
        height : "auto",
        width : "100%",        
        maxWidth : "15%",
        margin : "auto",
        marginBottom : "4%",
    },
    "& .subHead": {
        fontFamily: "Poppins",
        textAlign: "center",
        fontSize: "17px",
        fontWeight: 400,
    },
    "& .contentContainer": {
        height: "100%",
        width: "100%",
        maxHeight: "8%",
        maxWidth: "50%",
        margin: "auto",
        marginTop: "32px",
        marginBottom: "2%"
    },
    "& .choiceHeading": {
        fontWeight: 700,
        fontFamily: "Poppins",
        margin: "auto",
        fontSize: "20px",
        textAlign: "center"
    },
    "& .subTitle": {
        width: "100%",
        maxWidth: "40%",
        maxHeight: "5%",
        margin: "auto",
        textAlign: "center",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "24px",
        color: "#2C2524"
    },
    "& .title": {
        fontFamily: "Raleway",
        height: "100%",
        width: "100%",
        maxWidth: "55%",
        maxHeight: "10%",
        margin: "auto",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "41px",
        fontWeight: 600,
        marginTop: "3%",
        marginBottom: "2%"
    },
    "& .footer-container": {
        justifyContent: "center",
        display: "flex",
        marginTop: "100px",
        margin: "auto",
        maxWidth: "60%",
        marginBottom: "80px",
    },
    "& .leftBorder": {
        borderLeft: "32px solid #1A4A42",
        height: "auto"
    },
    "& .box-wrapper": {
        maxWidth: "33.33%",
        width: "100%",
    },
    "& .button-continue": {
        width: "100%",
        maxWidth: "392px",
        height: "56px",
        background: "#E46760",
        borderRadius: "40px",
        cursor: "pointer",
        fontWeight: "700",
        fontFamily: "Raleway",
        fontSize: "20px",
        color: "#FFFFFF",
        border: "none",
    },
    "& .button-continue.active": {
        color: "#FFFFFF",
        background: "#E46760",
        cursor: "pointer",
        fontSize: "20px",
        fontWeight: "700",
        fontFamily: "Raleway",
        border: "none",
    },
    "& .button-continue.inactive": {
        cursor: "not-allowed",
        color: "#FFFFFF",
        fontFamily: "Raleway",
        background: "#D6D3D1",
        disableCursor: true,
        fontSize: "20px",
        fontWeight: "700",
        border: "none",
    },
    "& .skipIcon": {
        display: "flex",
        alignItems: "center",
        fontWeight: "700",
        justifyContent: "center",
        fontSize: "20px",
        fontFamily: "Raleway",
    },
    "& .greyColor": {
        fontFamily: "Raleway",
        color: "#807C7C",
        fontSize: "20px",
        fontWeight: "700",
        cursor: "pointer",
    },
    "& .headerStepper": {
        "display": "flex",
        "paddingTop": "32px",
        "justifyContent": "center",
    },
})


export default LastQuestion;
// Customizable Area End