// Customizable Area Start
import React from 'react';
import {
    Box,
    styled,
    TextField,
    Divider
} from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import {HeaderLogoImg, emergency, call} from '../assets';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .matches(/^[A-Za-z\s]*$/, "First Name can only contain letters and spaces"),
  lastName: Yup.string()
    .required("Last Name is required")
    .matches(/^[A-Za-z\s]*$/, "Last Name can only contain letters and spaces"),
  phone_number: Yup.string()
  .test(
    "phone-or-landline",
    "Mobile Number is required unless you add a landline",
    function (value) {
      const { landline_number } = this.parent;
      return landline_number || value; 
    }
  )
  .when('landline_number', {
    is: false, 
    then: Yup.string().required("Mobile Number is required"),
  }),
landline_number: Yup.string().notRequired(), 
 });

import AuthorizedRepresentativeUpdateController,{Props} from "./AuthorizedRepresentativeUpdateController"
import StepperData from "../../../../components/src/StepperData.web";
import { setStorageData } from '../../../../framework/src/Utilities';
import { ConditionalRenderer, ResolveCondition ,handleNavigation} from '../../../../blocks/utilities/src/CustomHelpers';

const commonStyleData= require('./CommonStyles.js');
const {  PhoneInputWrapper } = commonStyleData.CommonStyles;


class AuthorizedRepresentativeUpdate extends AuthorizedRepresentativeUpdateController {
    constructor(props: Props) {
        super(props);
    }
    renderError = (touched: boolean, fieldStats: string) => {
      return ConditionalRenderer(Boolean(touched && fieldStats), <div className="error">{fieldStats}</div>, '')
    }
    render() {
      
      const paramId = this.props.navigation.getParam("navigationBarTitleText"); 

        const {allResponseJsondata} = this.state;
        return (
            <StyledWrapperForWellness>
               <Box className="headerLogo">
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Divider />
          <Box className= "headerStepper">           
             <StepperData 
              key={`stepper${allResponseJsondata.stage_type}`}
              listData={allResponseJsondata.progress_bars_attributes}
              activeStep={""}
            />
          </Box>

          <Formik
            initialValues={{
              landline_number : "",
              phone_checkbox: false,
              firstName:"",
              lastName:"",
              phone_number: "",
              
            }}
            
            validationSchema={validationSchema}
            data-test-id="formikWrapper"
            onSubmit={(values, { setSubmitting }) => {
              setStorageData("Param Value", paramId);
              handleNavigation("PatientDetails", this.props);
              setSubmitting(false);
              setStorageData("values", JSON.stringify(values));
              
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => {
            
            return(
              <Form onSubmit={handleSubmit}>
                <Box className="main-section1">
                  <h3 className="question1">
                    {allResponseJsondata?.form_header}
                  </h3>
                  <p className="informations">
                    {allResponseJsondata.form_description}
                  </p>

                  <Box className="mainSection">
                    {allResponseJsondata.first_name && (
                      <Box className="first-row">
                        <Box className="first-name">
                          <div className="heading">First Name
                          {ConditionalRenderer(allResponseJsondata.first_name,<span className='required-text'>*</span>,'')}
                          </div>
                          <Field
                             
                            onChange={(e:any) => {
                              const value = e.target.value;
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                handleChange(e);
                                this.setState({ firstName: value });
                              }
                            }}
                            name="firstName"
                            as={TextField}
                            value={values.firstName}
                            className="inputFields"
                            data-test-id="firstName"
                            placeholder="Enter Your Name"
                          />
                        
                          {ConditionalRenderer(Boolean(errors.firstName),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.firstName),
                                <div className="error">
                                  {errors.firstName}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                        </Box>

                        <Box className="last-name">
                          <div className="heading">Last Name
                          {ConditionalRenderer(allResponseJsondata.first_name,<span className='required-text'>*</span>,'')}
                          </div>
                          <Field
                            className="inputFields"
                            onChange={(e:any) => {
                              const value = e.target.value;
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                handleChange(e);
                                this.setState({ lastName: value });
                              }
                            }}
                            name="lastName"
                            as={TextField}
                            value={values.lastName}
                            data-test-id="lastName"
                            placeholder="Enter Your Last Name"
                          />
                     {ConditionalRenderer(Boolean(errors.lastName),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.lastName),
                                <div className="error">
                                  {errors.lastName}
                                </div>, ''
                              )}
                            </div>, ''
                          )} 
                        </Box>
                      </Box>
                   )}
                    { 
                   allResponseJsondata.mobile_number && 
                      <Box className="div-container">
                        <div className="heading"> Mobile Number
                        {ConditionalRenderer(allResponseJsondata.mobile_number,<span className='required-text'>*</span>,'')}
                        </div>
                        <Box className="inputField">
                          <PhoneInputWrapper>
                            <PhoneInput
                              country={'us'}
                              placeholder='Enter your phone number'
                              value={values.phone_number}                              
                              data-testid="mobile_number"
                               onChange={(phone) => {
                                setFieldValue('phone_number', phone);
                                this.setState({ therapyPhoneNumber: phone,consentChecked:true }); 
                              }}
                            />
                          </PhoneInputWrapper>
                          {ConditionalRenderer(Boolean(errors.phone_number),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.phone_number),
                                <div className="error">
                                  {errors.phone_number}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                        </Box>
                        <Box className="checkboxContainer1">
                <label style={webstyle.checkboxLabel}>
                    <input
                     type="checkbox"
                     checked={this.state.landline_number}
                     onChange={this.handleLandlineToggles}
                     style={webstyle.checkboxInput}
                     data-test-id = "landline" 
                    />
                <span style={webstyle.checkboxCustom(this.state.landline_number)}>
                  {<span style={webstyle.checkmark}></span>}
                </span>
                          <span className="checkboxText">I don't have a mobile - add a landline</span>
                          </label>
                        </Box>
                              {
                                ConditionalRenderer(this.state.landline_number, <Box className="inputField">
                                <PhoneInputWrapper>
                                  <PhoneInput
                                    country={'us'}
                                    placeholder='Enter your phone number'
                                    value={values.landline_number}                              
                                    data-test-id="landline_number"
                                    onChange={(landline_no: any)=> {
                                      setFieldValue('landline_number', landline_no);
                                      this.setState({ therapyLadlineNo: landline_no ,consentChecked:true});
                                    }}
                                  />
                                </PhoneInputWrapper>                             
                              </Box>,'')                                 
                              }                       
                      </Box>
                   } 
                  <Box>
                    <button
                    onClick={()=>this.putTherapySignupApi(paramId)}
                    disabled={!this.state.selectedValue}
                    data-test-id="submitBtn"
                    type="submit"
                      className={`button-continue1 ${ResolveCondition(
                        this.state.consentChecked,
                        "active",
                        "inactive"
                      )}`}
                      
                    >
                      {allResponseJsondata.button_text}
                    </button>
                  </Box>

                  <Box className="loginClass1">
                    <u className="content">Already have an account? Log in</u>
                  </Box>
                  
                </Box>
                                    
                </Box>
              </Form>
              )
            }}
            
          </Formik>
           
          {
              ConditionalRenderer(allResponseJsondata.help_footer, <Box className="footer1" id="footer1">
              <p className="emergencyIcon1">
                <span>
                  <img  alt="emergencyNumber" src={emergency} className="yellowFilter" />
                </span>
                &nbsp; If this is an emergency &nbsp; <u>please call 911</u>
              </p>
              <div className="footerRight1" id="rightFooter">
                <span className="label-700-20" id="label-need-help1" >
                  Need Help?
                </span>
                <button  data-test-id="callBTN" className="callButton1">
                  Schedule a call?
                  <span className="callImg1">
                    <img alt="call"  src={call} />
                  </span>
                </button>
              </div>
            </Box>,<Box className="footer1"></Box>)
               
            }
       
            </StyledWrapperForWellness>
        );
    }
}

const StyledWrapperForWellness = styled("div")({
    borderLeft: "32px solid #1A4A42;",

    margin: "0 auto",
    // height: "100%",
    width: "100%",
  
  
    "& .emailField .MuiOutlinedInput-root": {
      width: "100%",
      height: "42px",
      maxWidth: "600px",
      borderRadius: "24px",
      borderColor: "#F87171",
      "& fieldset": {
        borderColor: "#F87171",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F87171",
      },
      "&:hover fieldset": {
        borderColor: "#F87171",
      },
      "& .MuiInputBase-input": {
        height: "100%",
      }
    },
    "& *": {
      boxSizing: "border-box",
    },
    "& .required-text": {
      textFillColor: "#DC2626",
      WebkitTextFillColor: "#DC2626",
      margin:"3px"
  },
  "& .inputFieldLast .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .inputFieldLast .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxHeight: "44px",
    maxWidth: "100%",
    "border": "1px solid #E6C79C",
    width: "100%",
  },
  "& .inputFieldLast": {
    maxWidth: "100%",
    maxHeight: "72px",
    borderRadius: "24px",
    width: "100%",
    marginBottom:"24px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display:"flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%", 
      display:"flex"
    }
  },
    "& .MuiCheckbox-root.Mui-checked svg": {
      color: "#1A4A42",
      borderRadius: "4px",
    },
    "& .headerStepper": {
        "display": "flex",
        "paddingTop": "32px",
        "justifyContent": "center",
      },
    "& .MuiCheckbox-root svg": {
      borderRadius: "10px",
    },
    "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "16px",
    },
    "& .header1 .custom-step-connector1 .MuiStepConnector-root": {
      display: "none",
    },
    "& .header1 .custom-step-connector1 .MuiStepper-horizontal": {
      justifyContent: "space-between",
      position: "relative",
    },
    "& .header1 .custom-step-connector1 .MuiSvgIcon-root": {
      borderRadius: "10px",
    },
    "& .header1 .custom-step-connector1 .MuiStepLabel-iconContainer::after":
    {
      height: "3px",
      backgroundColor: "#A8A29E",
      content: `""`,
      position: "absolute",
      width: "239px",
      left: "44px",
      top: "10px",
    },
    "& .Select": {
      fontWeight: 400,
      fontFamily: "Poppins",
      color: "#78716C",
      fontSize:"16px",
      lineHeight: "24px",
    },
    "& .error-container": {
      // minHeight: "20px",
    },
    "& .error": {
      lineHeight:"19.5px",
      color: "#DC2626",
      marginTop: "2px",
      textAlign: "initial",
      fontWeight:400,
      fontFamily:"Poppins",
      fontSize:"13px",
    },
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      width: "100%",
    },
    "& .inputFields": {
      width: "288px",
      height: "44px",
      borderRadius: "24px",
      "boxSizing": "border-box",
    },
  
    "& .inputFields .MuiOutlinedInput-root": {
      height: "100%",
      width: "100%",
      maxWidth: "288px",
      maxHeight: "44px",
      "& fieldset": {
        borderColor: "#E6C79C",
        display: "flex",
        alignItems: "center",
        border: "2px solid #E6C79C",
        borderRadius: "24px",
        width: "100%"
      },
      "&:hover fieldset": {
        borderColor: "#E6C79C",
        display: "flex",
        alignItems: "center"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E6C79C",
        display: "flex",
        alignItems: "center"
      },
    },
   "& .content": {
      fontWeight: 700,
      color: "#E46760",
      fontSize: "20px",
      fontFamily: "Raleway",
      lineHeight:"23.48px"
    },
    "& .div-container": {
      marginBottom: "32px",
    },
    "& .div-container1":{
        marginBottom: "32px",

    },
    "& .loginClass1":{
        marginTop:"14px"
    },
    "& .checkBoxGroup1": {
      maxWidth: "291px",
      display: "flex",
      alignItems: "center",
      maxHeight: "22px",
      padding: "10px",
      borderRadius: "10px",
      marginLeft:"-10px",
      marginBottom:"24px"
    },
    "& .checkBoxGroup1.active": {
      backgroundColor: "#000000",
    },
    "& .checkboxContainerLast": {
      display: "flex",
      marginBottom: "16px",
      alignItems: "center",
    },
    "& .upperCheckbox":{
       borderRadius:"20px"
    },
    "& .checkboxContainer1": {
      marginTop:"8px",
      marginBottom:"6px",
      display: "flex",
      alignItems: "center",
    },
    '& .checkboxText' : {
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .heading": {
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "justify",
      marginBottom:"4px"
    }, 
    "& .Preferredheading" : {
      fontWeight: 700,
      fontFamily: "Poppins",
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "justify",
      marginBottom:"6px"
    },
    "& .mainSection": {
      maxWidth: "600px",
      maxHeight: "325px",
      width: "100%",
      height: "100%",
      margin: "auto",
      marginTop: "32px",
      zIndex:9999,
      position: "relative"
      // border : "2px solid black"
    },
    "& .iframe": {
      display: "none" 
    },
    
    "& .first-row": {
      display: "flex",
      justifyContent:"space-between",
      width: "100%",
      alignItems: "baseLine",
      marginBottom:"24px"
    },
    "& .first-name": {
      textAlign: "left",
      maxWidth: "calc(50% - 20px)",
      width: "100%",},
      "& .last-name": {
        textAlign: "left",
        maxWidth: "calc(50% - 20px)",
        margin: "10px",
        width: "100%",},
    "& .headerLogo": {
      height: "auto",
      width: "100%",
      minHeight: "78px",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
    },
    "& .logoImg": {
      marginLeft: "144px",
    },
    "& .footerRight1": {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    "& .label-700-20": {
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "Poppins",
    },
    "& .custom-step-connector1": {
      width: "100%",
      maxWidth: "600px",
    },
    "& .main-section1": {
      marginTop : "51.22px",
      textAlign: "center",
   
    },
    "& .header1 .custom-step-connector1 .MuiStepLabel-labelContainer": {
      alignItems: "center",
      flexDirection: "column",
    },
    "& .about-section": {
      borderLeft: "32px solid #1A4A42;",
      // "@media(max-width:1500px)": {
      //   color:"red"
      // },
    },
    "& .informations": {
      maxWidth: "600px",
      margin: "auto",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      marginTop: "32px",
    },
    "& .question1": {
      lineHeight: "53.3px",
      letterSpacing: "0.5px",
      maxWidth: "700px",
      margin: "auto",
      fontWeight: 600,
      fontFamily: "Raleway",
      fontSize: "41px",
    },
    "& .callButton1": {
      borderRadius: "16px",
      background: "#ffffff",
      fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "20px",
      border: "1px solid #E46760",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      lineHeight: "23.48px",
      padding: "5px 10px",
      color: "#E46760",
    },
    "& .footer1": {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "210.10px",
      padding: "0px 141px",
      alignItems: "center",
      borderTop: "1px solid #E7E5E4",
      minHeight: "96px",
      "@media(max-width:750px)": {
        padding: "0px 0px",
      },
    },
    "& .button-continue1": {
      border: "none",
      maxWidth: "600px",
      maxHeight: "56px",
      height: "100%",
      width: "100%",
      borderRadius: "24px",
      cursor: "pointer",
      background: "#E46760",
      marginTop: "32px",
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight:"23.48px"
    },
    "& .emergencyIcon1": {
      display: "flex",
      fontFamily: "Poppins",
      fontWeight: "400",
      fontSize: "20px",
      alignItems: "center",
    },
    "& .button-continue1.active": {
      border: "none",
      background: "#E46760",
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      marginTop: "32px",
      marginBottom:"28px",
      cursor: "pointer",
      color: "#FFFFFF",
      height:"56px",
    },
  
    "& .button-continue1.inactive": {
      border: "none",
      background: "#E7E5E4",
      cursor: "not-allowed",
      fontFamily: "Raleway",
      fontWeight: "700",
      fontSize: "20px",
      marginTop: "32px",
      marginBottom:"28px",
      height:"56px",
      color: "#A8A29E",
      disableCursor: true,
     

    },
    "& u": {
      color: "#D97706",
    },
  
    "@media screen and (max-width:1200px)": {
      "& .logoImg": {
        marginLeft: "50px",
      },
    },
    "@media screen and (max-width:767px)": {
      "& .logoImg": {
        marginLeft: "30px",
      },
    },
    "& .header1": {
      height: "auto",display: "flex",
      width: "100%",
      justifyContent: "center",
      minHeight: "140px",
    },
  });


const webstyle = {
    container: {
      fontFamily: "Arial, sans-serif",
    },
    checkboxLabel: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontSize: "14px",
    },
    checkboxLabelEmail:{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontSize: "14px",
      margin:"0px 24px"
    },
    checkboxInput: {
      display: "none",
    },
    checkboxCustom: (isChecked: any): React.CSSProperties => ({
      width: "20px",
      height: "20px",
      border: "1px solid #78716C",
      borderRadius: "6px",
      marginRight: "10px",
      display: "inline-block",
      position: "relative",
      backgroundColor: isChecked ? "#1A4A42" : "transparent",
    }),
    checkmark: {
      position: "absolute" as "absolute",
      top: "1px",
      left: "5px",
      width: "8px",
      height: "12px",
      border: "solid white",
      borderWidth: "0 2px 2px 0",
      transform: "rotate(45deg)",
      opacity: 1,
    } as React.CSSProperties,
    linkStyle: {
      color: "#00796b",
      textDecoration: "underline",
    },
  };

export default AuthorizedRepresentativeUpdate;
// Customizable Area End