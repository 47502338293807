// Customizable Area Start
import React from 'react';
import {
    styled,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    stepConnectorClasses
} from "@mui/material";
import { StepIconProps } from '@mui/material/StepIcon'
import { BlockComponent } from "../../framework/src/BlockComponent";
import { checkMark } from "../../blocks/email-account-registration/src/assets";

interface Props {
    listData: {progress_bar_text: string, progress_bar_stage: string}[],
    activeStep: string
}

interface S {
    activeStep: number
}

interface SS {
    id: string;
}

interface ColorlibStepIconProps extends StepIconProps {
    stages: {progress_bar_text: string, progress_bar_stage: string}[];
}

class StepperData extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeStep: 0
        }
    }

    async componentDidMount(): Promise<void> {
        if (this.props.activeStep) {
            this.setState({
                activeStep: this.props.listData.findIndex(item => item.progress_bar_text === this.props.activeStep)
            });
        }
    }

    render() {
        return (
            <StyledWrapper>
                <Stepper
                    alternativeLabel
                    activeStep={this.state.activeStep}
                    className="custom-step-connector"
                    connector={<CustomStepConnector />}
                    id="authorisedRepresentative"
                >
                    {this.props.listData.map((label, index) => (
                        <Step key={label.progress_bar_text} style={{padding: 0}} active={this.state.activeStep >= index ? true: false}>
                            <StepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} stages={this.props.listData} />}>
                                {label.progress_bar_text}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    maxWidth: "600px",
    "& .active-text": {
        background: "linear-gradient(0deg, #2C2524, #2C2524),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    },
    "& .default-text": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left"
    },
    "& .custom-step-connector": {
        maxWidth: "600px",
        width: "100%",
        marginTop: "32px"
    },
    "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
        marginTop: 10,
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "center",
        color: "#57534E"
    },
    "& .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active": {
        background: "linear-gradient(0deg, #2C2524, #2C2524),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent"
    }
});

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 14.75,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4.21,
        border: 0,
        padding: 0,
        backgroundColor:
          theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#E7E5E4',
        borderRadius: 1,
    },
    [`&.${stepConnectorClasses.active}`]: {
        '& .MuiStepConnector-line': {
            backgroundColor: "#E7E5E4",
        },
      },
      [`&.${stepConnectorClasses.completed}`]: {
        '& .MuiStepConnector-line': {
            backgroundColor: "#E7E5E4",
        },
      }
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#a8a29e',
    zIndex: 1,
    color: '#fff',
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left",
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: '#E46760'
    }),
    ...(ownerState.completed && {
      backgroundColor: '#1A4A42'
    }),
}));

function ColorlibStepIcon(props: ColorlibStepIconProps) {
    const { active, completed, className, stages } = props;
    const icons: { [index: string]: React.ReactElement } = {};

    stages.forEach((stage, index) => {
        icons[(index + 1).toString()] = completed ? <img src={checkMark} alt="completed steps"/>: <span>{stage.progress_bar_stage}</span>;
    });

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
}

export default StepperData;
// Customizable Area End