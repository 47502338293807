// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { hearing,extension_off,icon_indeterminate_question_box,psychology,location_home,person_search,sync_problem,tactic,explore } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string; 
}

interface BoxData{
  id : number,
  img : string,
  title : string,
  description : string,
  btn_text : string
}

interface S {
  box : BoxData[];
  selectedData : number[];
  listData: {progress_bar_text: string, progress_bar_stage: string}[],
  activeStep: string
}

interface SS {
  id: any;
}

export default class SupportDesignController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      box : [
        {
          id : 0,
          img : hearing,
          title : "A Listening Ear",
          description : "I need someone to listen and provide emotional support as I share my thoughts and feelings.",
          btn_text : "Select Care"
          
        },
        {
          id : 1,
          img : psychology,
          title : "Reflect and Remember",
          description : "I seek support in reflecting on my past experiences and improving my memory while navigating present challenges.",
          btn_text : "Select Care"          
        },
        {
          id : 2,
          img : explore,
          title : "Guidance and Advice",
          description : "I'm looking for guidance and practical advice to help me navigate life's challenges.",
          btn_text : "Select Care"          
        }, 
        {
          id : 3,
          img : tactic,
          title : "Coping Strategies",
          description : "I want to learn effective coping strategies to manage stress, anxiety, or difficult emotions.",
          btn_text : "Select Care"          
        }, 
        {
          id : 4,
          img : person_search,
          title : "Self-Exploration",
          description : "I'm interested in self-discovery and gaining a deeper understanding of myself.",
          btn_text : "Select Care"          
        }, 
        {   
          id : 5,
          img : sync_problem,
          title : "Problem Solving",
          description : "I need help identifying and solving specific problems or issues in my life.",
          btn_text : "Select Care"
          
        }, 
        {
          id : 6,
          img : location_home,
          title : "Building Resilience",
          description : "I want to build resilience and develop the skills to thrive in challenging situations.",
          btn_text : "Select Care"
          
        },
        {
          id : 7,
          img : extension_off,
          title : "Cognitive Decline",
          description : "I am experiencing difficulties with thinking, reasoning, or remembering that are affecting my daily life and activities.",
          btn_text : "Select Care"
          
        },    
        {
          id : 8,
          img : icon_indeterminate_question_box,
          title : "Memory Loss",
          description : "I am facing challenges with my memory, such as forgetting important information or events, and it is impacting my daily functioning.",
          btn_text : "Select Care"          
        },            
      ],
      selectedData : [],
      listData: [
        {progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2'}, 
        { progress_bar_text: 'Choose Provider', progress_bar_stage: '3' }
      ],
      activeStep: 'About You'
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleClick=(id :number)=>{
     this.setState((prevState) => ({
    selectedData: prevState.selectedData.includes(id)
      ? prevState.selectedData.filter((item) => item !== id)
      : [...prevState.selectedData, id]
  }));
  }
}
// Customizable Area End