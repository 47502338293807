import React from "react";

import { Typography } from "@builder/component-library";

// Customizable Area Start
import {
  Box,
  styled,
  Paper,
  Stepper,
  StepLabel,
  StepConnector,
  Typography as CustomTypography
} from "@mui/material";
import {
  SentimentVerySatisfied,
  SentimentSatisfied,
  SentimentNeutral,
  SentimentDissatisfied,
  SentimentVeryDissatisfied
} from '@mui/icons-material';
import {
  call,
  emergency,
  poor,
  skip,
  veryPoor,
  veryWell,
  well,
  neutral,
} from "./assets";
import {
  ResolveCondition,
  handleNavigation,
} from "../../../blocks/utilities/src/CustomHelpers";
import StepperData from '../../../components/src/StepperData.web';
import { ConditionalRenderer } from "./../../../blocks/utilities/src/CustomHelpers";
const getIconComponent = (iconName: unknown, className: string | undefined) => {
  switch (iconName) {
    case 'very_well':
      return <SentimentVerySatisfied className={className} />;
    case 'well':
      return <SentimentSatisfied className={className} />;
    case 'neutral':
      return <SentimentNeutral className={className} />;
    case 'poor':
      return <SentimentDissatisfied className={className} />;
    case 'very_poor':
      return <SentimentVeryDissatisfied className={className} />;
    default:
      return null;
  }
};

// Customizable Area End

import Cfcustomonboardingflowcreator1Controller, {
  Props,
  configJSON,
} from "./Cfcustomonboardingflowcreator1Controller";

export default class Cfcustomonboardingflowcreator1 extends Cfcustomonboardingflowcreator1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleButtonElm = (
    button_text: string,
    urlData: string,
    external_link: boolean,
    buttonType: string = ""
  ) => {
    const isDisabled = this.state.selectedValue?.length === 0;
    const buttonClass = buttonType ? "buttonSecond" : "button";
  
    if (external_link) {
      if (isDisabled) {
        return <span className="disabled-button">{button_text}</span>;
      } else {
        return (
          <a
            className={buttonClass}
            data-test-id={button_text}
            href={urlData}
            rel="noopener noreferrer"
          >
            {button_text}
          </a>
        );
      }
    } else {
      return (
        <button
          disabled={true}
          className={buttonClass}
          data-test-id={button_text}
          onClick={() => this.handleNavigation(urlData)}
        >
          {button_text}
        </button>
      );
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        <section className="about-section">
          <Box className="headerStepper">
            <StepperData 
              key={`stepper${this.state.activeStep}`}
              listData={this.state.listData}
              activeStep={this.state.activeStep}
            />
          </Box>

          <Box className="main-section">
           {
           ConditionalRenderer(Boolean(this.state.resStats.main_title),
                    <CustomTypography style={{
                      fontFamily: "Raleway",
                      fontWeight: 600,
                      fontSize: 41,
                      letterSpacing: 0.5,
                      textAlign: "center",
                      margin: "48px auto 0px"
                    }}>{this.state.resStats.main_title}</CustomTypography>, ''
                  )
                }
                {
                  ConditionalRenderer(Boolean(this.state.resStats.sub_title),
                    <CustomTypography className="information">{this.state.resStats.sub_title}</CustomTypography>, ''
                  )
                }
                {
                  ConditionalRenderer(Boolean(this.state.resStats.main_body), <CustomTypography className="information"
                  dangerouslySetInnerHTML={{__html: this.state?.resStats?.main_body}}                    
                  />, "")
                }

            <Box className="container-button">
              <Box>
                {[
                  {
                    label: "Very Well",
                    value: "very_well",
                    icon: 'very_well',
                    selectedIcon: 'very_well',
                  },
                  {
                    label: "Well",
                    value: "well",
                    icon: 'well',
                    selectedIcon: 'well',
                  },
                  {
                    label: "Neutral",
                    value: "neutral",
                    icon: 'neutral',
                    selectedIcon: 'neutral',
                  },
                  {
                    label: "Poor",
                    value: "poor",
                    icon: 'poor',
                    selectedIcon: 'poor',
                  },
                  {
                    label: "Very Poor",
                    value: "very_poor",
                    icon: 'very_poor',
                    selectedIcon: 'very_poor',
                  },
                ].map((option) => (
                  <CustomCheckbox
                    key={option.value}
                    data-test-id = {option.value}
                    selected={this.state.selectedValue.includes(option.value)}
                    onClick={() => this.handleClick(option.value)}
                  >
                    <Box>
                      {getIconComponent(
                      ResolveCondition(
                        this.state.selectedValue.includes(option.value),
                        option.selectedIcon,
                        option.icon
                      ),
                      'icons'
                    )}
                    </Box>
                    <CustomTypography className="option-label">
                      {option.label}
                    </CustomTypography>
                  </CustomCheckbox>
                ))}
              </Box>
            <Box className="footer-container">
              <div className="box-wrapper1">
                <div
                  className="greyColor"
                  data-test-id="btnTherapy"
                  onClick={() => {
                    this.props.navigation.goBack();
                  }}
                >
                  <p>Back</p>
                </div>
              </div>

              <div className="box-wrapper">
                {" "}
                {this.state.resStats.primary_links_attributes.map((link, index) => (
                <div key={`primary-${index}`} style={{ width: "100%" }}>
                  {ConditionalRenderer(
                    Boolean(link.primary_button_text),
                    this.handleButtonElm(link.primary_button_text, link.primary_link_url, link.external_link, ""),
                    ""
                  )}
                </div>
              ))}

              {this.state.resStats.secondary_links_attributes && this.state.resStats.secondary_links_attributes.map((link, index) => (
                <div key={`secondary-${index}`} style={{ width: "100%" }}>
                  {ConditionalRenderer(
                    Boolean(link.secondary_button_text),
                    this.handleButtonElm(link.secondary_button_text, link.secondary_link_url, link.external_link, "secondaryBtn"),
                    ""
                  )}
                </div>
))}
              </div>

              <div className="box-wrapper2">
                <div className="greyColor">
                  <p
                    className="skipIcon"
                    data-test-id="btnSkip"
                    onClick={() => {
                      handleNavigation("WellnessJourney", this.props);
                    }}
                  >
                    Skip this Step &nbsp;
                    <span>
                      <img src={skip} alt="skip" />
                    </span>
                  </p>
                </div>
              </div>
            </Box>
            </Box>
         </Box>

          {
              ConditionalRenderer(this.state.resStats.help_footer,
          <Box className="footer">
          <p className="emergencyIcon"><span><img src={emergency} alt="emergency" /></span>&nbsp; If this is an emergency &nbsp; <u>please call 911</u></p>
            <div className="footerRight" id="footer">
              <span className="label-700-20">Need Help?</span>
              <button className="callButton">
                Schedule a call?
                <span>
                  <img src={call} alt="call" />
                </span>
              </button>
            </div>
          </Box>,<Box className=""></Box>)
            }
        </section>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .footerRight": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  "& .label-700-20": {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  "& .main-section": {
    textAlign: "center",
  },
  "& .custom-step-connector": {
    width: "100%",
    maxWidth: "600px",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42",
  },
  "& .question": {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
  },
  "& .information": {
    maxWidth: "733px",
    margin: "32px auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
  },
  "& .buttonSecond": {
    width: "max-content",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    margin: "13px auto 0",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    color: "#E46760",
    border: "none",
    background: "none",
    cursor: "pointer",
  },
  "& .container-button": {
    display: "flex",
    flexDirection: "column",
    width: "700px",
    gap: "15px",
    alignItems: "center",
    marginTop: "32px",
    margin:"auto",
    padding:"24px"
  },
  "& .box-wrapper": {
    width: "100%",
  },
  "& .box-wrapper1": {
    marginRight: "368.5px",
    "@media screen and (max-width:1400px)": {
    marginRight: "268.5px",
    },
    "@media screen and (max-width:1200px)": {
      marginRight: "228.5px",
      },
        "@media screen and (max-width:950px)": {
          marginRight: "208.5px",
    },
  }, 
   "& .box-wrapper2": {
    marginLeft: "244.5px",
    "@media screen and (max-width:1400px)": {
      marginLeft: "144.5px",
      },
      "@media screen and (max-width:1200px)": {
        marginLeft: "104.5px",
        },
    "@media screen and (max-width:950px)": {
        marginLeft: "84.5px",
     },
  },
  "& .headerStepper": {
    "display": "flex",
    "justifyContent": "center",
  },
  "& .callButton": {
    background: "#ffffff",
    borderRadius: "16px",
    border: "2px solid #E46760",
    padding: "10px 16px 10px 16px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "1px"
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #E7E5E4",
    alignItems: "center",
    marginTop: "126px",
    minHeight: "96px",
    padding: "0px 141px",
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "black",
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    textWrap: "noWrap",
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .footer-container": {
    display: "flex",
    marginTop: "39px",
  },
  "& .button-continue": {
    height: "56px",
    width: "100%",
    maxWidth: "392px",
    borderRadius: "40px",
    background: "#E46760",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
    textDecoration: "none"
  },
  "& .button-continue.active": {
    background: "#E46760",
    color: "#FFFFFF",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .button-continue.inactive": {
    background: "#D6D3D1",
    color: "#FFFFFF",
    cursor: "not-allowed",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .button": {
    width: "392px",
    borderRadius: "24px",
    backgroundColor: "#E46760",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16px 20px 16px 20px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: "1px",
      "@media screen and (max-width:1200px)": {
        width: "300px",
        },
  },
  "& .disabled-button": {
    width: "392px",
    borderRadius: "24px",
    background: "#D6D3D1",
    color: "#FFFFFF",
    cursor: "not-allowed",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16px 20px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    "@media screen and (max-width:1400px)": {
      width: "400px",
      },
    "@media screen and (max-width:1200px)": {
      width: "360px",
      },
    "@media screen and (max-width:1100px)": {
      width: "300px",
    },
  },
  "& .headeingWellness": {
    fontFamily: "Raleway",
    color: "#1A4A42",
    fontWeight: 800,
    fontSize: "64px",
    lineHeight: "76.8px",
    letterSpacing: "0.5px",
    textAlign: "center",
    margin: "56px 0 16px",
  },
  "& .paragraphWellness": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#2C2524",
  },
  "& .list": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    display: "flex",
    justifyContent: "center",
    color: "#2C2524",
  },
  "& .image": {
    height: "auto",
    width: "100%",
    objectFit: "contain",
  },
  "& .content": {
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translate(-50% ,0)",
    width: "100%",
    maxWidth: "730px",
  },
  "@media screen and (max-width:1200px)": {
    "& .headeingWellness": {
      fontSize: "54px",
    },
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .headeingWellness": {
      fontSize: "30px",
      lineHeight: "normal",
    },
    "& .paragraphWellness": {
      margin: 0,
      fontSize: "18px",
    },
    "& .list": {
      fontSize: "15px",
      margin: 0,
      padding: 0,
    },
    "& .button": {
      maxWidth: "320px",
      marginBottom: "70px",
    },
    "& .header .MuiStepper-root.MuiStepper-horizontal": {
      gap: "6px",
      justifyContent: "start",
    },
    "& .header .custom-step-connector .MuiStepLabel-iconContainer.Mui-active::after, & .header .custom-step-connector .MuiStepLabel-iconContainer.Mui-active::before": {
      width: "62px",
      left: "24px",
      right: "24px",
    },
    "& .header .custom-step-connector .MuiStepLabel-iconContainer.Mui-active::before": {
      right: "24px",
    },
  },
  "@media screen and (max-width:500px)": {
    "& .footer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0px 20px",
      gap: "8px",
      minHeight: "unset",
      marginTop: "26px",
    },
    "& .footerRight": {
      display: "flex",
      justifyContent: "center",
    },
  },
});


const CustomCheckbox = styled(Paper)<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: "4px 16px 4px 12px",
  marginBottom: "12px",
  cursor: 'pointer',
  boxShadow: "none",
  gap: "8px",
  backgroundColor: ResolveCondition(selected, "#E46760", "#E7E5E4") as string,
  '&:hover': {
    backgroundColor: ResolveCondition(selected, "#E46760", "#E46760") as string,
  },
  borderRadius: "26px",
  width: "340px",
  height: "48px",
  "& .icons": {
    color: ResolveCondition(selected, 'white', '#44403C'),
    fontWeight: ResolveCondition(selected, '700', '400'),
  },
  "& .option-label": {
    fontFamily: "Poppins",
    fontSize: "20px",
    color: ResolveCondition(selected, 'white', '#44403C'),
    fontWeight: ResolveCondition(selected, '700', '400'),
  },
}));

const CustomStepper = styled(Stepper)({
  width: "100%",
  maxWidth: "600px",
  margin: "20px auto",
  '& .MuiStepLabel-labelContainer': {
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .MuiStepIcon-root': {
    marginBottom: '8px',
  },
});

const CustomStepConnector = styled(StepConnector)({
  '&.MuiStepConnector-active': {
    backgroundColor: '#000000',
  },
});

const CustomStepLabel = styled(StepLabel)({
  display: 'flex',
  flexDirection: 'column',
});

// Customizable Area End
