import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Moment } from "moment/moment";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment;
  end_time: Moment;
  id: string | number;
  token: string;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAppointmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addAppointmentApiCallId?: string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    const endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      token: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.navigateToAppointments();
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start

  addAppointment() {
    if (
      !this.state.available_date ||
      !this.state.start_time ||
      !this.state.end_time
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return;
    }

    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const body = {
      start_time: moment(this.state.start_time).format("hh:mm A"),
      end_time: moment(this.state.end_time).format("hh:mm A"),
      availability_date: moment(this.state.available_date).format("DD/MM/YY"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appointmentAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAppointmentAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToAppointments = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Appointments"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value }));
  }

  toMomentDate(value: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  // Customizable Area End
}
