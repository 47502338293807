import React from "react";

import { Input } from "@builder/component-library";

// Customizable Area Start
import {
  Box,
  styled,
  TextField,Divider
} from "@mui/material";
import { call, emergency, HeaderLogoImg } from "./assets";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { setStorageData } from "../../../framework/src/Utilities";
import { ConditionalRenderer, ResolveCondition, handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";
import StepperData from '../../../components/src/StepperData.web';
import PhoneInput from 'react-phone-input-2'
import TermsConditionsEdit from "../../termsconditions/src/TermsConditionsEdit.web";
// Customizable Area End

import WellnessJourneyController, { Props } from "./WellnessJourneyController";
export default class WellnessJourney extends WellnessJourneyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    phone_number: Yup.string().when([], {
      is: () => this.state.formStats?.phone_number_req === true,
      then: Yup.string().required("Required"),
      otherwise: Yup.string(),
    }),
    email: Yup.string()
      .email("Please enter a valid email")
      .when([], {
        is: () => this.state.formStats?.email_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),  
    contact_method: Yup.mixed().test(
      "at-least-one-checked",
      "Required",
      function () {
        const { phone_checkbox, email_checkbox, sms_checkbox } = this.parent;
        return phone_checkbox || email_checkbox || sms_checkbox;
      }
    ),
  });
  // Customizable Area End

  render() {
    const paramId = this.props.navigation.getParam("navigationBarTitleText");   
    return (
      // Customizable Area Start
      <StyledWrapperForWellness>
        <section className="about-section">
          <Box className="headerLogo">
            <img className="logoImg" alt="HeaderLogoImgs" src={HeaderLogoImg}  />
          </Box>
          <Divider />
          <Box className= "headerSteppers">           
             <StepperData 
              key={`stepper${this.state.formStats.stage_type}`}
              listData={this.state.formStats.progress_bars}
              activeStep={this.state.formStats.stage_type}
            />
          </Box>

          <Formik
            initialValues={{
              phone_number: "",
              landline_number : "",
              email: "",
              phone_checkbox: false,
              email_checkbox: false,
              sms_checkbox: false,
              contact_method : [] 
            }}
            data-test-id="formikWrapper"
            validationSchema={this.validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              setStorageData("values", JSON.stringify(values));
              setStorageData("Param Value", paramId);
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Box className="main-section">
                  <h2 className="questions">
                    {this.state.formStats.form_header}
                  </h2>
                  <p className="information">
                    {this.state.formStats.form_description}
                  </p>

                  <Box className="box">
                    {
                      this.state.formStats.phone_number &&
                      <Box className="div-container">
                        <div className="heading">{this.state.formStats.phone_number_label}
                        {ConditionalRenderer(this.state.formStats.phone_number_req,<span className='required-text'>*</span>,'')}
                        </div>
                        <Box className="inputField">
                          <PhoneInputWrapper>
                            <PhoneInput
                              country={'us'}
                              placeholder='Enter your phone number'
                              value={values.phone_number}                              
                              data-testid="mobile_number"
                              onChange={(phone) => {
                                setFieldValue('phone_number', phone);
                                this.setState({ therapyPhoneNo: phone }); 
                              }}
                            />
                          </PhoneInputWrapper>
                          {ConditionalRenderer(Boolean(errors.phone_number),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.phone_number),
                                <div className="error">
                                  {errors.phone_number}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                        </Box>
                        <Box className="checkboxContainer">
                <label style={webstyle.checkboxLabel}>
                    <input
                     type="checkbox"
                     checked={this.state.landline_no}
                     onChange={this.handleLandlineToggles}
                     style={webstyle.checkboxInput}
                     data-test-id = "landline" 
                    />
                <span style={webstyle.checkboxCustom(this.state.landline_no)}>
                  {<span style={webstyle.checkmark}></span>}
                </span>
                          <span className="checkboxText">I don't have a mobile - add a landline</span>
                          </label>
                        </Box>
                              {
                                ConditionalRenderer(this.state.landline_no, <Box className="inputField">
                                <PhoneInputWrapper>
                                  <PhoneInput
                                    country={'us'}
                                    placeholder='Enter your phone number'
                                    value={values.landline_number}                              
                                    data-test-id="landline_number"
                                   onChange={(landline_no: any)=> {
                                      setFieldValue('landline_number', landline_no);
                                      this.setState({ therapyLadlineNo: landline_no });
                                    }}/>
                                </PhoneInputWrapper>                             
                              </Box>,'')                                 
                              }                       
                      </Box>
                    }

                    {
                      this.state.formStats.email &&
                      <Box className="div-container">
                        <div className="heading">{this.state.formStats.email_label}
                        {ConditionalRenderer(this.state.formStats.email_req, <span className='required-text'>*</span>,'')}
                        </div>
                        <Box className="inputField">
                          <Field
                              onChange={(e:any) => {
                                handleChange(e);
                                this.setState({therapyEmail: e.target.value})
                              }}
                            name="email"
                            data-test-id="txtInput2"
                            as={TextField}
                            value={values.email}
                            className="emailField inputFields"
                            placeholder="Enter Email address"
                          />
                          {ConditionalRenderer(Boolean(errors.email),
                            <div className="error-container">
                              {ConditionalRenderer(Boolean(touched.email),
                                <div className="error">
                                  {errors.email}
                                </div>, ''
                              )}
                            </div>, ''
                          )}
                        </Box>
                      </Box>
                    }

                    {this.state.formStats.contact_method && (
                      <>
                        <div className="heading">
                          Preferred Contact Method{" "}
                          {ConditionalRenderer(this.state.formStats.contact_method_req,<span className='required-text'>*</span>,'')}                          
                          <span className="Select">
                            (select all that apply)
                          </span>
                        </div>
                        <Box className="checkBoxGroup">
                        <label style={webstyle.checkboxLabel} >
                          <input
                            type="checkbox"
                            data-test-id="phone1"
                             onChange={(e:any) => {
                              setFieldValue("phone_checkbox", e.target.checked);
                              this.handleCheckboxChange("phone", e.target.checked);
                            }}                          
                            name="phone_checkbox"
                            checked={values.phone_checkbox}
                            style={webstyle.checkboxInput}  
                          />
                         <span
                           style={webstyle.checkboxCustom(values.phone_checkbox)}>
                          {<span style={webstyle.checkmark}></span>}
                         </span>
                         <span className="phoneClass">Phone</span>
                        </label>
                        <label style={webstyle.checkboxLabelEmail}>
                          <input
                            type="checkbox"
                            data-test-id="email1"
                            style={webstyle.checkboxInput}  
                            onChange={(e:any) => {
                              setFieldValue("email_checkbox", e.target.checked);
                              this.handleCheckboxChange("email", e.target.checked);
                            }}  
                            name="email_checkbox"
                            checked={values.email_checkbox}
                          />
                         <span
                           style={webstyle.checkboxCustom(values.email_checkbox)}>
                          {<span style={webstyle.checkmark}></span>}
                         </span>
                         <span className="phoneClass">Email</span>
                         </label>
                         <label style={webstyle.checkboxLabel}>
                          <input
                            type="checkbox"
                            data-test-id="sms1"
                            style={webstyle.checkboxInput}  
                            onChange={(e:any) => {
                              setFieldValue("sms_checkbox", e.target.checked);
                              this.handleCheckboxChange("sms", e.target.checked);
                            }}  
                            name="sms_checkbox"
                            checked={values.sms_checkbox}
                          />
                         <span
                           style={webstyle.checkboxCustom(values.sms_checkbox)}>
                          {<span style={webstyle.checkmark}></span>}
                         </span>
                         <span className="phoneClass">SMS</span>
                         </label>
                        </Box>                      
                      </>
                    )}                 
                    <Box className="checkboxContainerLast">
                      
                       <label style={webstyle.checkboxLabel}>
                          <input
                            type="checkbox"
                            style={webstyle.checkboxInput}  
                            name="agree"
                            data-test-id="agreeCheck"
                            checked={this.state.agreeChecked}
                            onChange={(e) =>
                            this.handleAgreeChanges(e.target.checked)
                            }
                          />
                         <span
                           style={webstyle.checkboxCustom(this.state.agreeChecked)}>
                          {<span style={webstyle.checkmark}></span>}
                         </span>
                         <span className="phoneClass">
                        I agree with the{" "}
                        <u data-test-id = "conditions" onClick={()=>this.handleNavigateTermConditions("terms_and_condition","patient")}>
                          <i className="italic" style={{cursor:"pointer"}}>Terms of Use</i>
                        </u>{" "}
                        <span className="phoneClass">and{" "}</span>
                        <u data-test-id = "condition" onClick={()=>this.handleNavigateTermConditions("privacy_policy","patient")}>
                          <i className="italic"  style={{cursor:"pointer"}}>Privacy Policy</i>
                        </u>
                      </span>
                         </label>
                      
                      {
                        this.state.modalOpen && <TermsConditionsEdit navigation={undefined} id={""} modalOpen={this.handleNavigateTermConditions} pageType={this.state.apiPageType} role={this.state.role} states={this.state.modalOpen}/>
                      }
                    </Box>
                  </Box>
                  <Box>
                    <button
                     onClick={()=>
                      this.postTherapySignupApi() 
                    }
                      className={`button-continue ${ResolveCondition(
                        this.state.agreeChecked,
                        "active",
                        "inactive"
                      )}`}
                      disabled={!this.state.selectedValue}
                      data-test-id="submitBtn"
                      type="submit"
                    >
                      Continue
                    </button>
                  </Box>
                  <Box>
                    <u className="content">Already have an account? Log in</u>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
            {
              ConditionalRenderer(this.state.formStats.help_footer, <Box className="footer" id="footer">
              <p className="emergencyIcon">
                <span>
                  <img src={emergency} alt="emergency" className="yellowFilter" />
                </span>
                &nbsp; If this is an emergency &nbsp; <u>please call 911</u>
              </p>
              <div className="footerRight" id="rightFooter">
                <span id="label-need-help" className="label-700-20">
                  Need Help?
                </span>
                <button className="callButton" data-test-id="callBTN">
                  Schedule a call?
                  <span className="callImg">
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>,<Box className="footer"></Box>)
               
            }
         
        </section>
      </StyledWrapperForWellness>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapperForWellness = styled("div")({
  margin: "0 auto",
  height: "100%",
  width: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .required-text": {
    textFillColor: "#DC2626",
    WebkitTextFillColor: "#DC2626",
    margin:"3px"
},
  "& .MuiFormControl-root.MuiTextField-root.emailField.inputFields": {
    width: "100% !important"
  },
  "& .emailField .MuiOutlinedInput-root": {
    width: "100%",
    height: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
    borderColor: "#F87171",
    "& fieldset": {
      borderColor: "#F87171",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F87171",
    },
    "&:hover fieldset": {
      borderColor: "#F87171",
    },
    "& .MuiInputBase-input": {
      height: "100%",
    }
  },
  "& .MuiCheckbox-root.Mui-checked svg": {
    color: "#1A4A42",
    borderRadius: "4px",
  },
  "& .headerSteppers": {
    "display": "flex",
    "paddingTop": "32px",
    "justifyContent": "center",
  },
  "& .MuiCheckbox-root svg": {
    borderRadius: "10px",
  },
  "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  "& .header .custom-step-connector .MuiStepConnector-root": {
    display: "none",
  },
  "& .header .custom-step-connector .MuiStepper-horizontal": {
    justifyContent: "space-between",
    position: "relative",
  },
  "& .header .custom-step-connector .MuiSvgIcon-root": {
    borderRadius: "10px",
  },
  "& .inputField .inputFields .MuiInputBase-root": {
    width: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px"
  },
  "& .inputField .inputFields .MuiOutlinedInput-root": {
    width: "100%",
    maxHeight: "42px",
    borderRadius: "24px",
    borderColor: "#F87171",
    "& fieldset": {
      borderColor: "#F87171",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F87171",
    },
    "&:hover fieldset": {
      borderColor: "#F87171",
    },
  },
  "& .header .custom-step-connector .MuiStepLabel-iconContainer::after":
  {
    height: "3px",
    backgroundColor: "#A8A29E",
    content: `""`,
    position: "absolute",
    width: "239px",
    left: "44px",
    top: "10px",
  },
  "& .Select": {
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#78716C",
    fontSize:"16px",
    lineHeight: "24px",
  },
  "& .error-container": {
    // minHeight: "20px",
  },
  "& .error": {
    color: "#DC2626",
    marginTop: "2px",
    textAlign: "initial",
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"13px",
    lineHeight:"19.5px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "100%",
  },
  "& .inputField .inputFields .MuiInputBase-root.MuiOutlinedInput-root":
  {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
  },
  "& .italic": {
    fontFamily: "Poppins",
    fontWeight:400,
    fontSize:"20px",
    lineHeight:"30px",
    textDecoration: "underline",
    color: "#1A4A42",
    textDecorationColor: "green",
  },
  "& .content": {
    fontWeight: 700,
    color: "#E46760",
    fontSize: "20px",
    fontFamily: "Raleway",
    lineHeight:"23.48px"
  },
  "& .div-container": {
    marginBottom: "24px",
  },

  "& .checkBoxGroup": {
    maxWidth: "291px",
    display: "flex",
    alignItems: "center",
    maxHeight: "22px",
    marginTop: "6px",
    padding: "10px",
    borderRadius: "10px",
    marginLeft:"-10px"
  },
  "& .phoneClass"  : {
     fontFamily:"Poppins",
     fontWeight:400,
     fontSize:"20px",
     lineHeight:"30px"
  },

  "& .checkBoxGroup.active": {
    backgroundColor: "#000000",
  },
  "& .checkboxContainerLast": {
    display: "flex",
    marginTop: "24px",
    alignItems: "center",
  },
  "& .checkboxContainer": {
    display: "flex",
    alignItems: "center",
    marginTop:"4px"
  },
  "& .upperCheckbox":{
     borderRadius:"20px"
  },
  '& .checkboxText' : {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .heading": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
  }, 
  "& .box": {
    height: "100%",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "340px",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    marginTop: "32px",
  },
  "& .headerLogo": {
    height: "auto",
    width: "100%",
    minHeight: "78px",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .footerRight": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  "& .label-700-20": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  "& .main-section": {
    // height: "100vh",
    textAlign: "center",
    marginTop : "30px"
  },
  "& .custom-step-connector": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .header .custom-step-connector .MuiStepLabel-labelContainer": {
    alignItems: "center",
    flexDirection: "column",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
  "& .questions": {
    fontWeight: 600,
    fontFamily: "Raleway",
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    maxWidth: "700px",
    margin: "auto",
  },
  "& .information": {
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "32px",
  },
  "& .callButton": {
    borderRadius: "16px",
    background: "#ffffff",
    border: "1px solid #E46760",
    padding: "5px 10px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    marginTop: "77.78px",
    padding: "0px 141px",
    minHeight: "96px",
    "@media(max-width:750px)": {
      padding: "0px 0px",
    },
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .button-continue": {
    border: "none",
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer",
    background: "#E46760",
    marginTop: "32px",
    marginBottom: "28px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight:"23.48px"
  },
  "& .button-continue.active": {
    border: "none",
    background: "#E46760",
    cursor: "pointer",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
  },

  "& .button-continue.inactive": {
    border: "none",
    background: "#D6D3D1",
    cursor: "not-allowed",
    color: "#FFFFFF",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  "@media screen and (max-width:1200px)": {
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
  },
});

const PhoneInputWrapper = styled(Box)({
  "& .form-control": {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    paddingLeft: "15px",
    color: "#36403B",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "24px",
    border: "1px solid #F87171"
  },
  "& .form-control::placeholder": {
    fontSize: "15px",
  },
  "& .selected-flag": {
    display: "none"
  },
  "& .flag-dropdown ": {
    border: "none"
  }
});
const webstyle = {
  container: {
    fontFamily: "Arial, sans-serif",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
  },
  checkboxLabelEmail:{
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    margin:"0px 24px"
  },
  checkboxInput: {
    display: "none",
  },
  checkboxCustom: (isChecked: any): React.CSSProperties => ({
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
    borderRadius: "6px",
    marginRight: "10px",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
  }),
  checkmark: {
    position: "absolute" as "absolute", // Explicitly setting the position type
    top: "3px",
    left: "5px",
    width: "6px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)",
    opacity: 1,
  } as React.CSSProperties,
  linkStyle: {
    color: "#00796b",
    textDecoration: "underline",
  },
};


// Customizable Area End