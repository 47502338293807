import React, { CSSProperties } from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Divider,
  TextField,
} from "@mui/material";

import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AddAppointmentController, {
  Props,
  configJSON,
} from "./AddAppointmentController";

export default class AddAppointment extends AddAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "1rem 0",
              }}
            >
              <Button
                data-test-id="btnNavigateToAppointments"
                variant="contained"
                color="primary"
                onClick={() => this.navigateToAppointments()}
              >
                {configJSON.viewAllAppointments}
              </Button>
            </Box>
            <Box
              width={"100%"}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography noWrap variant="h6">
                {configJSON.availableDate}:{" "}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  data-test-id="txtInputAvailableDate"
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  inputFormat={configJSON.webFormat}
                  minDate={configJSON.minDate}
                  maxDate={configJSON.maxDate}
                  value={this.state.available_date}
                  onChange={(value) =>
                    this.update({ available_date: this.toMomentDate(value) })
                  }
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Divider
                orientation="horizontal"
                flexItem
                style={{ margin: "1rem 0" }}
              />

              <Typography noWrap variant="h6">
                {configJSON.availableFrom}:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  data-test-id="txtInputAvailablefrom"
                  ampm={false}
                  value={this.state.start_time}
                  onChange={(value) => {
                    if (value) {
                      this.update({ start_time: value });
                    }
                  }}
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Divider
                orientation="horizontal"
                flexItem
                style={{ margin: "1rem 0" }}
              />

              <Typography noWrap variant="h6">
                {configJSON.availableTo}:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  data-test-id="txtInputAvailableTo"
                  ampm={false}
                  value={this.state.end_time}
                  onChange={(value) => {
                    if (value) {
                      this.update({ end_time: value });
                    }
                  }}
                  //@ts-ignore #R18RN640524 This TS error is a result of running React 18 with RN 0.64 and will be resolved when we upgrade to RN 0.70
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <Divider
                orientation="horizontal"
                flexItem
                style={{ margin: "1rem 0" }}
              />

              <Button
                data-test-id="btnAddAppointment"
                variant="contained"
                color="primary"
                onClick={() => this.addAppointment()}
                disabled={!this.state.token}
              >
                {configJSON.addAppointment}
              </Button>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
