import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export interface Props{
  navigation: any;
  id: string; 
}
interface Option {
  id: number;
  label: string;
}

export interface S{  
  optionList : Option[];
  selectedData : number[];
  showOtherInput : boolean;
  otherValue: string,
  listData: {progress_bar_text: string, progress_bar_stage: string}[],
  activeStep: string
}

interface SS {
    id: any;
  }

export default class LastQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {  
      optionList: [
        { id: 1, label: 'Autism/Asperger.s' },
        { id: 2, label: 'Addictions/Substance Abuse' },
        { id: 3, label: 'ADHD' },
        { id: 4, label: 'Adoption' },
        { id: 5, label: 'Talking to friends/loved ones' },
        { id: 6, label: 'Anger Management' },  
        {id : 7, label : 'Other'}             
      ],
      selectedData : [],
      showOtherInput : false,
      otherValue: '',
      listData: [
        {progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2'}, 
        { progress_bar_text: 'Choose Provider', progress_bar_stage: '3' }
      ],
      activeStep: 'About You'
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 
        
      handleTicks = (id: number, label: string) => {      
        this.setState((prevState) => {
          const isSelected = prevState.selectedData.includes(id);
          const newSelectedData = isSelected
            ? prevState.selectedData.filter((item) => item !== id)
            : [...prevState.selectedData, id];
      
          return {
            selectedData: newSelectedData,
            showOtherInput: label === "Other" ? !isSelected : prevState.showOtherInput,
          };
        });
      }
      

    handleOtherInputChange = (event: any) => {
      this.setState({ otherValue: event.target.value });      
  };

  handleOtherInputKeyPress = (event:any) => {
    if (event.key === 'Enter' && this.state.otherValue.trim() !== '') {
      const newOption = {
        id: this.state.optionList.length + 1,
        label: this.state.otherValue
      };
  
      this.setState((prevState) => {
        const otherIndex = prevState.optionList.findIndex(option => option.label === 'Other');
        const updatedOptionList = [
          ...prevState.optionList.slice(0, otherIndex),
          newOption,
          ...prevState.optionList.slice(otherIndex)
        ];
  
        return {
          optionList: updatedOptionList,
          selectedData: [...prevState.selectedData, newOption.id],
          showOtherInput: false,
          otherValue: ''
        };
      });
    }
  };
  
  
}