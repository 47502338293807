// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

interface FormDataStructure {
    suffix_label: any;
    id: number | null;
    form_header: string;
    form_description: string;
    email: boolean;
    email_req : boolean;
    sign_up_with_google: boolean;
    phone_number: boolean;
    phone_number_req : boolean;
    contact_method: boolean;
    contact_method_req : boolean;
    name: boolean;
    progress_bars: {progress_bar_text: string, progress_bar_stage: string}[];
    stage_type: string;
    email_label : string;
    phone_number_label : string;
    contact_method_label : string;
    help_footer : boolean;
  }
  // Customizable Area End
  
 
  
  export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    selectedValue: string | [];
    agreeChecked: boolean;
    skipped: Set<number>;
    formStats: FormDataStructure;
    identificationContactDetails :{secondary_mobile_number: { value: string }}  
    modalOpen:boolean;
    apiPageType:string,
    role:string;
    landline_no : boolean;
    therapyEmail:string;
    therapyLadlineNo:any;
    therapyPhoneNo:any;
    phoneCheckbox:boolean;
    selectedMethods:any;
    emailCheckbox:boolean;
    smsCheckbox:boolean;
    postApiId:any;
    
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }
  
  export default class WellnessJourneyController extends BlockComponent<
    Props,
    S,
    SS
  > {
    // Customizable Area Start
    getQuestionStatsCallId: string = "";
    postTherapyApiCallId:string = "";
    // Customizable Area End
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      // Customizable Area Start
      // Customizable Area End
  
      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.AccoutLoginSuccess),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];
  
      this.state = {
        // Customizable Area Start
        selectedValue: [],
        selectedMethods:[],
        postApiId:"",
        therapyEmail:"",
        therapyLadlineNo:"",
        phoneCheckbox:false,
        emailCheckbox:false,
        smsCheckbox:false,
        therapyPhoneNo:"",
        modalOpen:false,
        apiPageType:"",
        role:"",
        skipped: new Set(),
        agreeChecked: false,
        landline_no : false,
        formStats:{
          id: null,
          form_header: "",
          form_description: "",
          email: false,
          email_req : false,
          phone_number: false,
          phone_number_req : false,
          contact_method: false,
          contact_method_req : false,
          name: false,
          sign_up_with_google: false,
          progress_bars: [],
          stage_type: '',
          email_label : '',
          phone_number_label : '',
          contact_method_label :'',  
          help_footer : false,
          suffix_label: "",   
        },
        identificationContactDetails: {secondary_mobile_number: { value: "" }}
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  
      // Customizable Area Start
      // Customizable Area End
    }
  
    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      this.handleResForQuestionStats(from,message)
      this.handlePostApiResponse(from,message)
      // Customizable Area End
    }
  
    // Customizable Area Start
    async componentDidMount(){
      this.getApis()
    }
  
    handleCheckboxChange = (method: any, isChecked: any) => {
      let selectedMethods = [...this.state.selectedMethods];
    
      if (isChecked) {
        selectedMethods.push(method);
      } else {
        selectedMethods = selectedMethods.filter(item => item !== method); 
      }
    
      this.setState({ selectedMethods: selectedMethods });
    };
    
  
    getApis = () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getQuestionStatsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_landingpage2/landing_page_questions/get_variant_form_data?form_type=authorized_representative"
        
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  
    handleResForQuestionStats = async (from: string, message: Message) => {
      if (
        this.getQuestionStatsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){
          this.setState({formStats:responseJson})
        }
      }
    };
    handleNavigationWellness = (pathname: string, props: unknown, param?: any) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
      runEngine.sendMessage(message.id, message)
    };
    handlePostApiResponse = async (from: string, message: Message) => {
      if (
        this.postTherapyApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){
          this.setState({postApiId:responseJson.data.id} , () => {
            this.handleNavigationWellness("PatientDetailsUpdate", this.props, this.state.postApiId)
  
          })
  
        }
  
      }
    };
    handleLandlineToggles = () => {
      this.setState((prevState) => ({
        landline_no: !prevState.landline_no,
      }));
    }; 
  
    handleAgreeChanges = (value: boolean) => {
      this.setState({ agreeChecked: value });
    };
  
    postTherapySignupApi = async () => {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      
      let data = {
        data: {
        type: "patients",
        attributes: {
        email: this.state.therapyEmail,
        phone_number: this.state.therapyPhoneNo ? this.state.therapyPhoneNo : this.state.therapyLadlineNo,
        contact_method: this.state.selectedMethods
      }
        },
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.postTherapyApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postTherapyDataApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
   
    // Customizable Area End
  }

// Customizable Area End