// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  main_title: string,
  responseWellness: any,
  message: string
}

interface SS {
  id: string;
}

export default class WellnessMembershipPlan extends BlockComponent<
  Props,
  S,
  SS
> {
  getWellnessMembershipPlanCallId: string = "";
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      main_title: "",
      responseWellness: [],
      message: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    this.handleWellnessMembershipPlan(from,message);
  }

  async componentDidMount(){
    this.getMembershipPlanPage()
  }

  handleNavigation = (pathname: string) => {
    const paramsDatas = pathname.split('/');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), paramsDatas[0]);
    message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
    paramsDatas[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsDatas[1]);
    this.send(message);
  }
  
  getMembershipPlanPage = () => { 
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWellnessMembershipPlanCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWellnessMembershipPage
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleWellnessMembershipPlan =  async (from: string, message: Message) => {
    if (
      this.getWellnessMembershipPlanCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({responseWellness:responseJson.data , message: ""})
        };
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found"});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found"});
        }
      };
    }
  }
}
// Customizable Area End