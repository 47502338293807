// Customizable Area Start
import { createRef, RefObject } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    id: string;
    classes: Object;
}

interface S {
    toggleMenu: boolean,
    selectedMenu: string,
    button_text: string,
    menuList: string[],
    showModal: boolean,
    messageText: string,
    file: File[]
}

interface SS {
    id: string;
}

export default class NeedHelpController extends BlockComponent<
    Props,
    S,
    SS
> {
    getNeedHelpCallId: string = "";
    needHelpPostApiCallID: string = "";
    dropDownRef: RefObject<HTMLDivElement> | null;
    uploadRef: RefObject<HTMLInputElement> | null;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            toggleMenu: false,
            selectedMenu: '',
            button_text: 'New Chat',
            menuList: [
                "Any time", 
                "Morning: 8 - 12 PM",
                "Afternoon: 12 - 4 PM",
                "Evening: 4 - 8 PM"
            ],
            showModal: false,
            messageText: '',
            file: []
        };
        this.dropDownRef = createRef();
        this.uploadRef = createRef();
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
    }

    handleSelectedData = (data: string) => {
        this.setState({
            selectedMenu: data,
            toggleMenu: false
        })
    }

    handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileData = event.target.files;
        if (fileData && fileData.length > 0) {
            this.setState({
                file: [fileData[0]]
            })
        }
    }

    handleMessage = (data: string) => {
        this.setState({
            messageText: data
        })
    }
    
    async componentDidMount(): Promise<void> {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.dropDownRef && this.dropDownRef.current && !this.dropDownRef.current.contains(event.target as Node)) {
            this.setState({ toggleMenu: false });
        }
    };

    handleChat = () => {
        this.setState({
            showModal: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false
        });
    }

    toggleMenuData = () => {
        this.setState({
            toggleMenu: !this.state.toggleMenu
        })
    }
}

// Customizable Area End