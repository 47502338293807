// Customizable Area Start
import { Box, styled, Button } from "@mui/material";

export const CommonStyles = {
    CommonStyles: {
        "& .header-section": {
            height: "78px",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #E7E5E4",
            width: "100%",
            '& img': { paddingLeft: "112px" },
        },
        "& .required-text": {
            textFillColor: "#DC2626",
            WebkitTextFillColor: "#DC2626"
        },
        "& .danger-text": {
            fontFamily: "Raleway",
            textDecoration: "underline",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "23.48px",
            letterSpacing: "1px",
            textAlign: "left",
            color: "#E46760",
            cursor: "pointer",
            marginBottom: "54.78px",
        },
        "& .termsConditionsContainer": {
            width: "max-content",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
        },
        "& .active-text, & .checkBoxGroup .label-text, & .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active, & .mobilenumber-input": {
            background: "linear-gradient(0deg, #2C2524, #2C2524), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
        },
        "& .default-text": {
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            textAlign: "left",
            "& .termsConditionText": {
            textDecoration: "underline",
            fontStyle: "italic",
            color: "#1A4A42 !important",
            background: "none",
            WebkitBackgroundClip: "border-box",
            WebkitTextFillColor: "initial",
            },
        },
        "& .label-color": {
            color: "#2C2524"
        },
        "& .form-container": {
            backgroundColor: "#fff",
            height: "auto",
            minHeight: "78px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "1512px",
            borderLeft: "32px solid #1A4A42",
        },
        "& .checkBoxGroupContainer, & .checkBoxGroup": {
            display: "flex",
            gap: "24px",
            marginTop: "4px",
        },
        "& .checkBoxGroup": {
            alignItems: "center",
            gap: "8px",
        },
        '& .form-label': {
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            textAlign: "left",
            color: "#2C2524"
        },
        '& .menu-text': {
            height: "46px",
            width: "100%",
            maxWidth: "570px",
            padding: "0 12px 0 16px",
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            borderBottom: "1px solid #D6D3D1",
            ':last-child': {
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                borderBottom: "none"
            },
            "&.selected": {
                backgroundColor: "#F5F5F4"
            }
        },
        '& .menu-container': {
            borderRadius: "24px",
            border: "none",
            width: "max-content",
            '&.active': {
                outline: "2px solid #E6C79C",
                borderTop: "none !important",
                "& .select-btn": {
                    outlineWidth: "2px"
                }
            }
        },
        "& .heading-text": {
            fontFamily: "Raleway !important",
            color: "#FFFFFF",
            fontWeight: 600,
            fontSize: "41px",
            lineHeight: "53.3px",
            letterSpacing: "0.5px",
            textAlign: "center",
        },
        "& .custom-step-connector": {
            maxWidth: "600px",
            width: "100%",
            marginTop: "32px",
        },
        "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
            marginTop: 10,
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            textAlign: "center",
            color: "#57534E",
        },
        "& .mobilenumber-input": {
            border: "none",
            width: "100%",
            height: "44px",
            borderRadius: "24px",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            padding: "10px 16px",
            outline: "1px solid #E6C79C",
            "&::placeholder": { 
                color: "#A8A29E",
                opacity: 1 
            },
            "&:focus": { outlineWidth: "2px" },
        },
        "& .mobilenumber-container.disabled, & .mobilenumber-input.disabled": {
            cursor: "not-allowed",
            opacity: 0.5,
            pointerEvents: "none",
        }, 
        "& .date-picker-container":{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%"
        },
        "& .inputFields.two-column":{
            width:"100%",
            maxWidth:"100%",
            '&:focus': {
                outlineWidth: "2px"
            }

        },
        "@media screen and (min-width: 1280px) and (max-width:1860px)": {
            '& .left-section': {
            marginLeft: "20px !important",
            },
            '& .right-section': {
            marginRight: "44px !important",
            },
            "& .header-section": {
            '& img': {
                paddingLeft: "23px !important",
            },
            },
        }
    },
    outlineInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: "1px solid #E6C79C"
            },
            '&:hover fieldset': {
              border: "1px solid #E6C79C"
            },
            '&.Mui-focused fieldset': {
              border: "2px solid #E6C79C"
            },
        }
    },
    CustomBox: styled(Box)(({ theme }) => ({
        '&.select-btn': {
            marginTop: "4px",
            width: "566px",
            borderRadius: "24px",
            outline: "1px solid #E6C79C",
            height: "44px",
            backgroundColor: '#fff',
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
            color: "#A8A29E",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            '& img.active': { rotate: "180deg" },
        },
    })),
    CustomButton: styled(Button)(({ theme }) => ({
        textTransform: 'none',
        fontFamily: "Raleway",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        fontWeight: 700,
        fontSize: '20px',
        '&.submit-button': {
            backgroundColor: '#E46760',
            color: '#FFFAF6',
            borderRadius: '24px',
            width: "100%",
            height: "56px",
        },
        '&.Mui-disabled.submit-button': {
            backgroundColor: '#D6D3D1',
            color: '#78716C',
            cursor: 'not-allowed',
            boxShadow: 'none',
            pointerEvents: 'none',
        },
        '&.submit-button:hover': {
            backgroundColor: '#E46760',
        },
    })),
    PhoneInputWrapper: styled(Box)({
        "& .form-control": {
            border: "none",
            width: "100%",
            height: "44px",
            borderRadius: "24px",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            padding: "10px 16px",
            outline: "1px solid #E6C79C",
            "&::placeholder": { 
                color: "#A8A29E",
                opacity: 1 
            },
            "&:focus": { outlineWidth: "2px" },
        },
        "& .selected-flag": { display: "none" },
        "& .flag-dropdown ": { border: "none" },
    })
}

// Customizable Area End
