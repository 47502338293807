import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageAdapter from "../../blocks/adapters/src/AlertPageAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import AnalyticsAdapter from "../../blocks/adapters/src/AnalyticsAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";

//Assembler generated adapters start
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const analyticsAdapter = new AnalyticsAdapter();
const onboardingAdapter = new OnboardingAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageAdapter = new AlertPageAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to TotalLifeMainPlatform!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'ocropticalcharacterrecognition'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'OrderManagement'}  onPress={() => navigation.navigate("OrderManagement")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'GroupVideoCall'}  onPress={() => navigation.navigate("GroupVideoCall")} />
<CustomTextItem content={'storecredits'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLogin'}  onPress={() => navigation.navigate("SocialMediaAccountLogin")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'Customisableusersubscriptions'}  onPress={() => navigation.navigate("Customisableusersubscriptions")} />
<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'customform'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'chatbot'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'LanguageSupport'}  onPress={() => navigation.navigate("LanguageSupport")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'surveys'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'audiolibrary'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DailyScheduleNotifications'}  onPress={() => navigation.navigate("DailyScheduleNotifications")} />
<CustomTextItem content={'livestreaming'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'amazoninteractivevideoservice'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Notificationsettings'}  onPress={() => navigation.navigate("Notificationsettings")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'outlookintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'timetracker'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'Hashtags'}  onPress={() => navigation.navigate("Hashtags")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'Chat'}  onPress={() => navigation.navigate("Chat")} />
<CustomTextItem content={'Comment'}  onPress={() => navigation.navigate("Comment")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'DesktopNotifications'}  onPress={() => navigation.navigate("DesktopNotifications")} />
<CustomTextItem content={'automaticreminders'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'ICalendarSupport'}  onPress={() => navigation.navigate("ICalendarSupport")} />
<CustomTextItem content={'repeatingtasks'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'ReviewApprovalAdmin'}  onPress={() => navigation.navigate("ReviewApprovalAdmin")} />
<CustomTextItem content={'contentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'hamburgermenu'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Polling'}  onPress={() => navigation.navigate("Polling")} />
<CustomTextItem content={'recommendationengine'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'dashboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'productdescription'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'SaveAsPdf'}  onPress={() => navigation.navigate("SaveAsPdf")} />
<CustomTextItem content={'ShoppingCartOrders'}  onPress={() => navigation.navigate("ShoppingCartOrders")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'Share'}  onPress={() => navigation.navigate("Share")} />
<CustomTextItem content={'multiselect'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Appointments'}  onPress={() => navigation.navigate("Appointments")} />
<CustomTextItem content={'timetrackingbilling'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'customisableuserprofiles'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Reviews'}  onPress={() => navigation.navigate("Reviews")} />
<CustomTextItem content={'RequestManagement'}  onPress={() => navigation.navigate("RequestManagement")} />
<CustomTextItem content={'DragDropInterface'}  onPress={() => navigation.navigate("DragDropInterface")} />
<CustomTextItem content={'fileattachment'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'kanbanboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'GoogleCalendarSync'}  onPress={() => navigation.navigate("GoogleCalendarSync")} />
<CustomTextItem content={'smssettings'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'Sorting'}  onPress={() => navigation.navigate("Sorting")} />
<CustomTextItem content={'Cfaccesslevelmatrix1'}  onPress={() => navigation.navigate("Cfaccesslevelmatrix1")} />
<CustomTextItem content={'Cfactivityconsole1'}  onPress={() => navigation.navigate("Cfactivityconsole1")} />
<CustomTextItem content={'Cfattendancetracking1'}  onPress={() => navigation.navigate("Cfattendancetracking1")} />
<CustomTextItem content={'Cfautomateddataencryption1'}  onPress={() => navigation.navigate("Cfautomateddataencryption1")} />
<CustomTextItem content={'Cfbillingcodeoverridestacking1'}  onPress={() => navigation.navigate("Cfbillingcodeoverridestacking1")} />
<CustomTextItem content={'Cfbrandedinterfacedocuments1'}  onPress={() => navigation.navigate("Cfbrandedinterfacedocuments1")} />
<CustomTextItem content={'Cfbrandedsingupportal1'}  onPress={() => navigation.navigate("Cfbrandedsingupportal1")} />
<CustomTextItem content={'Cfbulkbillingsuperinvoices1'}  onPress={() => navigation.navigate("Cfbulkbillingsuperinvoices1")} />
<CustomTextItem content={'Cfconditionallogic1'}  onPress={() => navigation.navigate("Cfconditionallogic1")} />
<CustomTextItem content={'Cfcontacthistory1'}  onPress={() => navigation.navigate("Cfcontacthistory1")} />
<CustomTextItem content={'Cfcontactlists1'}  onPress={() => navigation.navigate("Cfcontactlists1")} />
<CustomTextItem content={'Cfcontactpropertiesevents1'}  onPress={() => navigation.navigate("Cfcontactpropertiesevents1")} />
<CustomTextItem content={'Cfcontactpropertieslistener1'}  onPress={() => navigation.navigate("Cfcontactpropertieslistener1")} />
<CustomTextItem content={'Cfcoursemodules1'}  onPress={() => navigation.navigate("Cfcoursemodules1")} />
<CustomTextItem content={'Cfcustomnotetemplatescreator1'}  onPress={() => navigation.navigate("Cfcustomnotetemplatescreator1")} />
<CustomTextItem content={'Cfcustomonboardingflowcreator1'}  onPress={() => navigation.navigate("Cfcustomonboardingflowcreator1")} />
<CustomTextItem content={'Cfcustomroleandpermissioncreator1'}  onPress={() => navigation.navigate("Cfcustomroleandpermissioncreator1")} />
<CustomTextItem content={'Cfdirectbilling1'}  onPress={() => navigation.navigate("Cfdirectbilling1")} />
<CustomTextItem content={'Cfdisablescreenshots1'}  onPress={() => navigation.navigate("Cfdisablescreenshots1")} />
<CustomTextItem content={'Cfemailevents1'}  onPress={() => navigation.navigate("Cfemailevents1")} />
<CustomTextItem content={'Cfemaillistener1'}  onPress={() => navigation.navigate("Cfemaillistener1")} />
<CustomTextItem content={'Cfiptracking1'}  onPress={() => navigation.navigate("Cfiptracking1")} />
<CustomTextItem content={'Cfmasksensitivefields1'}  onPress={() => navigation.navigate("Cfmasksensitivefields1")} />
<CustomTextItem content={'Cfmicrosoftdefenderforcloud6'}  onPress={() => navigation.navigate("Cfmicrosoftdefenderforcloud6")} />
<CustomTextItem content={'Cfnonstandardinfrasetup1'}  onPress={() => navigation.navigate("Cfnonstandardinfrasetup1")} />
<CustomTextItem content={'Cfonboardingjumpin1'}  onPress={() => navigation.navigate("Cfonboardingjumpin1")} />
<CustomTextItem content={'Cfringfencepatients1'}  onPress={() => navigation.navigate("Cfringfencepatients1")} />
<CustomTextItem content={'Cfcallqueueing1'}  onPress={() => navigation.navigate("Cfcallqueueing1")} />
<CustomTextItem content={'Cfchatbotevents1'}  onPress={() => navigation.navigate("Cfchatbotevents1")} />
<CustomTextItem content={'Cfchatbotlistener1'}  onPress={() => navigation.navigate("Cfchatbotlistener1")} />
<CustomTextItem content={'Cfcloudflaredomainmapping1'}  onPress={() => navigation.navigate("Cfcloudflaredomainmapping1")} />
<CustomTextItem content={'Cfringfencetherapists1'}  onPress={() => navigation.navigate("Cfringfencetherapists1")} />
<CustomTextItem content={'Cfsmsevents1'}  onPress={() => navigation.navigate("Cfsmsevents1")} />
<CustomTextItem content={'Cfsmslistener1'}  onPress={() => navigation.navigate("Cfsmslistener1")} />
<CustomTextItem content={'Cftaskevents1'}  onPress={() => navigation.navigate("Cftaskevents1")} />
<CustomTextItem content={'Cftasklistener1'}  onPress={() => navigation.navigate("Cftasklistener1")} />
<CustomTextItem content={'Cftelephonyevents1'}  onPress={() => navigation.navigate("Cftelephonyevents1")} />
<CustomTextItem content={'Cftelephonylistener1'}  onPress={() => navigation.navigate("Cftelephonylistener1")} />
<CustomTextItem content={'Cfutmtracking1'}  onPress={() => navigation.navigate("Cfutmtracking1")} />
<CustomTextItem content={'Cfwhitelabelurl1'}  onPress={() => navigation.navigate("Cfwhitelabelurl1")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Rolesandpermissions2'}  onPress={() => navigation.navigate("Rolesandpermissions2")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Splitpayments2'}  onPress={() => navigation.navigate("Splitpayments2")} />
<CustomTextItem content={'Uploadmedia3'}  onPress={() => navigation.navigate("Uploadmedia3")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;