import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  Stepper,
  StepLabel,
  StepConnector,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Divider
} from "@mui/material";
import { call, emergency, HeaderLogoImg } from "./assets";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import { Event} from "@mui/icons-material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StepperData from '../../../components/src/StepperData.web';
// Customizable Area End

import WellnessJourneyUpdateController, {
  Props,ForState
} from "./WellnessJourneyUpdateController";
import { ConditionalRenderer, ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
export default class WellnessJourneyUpdate extends WellnessJourneyUpdateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderError = (touched: boolean|undefined, fieldStats: string|undefined) => {
    return ConditionalRenderer(Boolean(touched && fieldStats), <div className="error">{fieldStats}</div>, '')
  }

  validationSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().when([], {
        is: () => this.state.formStats.name_req === true,
        then: Yup.string().required("Required").matches(/^[A-Za-z\s]*$/, "First Name can only contain letters and spaces"),
        otherwise: Yup.string(),
      }),
      lastName: Yup.string()
        .when([], {
          is: () => this.state.formStats.last_name_req === true,
          then: Yup.string().required("Required").matches(/^[A-Za-z\s]*$/, "Last Name can only contain letters and spaces"),
          otherwise: Yup.string(),
        }), 
      suffix: Yup.string()
      .when([], {
        is: () => this.state.formStats.suffix_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }), 
      dob: Yup.string()
      .when([], {
        is: () => this.state.formStats.dob_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }), 
      state: Yup.string()
      .when([], {
        is: () => this.state.formStats.state_req === true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string(),
      }),
      
    });}

   dobRender = (data:boolean,dataLabel:string,dataReq:boolean,value:string,key:keyof ForState,touched:boolean | undefined,error:string| undefined) => {
     return (
      <>
      {data &&(
                  <Box className="first-name">
                        <div className="heading">{dataLabel}
                        {ConditionalRenderer(dataReq,<span className='required-text'>*</span>,'')}

                        </div>
                        <Box className="date-picker-container">
                          <DatePicker
                            className="inputFields"
                            placeholder="MM-DD-YYYY"
                            data-testid="date_of_birth"
                            format="MM-DD-YYYY"
                            name="dateOfBirth"
                            value={value}
                            onChange={(date) => { 
                            this.handleInputChange(key,date?.format)
                           }}
                            required={true}
                            maxDate={new Date()}
                            style={{
                              width: '100%',
                              maxWidth: '288px',
                              borderRadius: '24px',
                              border: '2px solid #E6C79C',
                              padding: '10px',
                              height: "100%",
                              maxHeight: "44px",
                              fontFamily:"Poppins",
                              fontWeight:400,
                              fontSize:"16px",
                              lineHeight:"24px"
                            }}
                          />                         
                          <Event className="calendar-icon" />
                        </Box>
                        {this.renderError(touched, error)}
                      </Box>
                    )}
      </>
     )
  }
  // Customizable Area End

  render() {
    const paramId = this.props.navigation.getParam("navigationBarTitleText");   
    const suffixOptions = this.state?.formStats?.suffix_label.split(",");
    return (
      // Customizable Area Start
      <StyledWrapper>
        <section className="about-section">
          <Box className="headerLogo">
            <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
          </Box>
          <Divider/>
          <Box className="header">
            <StepperData 
              key={`stepper${this.state.stage_type}`}
              listData={this.state.progress_bars}
              activeStep={this.state.stage_type}
            />
          </Box>

          <Formik
            initialValues={{
              lastName: "",
              suffix: "",
              firstName: "",
              dateOfBirth: "",
              state: "",
            }}
            data-test-id="formikWrapper"
            validationSchema={this.validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              this.getQuestionStats();
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Box className="main-section">
                  <h2 className="question">
                    {this.state.formStats.form_header}
                  </h2>
                  <p className="information">
                    {this.state.formStats.form_description}
                  </p>

                  <Box className="box">
                    {this.state.formStats.name && (
                      <Box className="first-row">
                        <Box className="first-name">
                          <div className="heading">{this.state.formStats.name_label}
                          {ConditionalRenderer(this.state.formStats.name_req,<span className='required-text'>*</span>,'')}
                          </div>
                          <Field
                            onChange={(e:any) => {
                              const value = e.target.value;
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                handleChange(e);
                                this.setState({ firstName: value });
                              }
                            }}
                            name="firstName"
                            as={TextField}
                            value={values.firstName}
                            className="inputFields"
                            data-test-id="firstName"
                            placeholder="Enter Your Name"
                          />
                          {this.renderError(touched.firstName as boolean, errors.firstName as string)}
                        </Box>

                        <Box className="first-name">
                          <div className="heading">{this.state.formStats.last_name_label}
                          {ConditionalRenderer(this.state.formStats.last_name_req,<span className='required-text'>*</span>,'')}

                          </div>
                          <Field
                            className="inputFields"
                            onChange={(e:any) => {
                              const value = e.target.value;
                              if (/^[A-Za-z\s]*$/.test(value)) {
                                handleChange(e);
                                this.setState({ lastName: value });
                              }
                            }}
                            name="lastName"
                            as={TextField}
                            value={values.lastName}
                            data-test-id="lastName"
                            placeholder="Enter Your Last Name"
                          />
                          {this.renderError(touched.lastName as boolean, errors.lastName as string)}
                        </Box>
                      </Box>
                    )}
                  
                    <Box className="first-row">
                    {this.state.formStats.suffix && (
                      <Box className="first-name">
                        <div className="heading">{this.state.formStats.suffix_label}
                        {ConditionalRenderer(this.state.formStats.suffix_req,<span className='required-text'>*</span>,'')}

                        </div>
                        <FormControl className="suffixInputField">
                        <Field
                         as={Select}
                         onChange={(e:any) => {
                          handleChange(e);
                          this.setState({suffixValue: e.target.value})
                        }}
                         className="inputFields"
                         name="suffix"
                         value={values.suffix}
                         data-test-id="suffixs"
                         placeholder="Write preffered Suffix"
                         IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
                         MenuProps={{
                          PaperProps: {
                            sx: {
                              border: "1px solid #E6C79C", 
                              borderRadius: "0px 0px 24px 24px", 
                            },
                          },
                        }}
                        sx={{
                          border: "1px solid #E6C79C",
                          borderRadius: "0px 0px 24px 24px", 
                          padding: "12px",
                          borderTop:"none",
                          "& fieldset": {
                            border: "none", 
                          },
                          "& .MuiSelect-select": {
                            borderRadius: "16px",
                          },
                        }}
                        >
                          {suffixOptions.map((item:string,index:number) => (
                          <MenuItem
                          sx={{
                            fontWeight: 400,
                            borderBottom:"1px solid #D6D3D1",
                            padding:"8px 12px 8px 16px",
                            height:"40px",
                            fontFamily: "Poppins", 
                            fontSize: "16px",
                            lineHeight: "24px", 
                            "&:hover": {
                              backgroundColor: "#e0e0e0", 
                            },
                          }}
                          key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Field>
                  </FormControl>
                        {this.renderError(touched.suffix as boolean, errors.suffix as string)}
                      </Box>
                   )}

{this.dobRender(this.state.formStats.dob,this.state.formStats.dob_label,this.state.formStats.dob_req,values.dateOfBirth,"dateValue",touched.dateOfBirth,errors.dateOfBirth)}
                  
                    </Box>
                    { this.state.formStats.state && (  
                    <Box className="full-name">
                      <div className="heading">
                        {this.state.formStats.state_label}
                      {ConditionalRenderer(this.state.formStats.state_req,<span className='required-text'>*</span>,'')}
                      </div>
                      <FormControl className="inputFieldLast" fullWidth>
                        <Field
                          as={Select}
                          onChange={(e:any) => {
                            handleChange(e);
                            this.setState({stateValue: e.target.value})
                          }}
                          name="state"
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                border: "1px solid #E6C79C", 
                                borderRadius: "0px 0px 24px 24px", 
                              },
                            },
                          }}
                          sx={{
                            border: "1px solid #E6C79C",
                            borderRadius: "0px 0px 24px 24px", 
                            padding: "12px",
                            borderTop:"none",
                            "& fieldset": {
                              border: "none", 
                            },
                            "& .MuiSelect-select": {
                              borderRadius: "16px",
                            },
                          }}
                          value={values.state}
                          data-test-id="state"
                          displayEmpty
                          IconComponent={ResolveCondition(this.state.open, KeyboardArrowUpIcon, KeyboardArrowDownIcon)}
                        >
                          {this.state.stateDropdown.map((state, index) => (
                            <MenuItem
                            sx={{
                              fontWeight: 400,
                              borderBottom:"1px solid #D6D3D1",
                              padding:"8px 12px 8px 16px",
                              height:"40px",
                              fontFamily: "Poppins", 
                              fontSize: "16px",
                              lineHeight: "24px", 
                              "&:hover": {
                                backgroundColor: "#e0e0e0", 
                              },
                            }}
                            key={index} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      {this.renderError(touched.state as boolean, errors.state as string)}
                    </Box> 
                     )}                   
                  </Box>
                      
                  <Box>
                    <Button
                    onClick={()=>this.postTherapySignupApi(paramId)}
                      type="submit"
                      className="button-continue"
                      data-test-id="submitBtn"
                    >
                      Submit
                    </Button>
                  </Box>
                  <Box>
                    <u className="content">Already have an account? Log in</u>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>

          <Box className="footer">
            <p className="emergencyIcon" id="footer">
              <span>
                <img src={emergency} alt="emergency" className="yellowFilter" />
              </span>
              &nbsp; If this is an emergency &nbsp; <u>please call 911</u>
            </p>
            <div className="footerRight">
              <span className="label-700-20">Need Help?</span>
              <button className="callButton">
                Schedule a call?
                <span>
                  <img src={call} alt="call" />
                </span>
              </button>
            </div>
          </Box>
        </section>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  width: "100%",
  margin: "0 auto",
  height: "100%",
  "& *": {
    boxSizing: "border-box",
  },
  "& .date-picker-container .rmdp-top-class": {
    backgroundColor: "#FFFFFF",
  },
  "& .date-picker-container .rmdp-container": {
    // display: "block !important",
    width: "100% !important"
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "15px",
  },
  "& .required-text": {
    textFillColor: "#DC2626",
    WebkitTextFillColor: "#DC2626",
    margin:"3px"
},
  "& .calendar-icon": {
    "position": "absolute",
    "right": "13px",
    "height": "24px",
    "width": "24px",
    "color": "#A8A29E",
    "display": "flex",
    "justifyContent": "center",
    "alignContent": "center",
    "alignItems": "center",
    "top": "10px",
    pointerEvents: "none",
    cusrsor: "pointer"
  },
  "& .inputFields": {
    "width": "100%",
    "height": "44px",
    "borderRadius": "24px",
    "boxSizing": "border-box",
  },
  "& .date-picker-container .inputFields input::placeholder": {
    fontSize: "15px",
    fontFamily: "Poppins",
  },
  "& .date-picker-container": {
    "position": "relative",
    "width": "100%",
    "maxWidth": "288px",
    "height": "44px",
    // border :"2px solid black"
  },
  "& .checkboxContainer .MuiCheckbox-root.Mui-checked": {
    color: "#1A4A42"
  },
  "& .checkboxContainerLast .MuiCheckbox-root.Mui-checked": {
    color: "#1A4A42"
  },
  "& .checkboxContainer .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#1A4A42",
  },
  "& .checkboxContainerLast .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
    color: "#1A4A42",
  },
  "& .date-picker-container .inputFields": {
    height: "100%",
    width: "100%",
    maxHeight: "44px",
    maxWidth: "288px",
    background: "#FFFFFF",
  },
  "& .css-j5w0w9-MuiStepConnector-root": {
    display: "none"
  },
  "& .inputFields .MuiOutlinedInput-root": {
    height: "100%",
    width: "100%",
    maxWidth: "288px",
    maxHeight: "44px",
    "& fieldset": {
      borderColor: "#E6C79C",
      display: "flex",
      alignItems: "center",
      border: "2px solid #E6C79C",
      borderRadius: "24px",
      width: "100%"
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
      display: "flex",
      alignItems: "center"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
      display: "flex",
      alignItems: "center"
    },
  },
  "& .suffixInputField .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .inputFieldLast .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .error": {
    color: "#DC2626",
    textAlign: "initial",
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"13px",
    lineHeight:"19.5px",
  },
  "& .full-name": {
    maxHeight: "72px",
    maxWidth: "600px",
    marginTop: "20px",
    // border : "2px solid red",
    padding: "0px 10px"
  },
  "& .classContainer": {
    maxHeight: "98px",
    maxWidth: "600px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
  },
  "& .boxContainerLast": {
    maxWidth: "600px",
    maxHeight: "72px",
    height: "100%",
    width: "100%",
  },
  "& .boxContainer": {
    maxWidth: "288px",
    maxHeight: "72px",
    height: "100%",
    width: "100%",
  },
  "& .buttonGoogle": {
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer",
    backgroundColor: "transparent",
    lineHeight: "23.48px",
    color: "#78716C",
    marginBottom: "28px",
  },
  "& .italic": {
    color: "#1A4A42",
    textDecoration: "underline",
    fontFamily: "Poppins",
    textDecorationColor: "green",
  },
  "& .content": {
    fontWeight: 700,
    fontFamily: "Raleway",
    color: "#E46760",
    fontSize: "20px",
  },
  "& .div-container": {
    marginBottom: "24px",
    maxHeight: "72px",
    maxWidth: "288px",
  },
  "& .heading": {
    fontWeight: 700,
    fontFamily: "Poppins",
    textAlign: "justify",
    fontSize: "16px",
    lineHeight: "28px",
    maxHeight: "24px",
    marginBottom: "4px",
    // border :"2px solid black"
  },
  "& .suffixInputField .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxHeight: "44px",
    maxWidth: "100%",
    "border": "1px solid #E6C79C",
    width: "100%"
  },
  "& .inputFieldLast .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxHeight: "44px",
    maxWidth: "100%",
    "border": "1px solid #E6C79C",
    width: "100%"
  },
  "& .inputFieldLast": {
    maxWidth: "100%",
    maxHeight: "72px",
    borderRadius: "24px",
    width: "100%",
    "& .MuiSelect-select": {
      marginLeft: "-11%",
      display:"flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%", 
      display:"flex"
    }
  },
  "& .suffixInputField": {
    maxWidth: "100%",
    maxHeight: "72px",
    borderRadius: "24px",
    width: "100%",
    "& .MuiSelect-select": {
      marginLeft: "-11%",
      display:"flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-11%", 
      display:"flex"
    }
  },
  "& .box": {
    maxWidth: "600px",
    maxHeight: "325px",
    width: "100%",
    height: "100%",
    margin: "auto",
    marginTop: "32px",
    // border : "2px solid black"
  },
  "& .first-row": {
    display: "flex",
    // margin: "10px",
    width: "100%",
    alignItems: "baseLine",
    // border :"2px solid black"
  },
  "& .first-name": {
    textAlign: "left",
    maxWidth: "calc(50% - 20px)",
    margin: "10px",
    width: "100%",
    // border :"2px solid black"
  },
  "& .headerLogo": {
    height: "auto",
    width: "100%",
    minHeight: "78px",
    alignItems: "center",
    display: "flex",
    justifyItems: "center",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .footerRight": {
    alignItems: "center",
    display: "flex",
    gap: "12px",
  },
  "& .label-700-20": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  "& .main-section": {
    textAlign: "center",
  },
  "& .custom-step-connector": {
    width: "100%",
    maxWidth: "600px",
  },
  "& .MuiStepLabel-labelContainer": {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
  "& .question": {
    fontWeight: 600,
    fontFamily: "Raleway",
    fontSize: "41px",
    lineHeight: "53.3px",
    maxWidth: "700px",
    letterSpacing: "0.5px",
    margin: "auto",
  },
  "& .information": {
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "32px",
  },
  "& .callButton": {
    borderRadius: "16px",
    padding: "5px 10px",
    background: "#ffffff",
    border: "1px solid #E46760",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "36px",
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    minHeight: "96px",
    marginTop: "126px",
    padding: "0px 141px",
  },
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: "#2C2524",
  },
  "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover": {
    backgroundColor: "#E46760",
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .button-continue": {
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer",
    marginBottom: "28px",
    background: "#E46760",
    color: "#FFFAF6",
    fonFamily: "Raleway",
    fontWeight: 700,
    textTransform: "capitalize",
    fontSize: "20px",
    marginTop:"12px"
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    width: "100%",
    height: "auto",
    minHeight: "140px",
    display: "flex",
    justifyContent: "center",
  },
  "@media screen and (max-width:1200px)": {
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
  },
});

const CustomStepper = styled(Stepper)({
  margin: "20px auto",
  width: "100%",
  maxWidth: "600px",
  "& .MuiStepLabel-labelContainer": {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .MuiStepIcon-root": {
    marginBottom: "8px",
  },
});

const CustomStepConnector = styled(StepConnector)({
  "&.MuiStepConnector-active": {
    backgroundColor: "#000000",
  },
});
const CustomStepLabel = styled(StepLabel)({
  flexDirection: "column",
  display: "flex",
});

// Customizable Area End