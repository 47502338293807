// Customizable Area Start
import React from 'react';
import {
  Box, styled, FormControlLabel, Chip, Stack
} from "@mui/material";
import HealthReviewController, { Props } from "./HealthReviewController";
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import { checkedIcon, skip, uncheckIcon } from './assets';
import { handleNavigation } from '../../utilities/src/CustomHelpers';

class HealthReview extends HealthReviewController {
  constructor(props: Props) {
    super(props);

  }
  render() {
    return (
      <StyledWrapperHealth>
        <>
          <section className="leftBorder">
            <Box className="container">
              <Box className="headerStepper">
                <StepperData 
                  key={`stepper${this.state.activeStep}`}
                  listData={this.state.listData}
                  activeStep={this.state.activeStep}
                />
              </Box>
              <Box className="headings">
                <h2 className='healthTitle'>Health Review</h2>
                <div className="description">Please share a few more details about your health and wellness.Have you been diagnosed with any of the conditions below? This will help us find the right provider to support your care</div>
                <Box className="contentContainer">
                  <div className='choiceHeading'>Choose from the following options</div>
                  <div className="subHead">(select all that apply)</div>
                </Box>
              </Box>
              <Box className="mainContainer">
                <Box className="optionsGrid">
                  {this.state.options.map((option) => (
                    <Box key={option.id} className="optionItem">
                      <FormControlLabel                      
                      data-test-id = {option.id}
                      control= {<img  src={this.state.selectedOptions.includes(option.id) ? checkedIcon : uncheckIcon} className="controlImage"/>}                     
                      label={option.label}
                      className='lbl'
                      onClick={() => {
                        this.handleOption(option.id)
                      }}
                    />                     
                    </Box>
                  ))}
                </Box>

              </Box>

              <Box className="mainContainer1">
                  {this.state.options.map((option) => (
                    <Box key={option.id} className="optionItem1">                    
                      <Stack direction="row">
                        <Chip 
                        data-test-id = {option.id}
                        label={option.label} className={`label ${this.state.selectedOptions.includes(option.id) ? "active" : ""}`}
                        onClick={() => {
                          this.handleOption(option.id)
                        }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
            </Box>
            <Box className="footer-container">
              <div className="box-wrapper"style={{display : "flex","justifyContent":"left"}}>
                <div
                  className="greyColor"
                  data-test-id = "btnback"
                  onClick={() => {
                    handleNavigation("ThanksForSharing", this.props);
                  }}               
                >
                  <p>Back</p>
                </div>
              </div>

              <div className="box-wrapper" style={{display : "flex","justifyContent":"center"}}>
                {" "}
                <button
                  className="button-continue"                                   
                  data-test-id="continue"                 
                >
                  Continue
                </button>
              </div>

              <div className="box-wrapper" style={{display : "flex","justifyContent":"right"}}>
                <div className="greyColor">
                  <p
                    className="skipIcon"
                    data-test-id = "btnSkip"
                    onClick={() => {
                      handleNavigation("SupportDesign", this.props);
                    }}                  
                  >
                    Skip this Step &nbsp;
                    <span>
                      <img src={skip} alt="skip" />
                    </span>
                  </p>
                </div>
              </div>
            </Box>
            <FooterData />
          </section>
        </>
      </StyledWrapperHealth>
    )
  }
}

const StyledWrapperHealth = styled('div')({
  width: "100%",
  margin: "0 auto",
  "& *": {
    boxSizing: "border-box",
  },
  "& .controlImage": {
    marginRight: "8px",
  },
  
  "& .lbl": {
    display: "flex",
    alignItems: "center",    
  },
  "& .label":{
    fontFamily : "Poppins",
    fontWeight : 400,
    fontSize : "16px",
    color :"#334155",
  },
  "& .label.active":{
    background : "#E46760",
    color : "#FFFFFF"
  },
  "& .button-continue": {
    width: "100%",
    maxWidth: "392px",
    height: "56px",
    background: "#E46760",
    cursor: "pointer",
    borderRadius: "40px",
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Raleway",
    border: "none",
    color: "#FFFFFF"
  },
  "& .button-continue.active": {
    color: "#FFFFFF",
    cursor: "pointer",
    background: "#E46760",
    fontWeight: "700",
    fontSize: "20px",
    fontFamily: "Raleway",
    border: "none",
  },
  "& .button-continue.inactive": {
    color: "#FFFFFF",
    cursor: "not-allowed",
    background: "#D6D3D1",
    fontFamily: "Raleway",
    fontWeight: "700",
    disableCursor: true,
    fontSize: "20px",
    border: "none",
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& .box-wrapper": {
    width: "100%",
    maxWidth: "33.33%",
  },
  "& .footer-container": {
    display: "flex",
    justifyContent: "center",
    margin : "auto",
    marginTop: "100px",
    marginBottom: "80px",  
    maxWidth : "60%",
  },
  "& .optionItem": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    maxHeight: "25px"
  },
  "& .optionsGrid": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    marginTop: "30px",
  },
  "& .mainContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "27px",
    width: "95%",
    margin: "auto",
    paddingLeft: "12px",
  },
  "& .mainContainer1": {
    width: "95%",
    maxWidth: "808px",
    display: "flex",
    flexWrap: "wrap",
    margin: "auto",
    justifyContent : "center"
  },
  "& .optionItem1": {
    flex: "0 0 auto",
    width : "auto",
    marginBottom: "10px",
    marginRight : "6px"
  },  
  "& .contentContainer": {
    marginTop: "32px"
  },
  "& .subHead": {
    textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "17px",
  },
  "& .choiceHeading": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "20px",
    margin: "auto",
    textAlign: "center"
  },
  "& .description": {
    fontFamily: "Poppins",
    maxWidth: "805px",
    maxHeight: "108px",
    textAlign: "center",
    fontSize: "24px",
    fontWight: 400,
    margin: "auto"
  },
  "& .healthTitle": {
    fontFamily: "Raleway",
    fontSize: "41px",
    fontWeight: 600,
    textAlign: "center",
    color: "#2C2524"
  },
  "& .leftBorder": {
    borderLeft: "32px solid #1A4A42",
    height: "auto"
  },
  "& .headerStepper": {
    "display": "flex",
    "justifyContent": "center",
    "paddingTop": "32px"
  },
  "& .headings": {
    "width": "100%",
    "maxWidth": "1017px",
    "maxHeight": "280px",
    "left": "50%",
  },
  "& .container": {
    "height": "auto",
    "width": "1017px",
    margin: "auto"
  }
})


export default HealthReview;