// Customizable Area Start
import React from 'react';
import {
    styled,
    Typography,
    Box,
    Button
} from "@mui/material";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { call, emergency} from '../../blocks/email-account-registration/src/assets';

interface Props {
    data?: Object
}

interface S {
    activeStep?: Object
}

interface SS {
    id: string;
}

class SignupFooter extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="footer-section">
                    <Typography className="left-section">
                        <img src={emergency} alt="emergency"/>
                        <span className='default-text'>If this is an emergency</span><span className='emergency-call'>please call 911</span>
                    </Typography>
                    <Box className="right-section">
                        <span className="default-text need-help">Need Help?</span>
                        <CustomButton onClick={() => {}} className="call-button" data-test-id="callBTN">
                            Schedule a call?
                            <img src={call} alt="call" />
                        </CustomButton>
                    </Box>
                </Box>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    "& .footer-section": {
        height: "96px",
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #E7E5E4",
        justifyContent: "space-between",
        width: "100%",
        "& .emergency-call": {
            textDecoration: "underline",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#D97706",
            cursor: "pointer"
        },
        "& .left-section, & .right-section": {
            display: "flex",
            gap: "12px",
            alignItems: "center"
        },
        '& .left-section': {
            marginLeft: "109px"
        },
        '& .right-section': {
            marginRight: "144px"
        },
        "& .need-help": {
            fontWeight: 700
        }
    },
    "& .default-text": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left"
    }
});


const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    "&.call-button": {
        height: "44px",
        gap: "8px",
        borderRadius: "16px",
        border: "2px solid #E46760",
        color: "#E46760",
        padding: "0 16px"
    },
    "&.call-button::hover": {
        border: "2px solid #E46760",
        color: "#E46760"
    }
}));

export default SignupFooter;
// Customizable Area End