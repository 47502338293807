import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface ImageUrls {
  image: string;
}
interface PrimaryLinksAttributes {  
  primary_button_text: string;
  primary_link_url: string;
  external_link: boolean
}
interface SecondaryLinkAttributes {
  secondary_button_text: string;
  secondary_link_url: string;
  external_link: boolean
}
interface MatchMakingPageInterface{
  id: number | null;
  main_title : string,
  sub_title : string,
  main_body : string,
  button_text : string,
  page_type : string,
  image_urls : ImageUrls,
  primary_links_attributes : PrimaryLinksAttributes[];
  secondary_links_attributes : SecondaryLinkAttributes[];
  help_footer : boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedValue:any;
  activeStep: string;
  skipped: Set<number>;
  listData: {progress_bar_text: string, progress_bar_stage: string}[];
  resStats: MatchMakingPageInterface;
  message: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfcustomonboardingflowcreator1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  matchMakingPageData:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedValue: [],
      skipped: new Set(),
      listData: [
        { progress_bar_text: 'Stage A', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Stage B', progress_bar_stage: '2' },
        { progress_bar_text: 'Stage C',  progress_bar_stage: '3'}
      ],
      activeStep: 'Stage A',
      resStats:{
        id : null,
        main_title : "",
        sub_title : "",
        main_body : "",
        button_text : "",
        page_type : "",      
        image_urls: {
          image: ""
        },
        primary_links_attributes : [],
        secondary_links_attributes : [],
        help_footer : false
      },
      message: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleMatchMakingPageResponse(from, message);
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleMatchMakingPageResponse = async (from: string, message: Message) => {
    if (
      this.matchMakingPageData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({resStats:responseJson.data.attributes, message: ''});
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found"});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found"});
        }
      }
    }
  }
  handleClick = (value: string) => {
    const { selectedValue } = this.state;
    if (selectedValue.includes(value)) {
      this.setState({
        selectedValue: selectedValue.filter((item:any) => item !== value),
      });
    } else {
      this.setState({
        selectedValue: [...selectedValue, value],
      });
    }
  };
  async componentDidMount(){
    this.matchMakingPage();
  }
  matchMakingPage = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.matchMakingPageData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.howAreYouFeelingApiEndp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    const body = { unique_slug, page_type: "Multiple Choice Page" };
     requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleNavigation = (pathname: string) => {
    const paramsData = pathname.split('/');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), paramsData[0]);
    message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
    paramsData[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsData[1]);
    this.send(message);
  }
  // Customizable Area End
}
