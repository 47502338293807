import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface ResponseType {
  id: number | null;
  main_title: string,
  main_body: string,
  main_title_1: string,
  main_body_1: string,
  image_urls: {
    image: string,
    image1: string
  },
  primary_links_attributes: {
    primary_button_text: string,
    primary_link_url: string,
    primary_button_text_1: string,
    primary_link_url_1: string,
    external_link: boolean,
    external_link_1: boolean
  }[]
  secondary_links_attributes: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes :Object;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  resStats: ResponseType,
  message: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      resStats:{
        id: null,
        main_title: '',
        main_body: '',
        main_title_1: '',
        main_body_1: '',
        image_urls: {
          image: '',
          image1: ''
        },
        primary_links_attributes: [],
        secondary_links_attributes: []
      },
      message: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleDualLandingPageResponse(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  dualLandingPageData:string = "";

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleNavigation = (pathname: string) => {
    const paramsData = pathname.split('/');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), paramsData[0]);
    message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
    paramsData[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsData[1]);
    this.send(message);
  };

  async componentDidMount(){
    this.dualLandingPage();
  }

  dualLandingPage = () => {
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dualLandingPageData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPages
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({unique_slug, page_type: "Dual Landing Pages"})
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDualLandingPageResponse = async (from: string, message: Message) => {
    if (
      this.dualLandingPageData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({resStats:responseJson.data.attributes, message: ''});
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found"});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found"});
        }
      }
    }
  }
  // Customizable Area End
}
