import React from "react";

// Customizable Area Start
import {
  Box, styled,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  TextField
} from "@mui/material";
import StepperData from '../../../components/src/StepperData.web';

// Customizable Area End

import ThanksForSharingPageController, {
  Props,
} from "./ThanksForSharingPageController";
import { call, emergency, skip } from "./assets";
import { ConditionalRenderer, ResolveCondition, handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";


class ThanksForSharingPage extends ThanksForSharingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <StyledWrapper>
        <>
          <section className="leftBorder">
            <Box>
              <Box className="header">
                <StepperData 
                  key={`stepper${this.state.activeStep}`}
                  listData={this.state.progress_bars}
                  activeStep={this.state.activeStep}
                />
              </Box>
            </Box>

            <Box className="title">
              Thank you for sharing!
            </Box>
            <Box className="primaryContent"> if you are comfortable, could you please tell us what prompted you to seek our services?</Box>
            <h5 className="headQues">You can choose from the following options:</h5>
            <Box className="btnContainer">
              {this.state.btnArray.map((label, index) => (
                <button
                  key={index}
                  data-test-id = {label}
                  className={`btns ${ResolveCondition(this.state.selectedIndex === index,'selected','')} ${ResolveCondition(this.state.editableIndex === index,'editing','')}`}             
                  onClick={() => this.handleButtonClick(index)}
                >
                  {
                    ConditionalRenderer(this.state.editableIndex === index,<StyledTextField
                      autoFocus
                      className="inputField"
                      data-test-id = "changeField"
                      onChange={(e) => this.handleInputChange(e)}
                      value={this.state.otherValue}
                      onKeyPress={(e) => this.handleInputKeyPress(e)}
                      onBlur={() => this.handleInputBlur()}
                      style={{ width: '100%', height: '100%' }}
                    />,label)
                  }                
                </button>
              ))}
            </Box>


            <Box className="footer-container">
              <div className="box-wrapper">
                <div
                  className="greyColor"
                  data-test-id="btnback"
                  onClick={() => {
                    handleNavigation("WellnessJourneyUpdate", this.props);
                  }}
                >
                  <p>Back</p>
                </div>
              </div>

              <div className="box-wrapper">
                <button
                  className={`button-continue ${ResolveCondition(this.state.selectedIndex !== null,'active','inactive')}`}
                  data-test-id="continue"
                  onClick={() => handleNavigation('HealthReview',this.props)}
                >
                  Continue
                </button>
              </div>

              <div className="box-wrapperRight">
                <div className="greyColor">
                  <p
                    className="skipIcon"
                    data-test-id="btnSkip"
                    onClick={() => {
                      handleNavigation("HealthReview", this.props);
                    }}
                  >
                    Skip this Step &nbsp;
                    <span>
                      <img src={skip} alt="skip" />
                    </span>
                  </p>
                </div>
              </div>
            </Box>

            <Box className="footer">
              <p className="emergencyIcon"><span><img src={emergency} alt="emergency" /></span>&nbsp; If this is an emergency &nbsp; <u>please call 911</u></p>
              <div className="footerRight" id="footer">
                <span className="label-700-20">Need Help?</span>
                <button className="callButton">
                  Schedule a call?
                  <span>
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>
          </section>
        </>
      </StyledWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  width: "100%",
  margin: "0 auto",
  "& *": {
    boxSizing: "border-box",
  }, 
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
"& .btns.selected": {
  background: "#E46760",
  border: "none"
},
"& .btns": {
  width: "343px",
  height: "48px",
  borderRadius: "26px",
  margin: "auto",
  border: "none",
  background: "#E7E5E4",
  color: "#44403C",
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "20px",
  textAlign: "left",
  paddingLeft: "16px",
  marginBottom: "12px"
},
"& .btns.editing": {
  background: "white",
  border: "2px solid #E6C79C",  
},
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    width: "327px",
    height: "48px",
    borderRadius: "26px",
    margin: "auto",
    border: "none",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    textAlign: "left",
    marginBottom: "12px",
    background: "none"
  },
  "& .MuiFormControl-root.MuiTextField-root.inputField.css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "343px",
    height: "48px",
    borderRadius: "26px",
    margin: "auto",
    border: "none",
    background: "#E7E5E4",
    color: "#44403C",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    textAlign: "left",
    marginBottom: "12px"
  },
  "& .skipIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
  },
  "& .callButton": {
    background: "#ffffff",
    borderRadius: "16px",
    border: "1px solid #E46760",
    padding: "5px 10px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "36px",
  },
  "& .label-700-20": {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  "& .footerRight": {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #E7E5E4",
    alignItems: "center",
    marginTop: "126px",
    minHeight: "96px",
    padding: "0px 141px"
  },
  "& .button-continue": {
    height: "56px",
    width: "100%",
    maxWidth: "392px",
    borderRadius: "40px",
    background: "#E46760",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
    color:"#FFFAF6"
  },
  "& .button-continue.active": {
    background: "#E46760",
    color: "#FFFFFF",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .button-continue.inactive": {
    background: "#D6D3D1",
    color: "#FFFFFF",
    cursor: "not-allowed",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    border: "none",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& .footer-container": {
    display: "flex",
    justifyContent: "space-evenly",
    maxWidth: "1231px",
    maxHeight: "56px",
    alignItems: "center",
    margin: "auto",
    marginTop: "32px",
  },
  "& .box-wrapper": {
    width: "100%",
    maxWidth: "33.33%",
  },
  "& .box-wrapperRight": {
    width: "100%",
    maxWidth: "33.33%",
    display: "flex",
    justifyContent: "end"
  },  
  "& .btnContainer": {
    maxWidth: "700px",
    maxHeight: "636px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    marginTop: "32px"
  },
  "& .headQues": {
    "maxWidth": "733px",
    "maxHeight": "36px",
    "textAlign": "center",
    "alignItems": "center",
    "display": "flex",
    "justifyContent": "center",
    "fontFamily": "Poppins",
    "fontWeight": 700,
    "fontSize": "24px",
    margin: "auto",
    marginTop: "32px"
  },
  "& .primaryContent": {
    "maxWidth": "733px",
    "maxHeight": "72px",
    "textAlign": "center",
    "alignItems": "center",
    "display": "flex",
    "justifyContent": "center",
    "fontFamily": "Poppins",
    "fontWeight": 400,
    "fontSize": "24px",
    margin: "auto",
    marginTop: "32px"
  },
  "& .title": {
    "height": "100%",
    "width": "100%",
    "maxWidth": "1017px",
    "maxHeight": "53px",
    "textAlign": "center",
    "alignItems": "center",
    "display": "flex",
    "justifyContent": "center",
    "fontFamily": "Raleway",
    "fontWeight": 600,
    "fontSize": "41px",
    margin: "auto",
    marginTop: "15px"
  },
 
  "& .leftBorder": {
    borderLeft: "32px solid #1A4A42",
    height: "100%"
  },
  "& .custom-step-connector": {
    maxWidth: "600px",
    width: "100%",
  },

});

const CustomStepper = styled(Stepper)({
  width: "100%",
  maxWidth: "600px",
  margin: "20px auto",
  "& .MuiStepLabel-labelContainer": {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .MuiStepIcon-root": {
    marginBottom: "8px",
  },
});

const CustomStepConnector = styled(StepConnector)({
  "& .MuiStepConnector-active": {
    backgroundColor: "#000000",
  },
});
const CustomStepLabel = styled(StepLabel)({
  display: "flex",
  flexDirection: "column",
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "26px",
    height: "100%",
    width: "100%",
    maxHeight: "44px",
    maxWidth: "340px",
    border: "none",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "20px",
    padding: "10px 16px",
    boxSizing: "border-box",
    border: "none",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "20px",
    color: "#999",
    fontFamily: "Poppins",
  },
});

export default ThanksForSharingPage;
// Customizable Area End
