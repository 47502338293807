import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
let btnArrayInitial = ["Anxiety", "Depression", "Life Changes", "Trauma", "Addiction", "Greif and Loss", "PTSD", "Suicidal Thoughts", "Prefer not to say", "Other(specify)"]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;  
}

interface S {
  // Customizable Area Start
  btnArray: string[];
  editableIndex: number | null;
  otherValue: string;
  selectedIndex: number | null;
  progress_bars: {progress_bar_text: string, progress_bar_stage: string}[];
  activeStep: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ThanksForSharingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getWellnessMembershipCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      btnArray: btnArrayInitial,
      editableIndex: null,
      otherValue: "",
      selectedIndex: null,
      activeStep: "Stage A",
      progress_bars: [
        { progress_bar_text: "Stage A", progress_bar_stage: '1' },
        { progress_bar_text: "Stage B", progress_bar_stage: '2' }, 
        { progress_bar_text: "Stage C", progress_bar_stage: '3' }
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  } 

  // Customizable Area Start  
  handleButtonClick = (index: number) => {
    this.setState((prevState) => {
      const newSelectedIndex = prevState.selectedIndex === index ? null : index;
      
      return {
        selectedIndex: newSelectedIndex,
        editableIndex: prevState.btnArray[index] === "Other(specify)" ? index : null,
        otherValue: prevState.btnArray[index] === "Other(specify)" ? "" : prevState.otherValue
      };
    });
  };

  handleInputBlur() {
    this.setState({ editableIndex: null });
  }

  handleInputChange(event: any) {
    let { value } = event.target
    this.setState({ otherValue: value });

  }

  handleInputKeyPress(event: any) {
    if (event.key === "Enter" && this.state.editableIndex ) {
      const newBtnArray = [...this.state.btnArray];
      newBtnArray[this.state.editableIndex] = this.state.otherValue;
      this.setState({
        btnArray: newBtnArray,
        editableIndex: null,
        otherValue: "",
        selectedIndex: null,
      });
    }
  }
  // Customizable Area End
}
