import React from "react";

// Customizable Area Start
import { Box, styled, Typography } from "@mui/material";
import PageNotFound from "../../../components/src/PageNotFound.web";

// Customizable Area End

import WellnessLandingPageController, {
  Props,
} from "./WellnessLandingPageController";
import { HeaderLogoImg, callimg,call } from "./assets";
import { ConditionalRenderer, handleNavigation } from "./../../../blocks/utilities/src/CustomHelpers";

class WellnessLandingPage extends WellnessLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleButtonElm = (
    button_text: string,
    urlData: string,
    external_link: boolean,
    buttonType: string = "") => {
    return external_link ? <a className={`${buttonType ? "buttonSecond": "button"}`} data-test-id={button_text} href={urlData}>{button_text}</a>: <button className={`${buttonType ? "buttonSecond": "button"}`} data-test-id={button_text} onClick={() => this.handleNavigation(urlData)}>{button_text}</button>;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start    
    return (
      <StyledWrapper>
        <>
        {this.state.message ? <PageNotFound />:
          <section className="leftBorder">
            <Box className="header">
              <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
            </Box>
            <Box className="main-containt">
              <img
              className="landingpageCover"
                src={this.state.responseWellness.image_urls.image}
                alt="wellnessLanding"
              />
              <div className="container button-container">
                {
                  ConditionalRenderer(Boolean(this.state.responseWellness.main_title),
                    <Typography className="headeingWellness">{this.state.responseWellness.main_title}</Typography>, ''
                  )
                }
                {
                  ConditionalRenderer(Boolean(this.state.responseWellness.sub_title),
                    <Typography className="paragraphWellness">{this.state.responseWellness.sub_title}</Typography>, ''
                  )
                }
                {
                  ConditionalRenderer(Boolean(this.state.responseWellness.main_body), <Typography className="subText"
                  dangerouslySetInnerHTML={{__html: this.state?.responseWellness?.main_body}}                    
                  />, "")
                }
                {this.state.responseWellness.primary_links_attributes.map((link, index) => (
                  <div key={`primary-${index}`} style={{ width: "100%" }}>
                    {ConditionalRenderer(
                      Boolean(link.primary_button_text),
                      this.handleButtonElm(link.primary_button_text, link.primary_link_url, link.external_link, "" ),
                      ""
                    )}
                  </div>
                ))}

                {this.state.responseWellness.secondary_links_attributes && this.state.responseWellness.secondary_links_attributes.map((link, index) => (
                  <div key={`secondary-${index}`} style={{ width: "100%" }}>
                    {ConditionalRenderer(
                      Boolean(link.secondary_button_text),
                      this.handleButtonElm(link.secondary_button_text, link.secondary_link_url, link.external_link, "secondaryBtn" ),
                      ""
                    )}
                  </div>
                ))}
                
              </div>
            </Box>            
            {
              ConditionalRenderer(this.state.responseWellness.help_footer,<Box className="footerContain">
              <p className="emergency"><span><img src={callimg} alt="emergency" /></span>&nbsp; If this is an emergency &nbsp; <u style={{ color: "#D97706" }}>please call 911</u></p>
              <div className="rightFooter" id="footer">
                <span className="needHelp">Need Help?</span>
                <button className="scheduleCall">
                  Schedule a call?
                  <span>
                    <img src={call} alt="call" />
                  </span>
                </button>
              </div>
            </Box>,<Box className="footer1"></Box>)
            }
          </section>}
        </>
      </StyledWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  width: "100%",
  margin: "0 auto",
  height : "auto",
  "& .scheduleCall": {
    borderRadius: "16px",
    background: "#ffffff",
    padding: "5px 10px",
    border: "1px solid #E46760",
    display: "flex",
    color: "#E46760",
    gap: "8px",
    alignItems: "center",
    fontWeight: 700,
    fontFamily: "Raleway",
    lineHeight: "36px",
    fontSize: "20px",
  },
  "& .rightFooter": {
    alignItems: "center",
    gap: "12px",
    display: "flex",
  },
  "& .needHelp": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  "& .emergency": {
    alignItems: "center",
    display: "flex",
    fontWeight: "400",
    fontFamily: "Poppins",
    fontSize: "20px",
  },
  "& .footerContain": {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    minHeight: "96px",
    padding: "0px 141px"
  },
  "& .footer1": {   
    minHeight: "96px",
  },
  "& .buttonSecond": {
    width: "max-content",
    display: "flex",
    justifyContent: "center",
    textDecoration: "underline",
    margin: "13px auto 0",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    "fontFamily": "Raleway",
    "fontWeight": 700,
    "fontSize": "20px",
    "color": "#E46760",
    "border": "none",
    "background": "none",
    cursor: "pointer"
  },
  "& .subText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "20px",
    width: "auto",
    margin: 0,
    "& p":{
      margin : 0
    }
  },
  "& .leftBorder": {
    borderLeft: "32px solid #1A4A42"
  },
  "& .header": {
    height: "auto",
    minHeight: "78px",
    width: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  "& .main-containt": {
    position: "relative",
    height:"auto"
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .button": {
    width: "475px",
    borderRadius: "24px",
    backgroundColor: "#E46760",
    color: "#FFFAF6",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16px 20px",
    border: "none",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0",
    cursor: "pointer",
    textDecoration: "none"
  },
  "& .headeingWellness": {
    fontFamily: "Raleway",
    color: "rgb(44, 37, 36)",
    fontWeight: 800,
    fontSize: "64px",
    lineHeight: "76.8px",
    letterSpacing: "0.5px",
    textAlign: "center",
    margin: 0,
  },
  "& .paragraphWellness": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    textAlign: "center",
    color: "#2C2524",
    marginTop: '20px',
    width: "90%"
  },
  "& .list": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    display: "flex",
    justifyContent: "center",
    color: "#2C2524",
  },
  "& .image": {
    height: "auto",
    width: "100%",
    objectFit: "contain",
  },
  "& .landingpageCover": {
    display: 'block',
    width: '100%',
    maskImage: 'linear-gradient(to bottom, white 16%, transparent 56%)',
  },
  "& .container": {
    gap: "20px",
    width: "100%",
    maxWidth: "60%",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    margin: "-44% auto 15%"
  },
  '& .button-container': {
    position: 'relative', 
    zIndex: '2'
  },
  "@media screen and (max-width: 1280px)": {
    "& .headeingWellness": {
      fontSize: "50px",
      letterSpacing: "0.5px"
    },
    "& .paragraphWellness": {
      fontSize: "22px",
      lineHeight: "34px",
    },
    "& .list": {
      fontSize: "20px",
      lineHeight: "30px",
    },
    "& .button": {
      fontSize: "18px"
    }
  },
  "@media screen and (max-width: 1100px)": {
    "& .headeingWellness": {
      fontSize: "45px",
      letterSpacing: "0.5px"
    },
    "& .paragraphWellness": {
      fontSize: "20px",
      lineHeight: "32px",
    },
    "& .list": {
      fontSize: "18px",
      lineHeight: "30px",
    },
  },
  "@media screen and (max-width: 991px)": {
    "& .headeingWellness": {
      fontSize: "40px",
      letterSpacing: "0.5px",
    },
    "& .paragraphWellness": {
      fontSize: "18px",
      lineHeight: "30px",
    },
    "& .list": {
      fontSize: "20px",
      lineHeight: "30px",
    },
    "& .button": {
      fontSize: "16px"
    }
  },
  "@media screen and (max-width: 767px)": {
    "& .headeingWellness": {
      fontSize: "35px",
      letterSpacing: "0.5px",
    },
    "& .paragraphWellness": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "& .list": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "& .button": {
      fontSize: "16px"
    }
  },
});

export default WellnessLandingPage;
// Customizable Area End
